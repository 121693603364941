import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPaymentMethods,
  savePaymentMethod,
  postOrder,
  changeIsLoading
} from "src/redux/actions/action-store";

import styles from "./confirmation-page.module.css";
import { _t } from "src/redux/store";

import { changeStep } from "src/redux/actions/action-store";

import classnames from "classnames";
import { ButtonBase } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import SummaryStore from "src/components/SummaryStore/summaryStore";
import { LANG_AND_CURRENCY_BY_COUNTRY } from "src/app-constant";

class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: this.props.CountryCode2,
      showLink: false,
    };
    this.selectedBtn = false;
    this.props.changeIsLoading();

  }
  componentDidMount() {
    this.props.changeIsLoading();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isLoading === true &&
      this.props.isLoading === false &&
      this.props.link !== ""
    ) {
      this.goToLink();
    }
  }

  goToLink() {
    // window.open(this.props.link, "_blank"); // disabled popup, to make payment show in current tab
    window.location.href = this.props.link; // open payment in the same tab
    this.setState({ showLink: true });
  }

  renderDetails() {
    let name = this.props.paymentMethods.find(
      (paymentMethod) =>
        paymentMethod.paymentMethodId ===
        parseInt(this.props.order.paymentTypeId)
    ).name;

    return (
      <div>
        <div>
          <div>
            <p className={styles.blueTitle}>
              {_t.translate("Account details")}
            </p>
            <p className={styles.titleInput}>
              {_t.translate("Betfair Vendor Client ID")}
            </p>

            <input
              type="number"
              disabled
              className={classnames(styles.input)}
              placeholder={_t.translate("Betfair Vendor Client ID")}
              value={this.props.order.vendorClientId}
            />
          </div>
          {this.props.order.invoiceDetails.name !== "" ? (
            <div>
              <div>
                <p className={styles.titleInput}>{_t.translate("Country")}</p>
                <input
                  type="text"
                  disabled
                  className={classnames(styles.input)}
                  value={
                    LANG_AND_CURRENCY_BY_COUNTRY[
                      this.props.order.invoiceDetails.countryIso2
                    ].name
                  }
                />
              </div>

              <div>
                <p className={styles.titleInput}>{_t.translate("Name")}</p>
                <input
                  type="text"
                  disabled
                  className={styles.input}
                  value={this.props.order.invoiceDetails.name}
                />
              </div>
              <div>
                <p className={styles.titleInput}>
                  {_t.translate("VAT Number")}
                </p>
                <input
                  type="number"
                  disabled
                  className={styles.input}
                  value={this.props.order.invoiceDetails.vatNumber}
                  onChange={this.handleClientVatChange}
                />
              </div>
              <div>
                <p className={styles.titleInput}>{_t.translate("City")}</p>
                <input
                  type="text"
                  disabled
                  className={styles.input}
                  value={this.props.order.invoiceDetails.city}
                />
              </div>
              <div>
                <p className={styles.titleInput}>
                  {_t.translate("Billing Address")}
                </p>
                <input
                  type="text"
                  disabled
                  className={styles.input}
                  value={this.props.order.invoiceDetails.address}
                />
              </div>
              <div>
                <p className={styles.titleInput}>{_t.translate("Zip Code")}</p>
                <input
                  type="text"
                  disabled
                  className={styles.input}
                  value={this.props.order.invoiceDetails.zipCode}
                />
              </div>
            </div>
          ) : null}
          <div>
            <p className={styles.blueTitlePadding}>
              {_t.translate("Payment Method")}
            </p>
            <FormControlLabel
              className={styles.target}
              label={name}
              control={
                <Radio
                  disabled
                  classes={{ root: styles.selects }}
                  checked={true}
                />
              }
            />
            <div className={styles.continueBtn}>
              <ButtonBase
                className={styles.contBtnAct}
                onClick={this.SubmitOrder}
              >
                {this.props.isLoading === true ? <div>
                  <div className={styles.loadingParent}>
                    <div className={styles.loadingContainer}>
                      <div className={"loader-circle"}></div>

                    </div>

                  </div>
                </div> : <>
                  {_t.translate("Continue")}
                </>}
              </ButtonBase>
            </div>
            {this.state.showLink === true && (
              <p className={styles.small}>
                {_t.translate("If the payment page didn't open, please click here")}
                {" "}<br />
                <a className={"isLink"} href={this.props.link}>
                  {this.props.link}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  SubmitOrder = (event) => {
    this.props.postOrder(
      this.props.order.productId.toString(),
      this.props.currency,
      this.props.order.vendorClientId,
      this.props.order.invoiceDetails,
      this.props.order.paymentTypeId
    );
    event.target.setAttribute("disabled", "disabled"); //disable button after one click
    // this.props.changeIsLoading();
  };

  render() {
    return (
      <div>
        <div className={styles.textCenter}>
          <p className={styles.title}>{_t.translate("Confirmation")}</p>
        </div>
        <div className={styles.row}>
          <div className={styles.leftCell}>{this.renderDetails()}</div>
          <div className={styles.rightCell}>
            <SummaryStore />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentMethods: state.store.paymentMethods,
    currency: state.options.currency,
    CountryCode2: state.options.countryGeoIp,
    order: state.store.order,
    link: state.store.orderResponse.Url,
    isLoading: state.store.isLoading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getPaymentMethods: () => {
      dispatch(getPaymentMethods());
    },
    savePaymentMethod: (paymentMethodId, recurringPayments) => {
      dispatch(savePaymentMethod(paymentMethodId, recurringPayments));
    },
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
    changeIsLoading: () => {
      dispatch(changeIsLoading());
    },
    postOrder: (
      subscriptionTypeId,
      currency,
      vendorClientId,
      invoiceDetails,
      paymentTypeId
    ) => {
      dispatch(
        postOrder(
          subscriptionTypeId,
          currency,
          vendorClientId,
          invoiceDetails,
          paymentTypeId
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
