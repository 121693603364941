import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import classnames from "classnames";
import styles from "./guia-page.module.css";
import { ButtonBase } from "@material-ui/core";
import UserGuideTopic from "./userGuide-page/userGuide-topic";
import { USER_GUIDE_TOPICS } from "src/views/userGuide-page/userGuideListData.js";
import { _t } from "src/redux/store";

export default class GuiaPage extends Component {
  handleOnSelect = (e) => {
    this.props.history.push(e.target.value);
  };

  renderNavItems() {
    return Object.keys(USER_GUIDE_TOPICS).map((topicKey) => {
      const item = USER_GUIDE_TOPICS[topicKey];
      const itemFullUrl = "/user-guide/" + item.topicUrl;
      return (
        <li
          key={topicKey}
          className={classnames(
            styles["spaceLeft-" + item.level],
            item.level === 1 && styles.spaceTop
          )}
        >
          <ButtonBase
            classes={{
              root: classnames(
                styles.btnGuia,
                this.props.location.pathname === itemFullUrl && styles.active
              ),
            }}
            href={itemFullUrl}
          >
            {item.topic}
          </ButtonBase>
        </li>
      );
    });
  }

  renderSelectItems() {
    return Object.keys(USER_GUIDE_TOPICS).map((topicKey) => {
      const item = USER_GUIDE_TOPICS[topicKey];
      const itemFullUrl = "/user-guide/" + item.topicUrl;
      return (
        <option key={topicKey} value={itemFullUrl}>
          {item.topic}
        </option>
      );
    });
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.column}>
          <div className={styles.rowTop}>
            <h1 className={styles.title}>{_t.translate("User Guide")}</h1>
          </div>
          <div className={styles.rowTop}>
            <div className={styles.leftCell}>
              <div className={styles.sticky}>
                <select
                  className={styles.selects}
                  onChange={this.handleOnSelect}
                >
                  {this.renderSelectItems()}
                </select>
                <ol className={styles.pills}>{this.renderNavItems()}</ol>
              </div>
            </div>
            <div className={styles.rightCell}>
              <Switch>
                <Route
                  exact
                  path="/user-guide/:topicKey"
                  component={UserGuideTopic}
                />
                <Redirect from="/user-guide" to="/user-guide/register" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
