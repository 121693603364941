import React, { Component } from "react";
import { _t } from "src/redux/store";
import { connect } from "react-redux";
import {
  changeClientVatNumber,
  changeTempClientVatNumber,
} from "src/redux/actions/action-store.js";
import FormControl from "@material-ui/core/FormControl";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./details-page.module.css";
import { TextField } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "./../../model/myEnv";

class InputClientVatNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVatNumberEdited: false,
    };
  }

  handleClientVatNumberChange = (event) => {
    this.setState({ isVatNumberEdited: true });
    this.props.changeTempClientVatNumber(event.target.value);
    if (event.target.value.length >= REQ_INVOICE_INPUT_MIN_LENGTH) {
      this.props.changeClientVatNumber(event.target.value);
    } else {
      this.props.changeClientVatNumber("");
    }
  };

  render() {
    const isVatNumberEmpty = this.props.tempClientVatNumber === "";
    const vatNumberIsReq = isVatNumberEmpty && this.state.isVatNumberEdited;
    const hasRequiredLength =
      this.props.tempClientVatNumber.length >= REQ_INVOICE_INPUT_MIN_LENGTH;
    const vatNumberNotValid =
      this.props.vatNumber !== this.props.tempClientVatNumber &&
      hasRequiredLength;
    return (
      <div className={styles.invoice}>
        <div className={styles.inputDetails}>
          <FormControl className={styles.formControl}>
            <TextField
              classes={{
                root: vatNumberIsReq ? styles.textFieldError : styles.textField,
              }}
              variant="outlined"
              required
              error={vatNumberIsReq}
              id="vendor-client-name"
              label={_t.translate("VAT Number")}
              helperText={
                vatNumberIsReq === true
                  ? _t.translate("VAT number is required")
                  : !hasRequiredLength && this.state.isVatNumberEdited
                  ? _t.translate(
                      "At least {{minimumLength}} characters required",
                      {
                        minimumLength: REQ_INVOICE_INPUT_MIN_LENGTH,
                      }
                    )
                  : ""
              }
              onChange={this.handleClientVatNumberChange}
              value={this.props.tempClientVatNumber}
            />
          </FormControl>
        </div>
        <div className={styles.checkIcon}>
          {vatNumberNotValid === false && hasRequiredLength ? (
            <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tempClientVatNumber: state.store.tempClientVatNumber,
    vatNumber: state.store.order.invoiceDetails.vatNumber,
    selectedCountryCode: state.store.order.invoiceDetails.countryIso2,
    countryGeoIp: state.options.countryGeoIp,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientVatNumber: (newClientVatNumber) => {
      dispatch(changeClientVatNumber(newClientVatNumber));
    },
    changeTempClientVatNumber: (newTempClientVatNumber) => {
      dispatch(changeTempClientVatNumber(newTempClientVatNumber));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputClientVatNumber);
