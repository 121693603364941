import { _t } from "src/redux/store";

export const USER_GUIDE_TOPICS = {
  register: {
    level: 1,
    topic: _t.translate("1. Register"),
    topicUrl: "register",
    title: _t.translate("SmartProTrading registration"),
    content: _t.translate(
      "<p>After installing SmartProTrading on your computer," +
      " fill in the blanks and provide a valid and accessible email for you," +
      " as it will be a fundamental means of contact to communicate with the" +
      " support team. The trial period starts at this point.</p>"
    ),
  },
  login: {
    level: 1,
    topic: _t.translate("2. Login"),
    topicUrl: "login-betfair",
  },
  "login-betfair": {
    level: 2,
    topic: _t.translate("2.1 Login betfair"),
    topicUrl: "login-betfair",
    title: _t.translate("Login with Betfair"),
    content: _t.translate(
      "<p>To login to the software, simply enter your Betfair credentials.</p>" +
      "<p>If you activate the “Remember Username” option, only your username will be saved." +
      " For security reasons, Betfair does not allow the password to be saved, even on your own computer.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/2-smartprotrading-login-betfair.png",
        alt: _t.translate("Login with Betfair in SmartProTrading software")
      }
    ]
  },
  "two-step-authentication": {
    level: 2,
    topic: _t.translate("2.2 Two step authentication"),
    topicUrl: "two-step-authentication",
    title: _t.translate("Login with Two-step authentication"),
    content: _t.translate(
      "<p>If two-step authentication is enabled on your Betfair account, you will need to go through this step at SmartProTrading login:" +
      " simply enter both values in the PASSWORD field, this is, enter your Betfair password followed by the verification code in the PASSWORD field (without spaces or any other separator).</p>"
    ),
  },
  "training-mode": {
    level: 1,
    topic: _t.translate("3. Training mode"),
    topicUrl: "training-mode",
    title: _t.translate("Training mode"),
    content: _t.translate(
      "<p>You don't know the features of the software and you are afraid to risk your money?" +
      " Activate the Training Mode for a complete experience, without having to worry about your bankroll.</p>" +
      "<p>The Training Mode will allow you to simulate many of the actions you want to do in real markets.</p>" +
      "<p>However, a note: it will not be possible to mix real and simulated bets, that is," +
      " when you place a bet in this mode, it is automatically matched, if money is available on the market," +
      " unlike real bets, which may take a while to be matched.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/3-smartprotrading-traning-mode.png",
        alt: _t.translate("Practice mode is available in SmartProTrading")
      }
    ]
  },
  "smartprotrading-layout": {
    level: 1,
    topic: _t.translate("4. SmartProTrading Layout"),
    topicUrl: "smartprotrading-layout",
    title: _t.translate("SmartProTrading layout"),
    content: _t.translate(
      "<p>The SmartProTrading layout is organized in independent windows," +
      " which means that the software offers the possibility to be adjusted and positioned by the user.</p>" +
      "<p>The main bar window (left sidebar) will be the only window that will remain open as long as the software is connected to your computer." +
      " All other windows can be resized or even closed.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/4-smartprotrading-betfair-professional-trading-software.png",
        alt: _t.translate("SmartProTrading floating layout allow you to open as many markets as needed")
      }
    ]
  },
  "main-bar-of-smartprotrading": {
    level: 1,
    topic: _t.translate("5. Main bar of SmartProTrading"),
    topicUrl: "main-bar-of-smartprotrading",
    title: _t.translate("Main bar of SmartProTradingt (left sidebar)"),
    content: _t.translate(
      "<p>The main bar of SmartProTrading is divided into 4 tabs: Markets, Sports, Active Markets and Settings.</p>" +
      "<p>Above these tabs, there is an area with user information, including the available balance and exposure," +
      " a search bar to facilitate the search for a certain event, and also the button to turn on/off the training mode.</p>"
    ),
  },
  "main-bar-of-smartprotrading-markets": {
    level: 2,
    topic: _t.translate("5.1 Markets"),
    topicUrl: "main-bar-of-smartprotrading-markets",
    title: _t.translate("Main bar - Markets Filters"),
    content: _t.translate(
      "<p>In the Markets tab, you can see the events that are taking place or that are going to start soon.<p>" +
      "<p>Default filters are made for the most wanted sports: Football, Tennis and Horse Racing. But you can edit and add your prefered filters.</p>" +
      "<p>Note that changing the total dropdown will allow you to see more markets.</p>" +
      "<p>When you click on each event, an independent window will open with market information." +
      " By default it opens the Grid view - there is the possibility to change the standard interface when opening the market in “Settings”).</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/5-smartprotrading-market-filters.png",
        alt: _t.translate("SmartProTrading custom market filters results on the main sidebar")
      }
    ]
  },
  "main-bar-of-smartprotrading-sports": {
    level: 2,
    topic: _t.translate("5.2 Sports"),
    topicUrl: "main-bar-of-smartprotrading-sports",
    title: _t.translate("Main bar - Sports"),
    content: _t.translate(
      "<p>As an alternative to the search bar at the top of the SmartProTrading Main Bar," +
      " it is possible to search for a specific event in this “Sports” tab.</p>" +
      "<p>The search for markets is presented in the form of a tree: sport-competition-event-market," +
      " in which competitions and markets are alphabetically ordered.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/5-smartprotrading-markets-tree.png",
        alt: _t.translate("Betfair all markets navigation, on the main sidebar, sports tab")
      }
    ]
  },
  "main-bar-of-smartprotrading-active-markets": {
    level: 2,
    topic: _t.translate("5.3 Active markets"),
    topicUrl: "main-bar-of-smartprotrading-active-markets",
    title: _t.translate("Main bar - Active markets"),
    content: _t.translate(
      "<p>In “Active Markets”, at the top, you can see the total CashOut value.</p>" +
      "<p>When this tab is selected, the list of all markets where you have open bets appears" +
      " that you can easily use to access each of the markets, or even cash out each market individually.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/5-smartprotrading-all-markets-with-open-bets.png",
        alt: _t.translate("Quick view of all the active markets where you have open bets. Global cashout button is also available here.")
      }
    ]
  },
  "main-bar-of-smartprotrading-settings": {
    level: 2,
    topic: _t.translate("5.4 Settings"),
    topicUrl: "main-bar-of-smartprotrading-settings",
    title: _t.translate("Main bar - Settings"),
    content: _t.translate(
      "<p>Customize your SmartProTrading! There are 5 tabs of settings that you can use to make it in your own way:</p>" +
      "<p><strong>GENERAL:</strong> change the software language; change the view when opening a new market (Grid, Ladder and Multi-bet);" +
      " choose to see or not see Live Scores; and edit how the Weight of Money is calculated.</p>" +
      "<p><strong>MARKET:</strong> confirm bet before placing it; include Betfair commission in cashout and hedge column values;" +
      " configure Cash Out Mode, this is, choose if you want or not to keep unmatched bets when using the cash out and hedge.</p>" +
      "<p><strong>STAKES:</strong> edit your personal list of stakes that you will use to quickly change the stake in the market view," +
      " without any limit on the number of stakes you can add.</p>" +
      "<p><strong>LADDER:</strong> you can define the line height and font size in the ladder view; you can choose to always see" +
      " the profit in the Hedge column or restart each time you close a trade; and you can add or hide some visible columns.</p>" +
      "<p><strong>SOUNDS:</strong> you can turn on/off alert sounds for several actions, like: market is going live," +
      " market has entered in-play, market suspended, bet placed, bet matched, bet partially matched, bet error, cashout.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/5-smartprotrading-settings-general.png",
        alt: _t.translate("SmartProTrading software settings, general tab")
      },
      {
        src: "/img/user-guide/5-smartprotrading-settings-market.png",
        alt: _t.translate("SmartProTrading software settings, market tab")
      },
      {
        src: "/img/user-guide/5-smartprotrading-settings-stakes-and-keyboard-shortcuts.png",
        alt: _t.translate("SmartProTrading software settings, stakes and keyboard shortcuts")
      },
      {
        src: "/img/user-guide/5-smartprotrading-settings-ladder-customization.png",
        alt: _t.translate("SmartProTrading software settings, ladder layout customization")
      },
      {
        src: "/img/user-guide/5-smartprotrading-settings-sounds-alerts.png",
        alt: _t.translate("SmartProTrading software settings, sound alerts")
      }
    ]
  },
  "grid-view": {
    level: 1,
    topic: _t.translate("6. Grid view"),
    topicUrl: "grid-view",
    title: _t.translate("Grid view"),
    content: _t.translate(
      "<p>The grid view of SmartProTrading has a betting interface very similar to the one found on the Betfair website," +
      " however, it is faster and gathers important information for a very effective performance.</p>" +
      "<p>Suitable for markets with many selections, such as horse racing, or for certain football events," +
      " the grid view has a simple and intuitive layout.</p>" +
      "<p>In addition to the grid itself, there is the bar shown at the top, where you can find information about the event," +
      " the value traded on the market and some buttons: Cashout button, change to other Market of the same event," +
      " change to Ladder view, and the Market Settings button where you can choose the type of stake and the type of Automatic Betting.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/6-smartprotrading-grid-view.png",
        alt: _t.translate("Grid view, good to keep an eye on several runners at once")
      }
    ]
  },
  "ladder-view": {
    level: 1,
    topic: _t.translate("7. Ladder view"),
    topicUrl: "ladder-view",
    title: _t.translate("Ladder view"),
    content: _t.translate(
      "<p>The ladder view is not available on the Betfair website, but it is one of the most popular views among users of trading software.</p>" +
      "<p>This view has a global and intuitive view of the odds ladder, so you can easily understand the behavior of the market" +
      " and place bets, which will be immediately matched or will be on the waiting list to be matched as soon as the market moves.</p>" +
      "<p>You will be able to see that there are many graphic indications, for example, through the additional columns," +
      " which will allow you to anticipate the way the market reacts.</p>" +
      "<p>The top of this view is very similar to that of the Grid View, where you can change stake, change market," +
      " use market settings and cashout.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/7-smartprotrading-ladder-view.png",
        alt: _t.translate("Ladder view, best view to understand market movements, and to make the market.")
      }
    ]
  },
  "multi-bet-view": {
    level: 1,
    topic: _t.translate("8. Multi-bet view"),
    topicUrl: "multi-bet-view",
    title: _t.translate("Multi-bet view"),
    content: _t.translate(
      "<p>The multi bet view is another betting interface that SmartProTrading offers and which is not available on the Betfair website.</p>" +
      "<p>When selected, it will allow you to place several bets at the same time on the market you have chosen.</p>" +
      "<p>There are 4 trading strategies that you should know and that you can use within the software:" +
      " two for Back bets (Dutching and Back-the-Field) and two for Lay bets (Bookmaking and Lay-the-Field).</p>" +
      "<p><strong>BACK - Dutching:</strong> select the runners that you think can win and quickly place bets in all of them;</p>" +
      "<p><strong>BACK - Back-the-Field:</strong> identical view to Dutching, but with an input to define a" +
      " global stake that will automatically be divided for the selected runners (according to their odds, in order to hedge the profits).</p>" +
      "<p><strong>LAY - Bookmaking:</strong> select the runners that will not win and quickly place bets in all of them;</p>" +
      "<p><strong>LAY - Lay-the-Field:</strong> identical view to Bookmaking, but with an input to define a" +
      " global stake that will automatically be divided for the selected runners (according to their odds, in order to hedge the profits).</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/8-smartprotrading-multibet-bookmaking.png",
        alt: _t.translate("Multibet view, bookmaking")
      },
      {
        src: "/img/user-guide/8-smartprotrading-multibet-lay_the_field.png",
        alt: _t.translate("Multibet view, lay the field strategy optimization")
      }
    ]
  },
  "stake-types": {
    level: 1,
    topic: _t.translate("9. Stake types"),
    topicUrl: "stake-types",
    title: _t.translate("Stake types overview"),
    content: _t.translate(
      "<p>Make your entries, varying between the types of stake that SmartProTrading offers.</p>" +
      "<p>There are 4 stake types and each has a characteristic color: Stake (blue), Liability (pink), Profit (green) and Mix (purple).</p>"
    ),
  },
  "stake-types-stake": {
    level: 2,
    topic: _t.translate("9.1 Stake"),
    topicUrl: "stake-types-stake",
    title: _t.translate("Stake"),
    content: _t.translate(
      "<p>In “STAKE” mode, the value you define for your stake (marked in blue) corresponds to the" +
      " total stake of the Back bets and the potential profit in the case of Lay bets.</p>" +
      "<p><strong>BACK:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>" +
      "<p><strong>LAY:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/9-smartprotrading-stake-mode-stake.png",
        alt: _t.translate("Normal stake mode")
      }
    ]
  },
  "stake-types-liability": {
    level: 2,
    topic: _t.translate("9.2 Liability"),
    topicUrl: "stake-types-liability",
    title: _t.translate("Liability"),
    content: _t.translate(
      "<p>In “LIABILITY” mode, the value you define for your stake (marked in pink) corresponds to the" +
      " amount you risk losing both in the Back and Lay bets.</p>" +
      "<p><strong>BACK:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>" +
      "<p><strong>LAY:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/9-smartprotrading-stake-mode-liability.png",
        alt: _t.translate("Liability stake mode")
      }
    ]
  },
  "stake-types-profit": {
    level: 2,
    topic: _t.translate("9.3 Profit"),
    topicUrl: "stake-types-profit",
    title: _t.translate("Profit"),
    content: _t.translate(
      "<p>In “PROFIT” mode, the value you define for your stake (marked in green) corresponds to the" +
      " potential profit both in the Back and Lay bets.</p>" +
      "<p><strong>BACK:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>" +
      "<p><strong>LAY:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/9-smartprotrading-stake-mode-profit.png",
        alt: _t.translate("Profit stake mode")
      }
    ]
  },
  "stake-types-mix": {
    level: 2,
    topic: _t.translate("9.4 Mix"),
    topicUrl: "stake-types-mix",
    title: _t.translate("Mix"),
    content: _t.translate(
      "<p>In “MIX” mode is the inverse of Stake mode. In the “MIX” mode," +
      " the value you define for your stake (marked in purple) corresponds to the" +
      " potential profit in Back bets and the amount you risk losing in the case of Lay bets.</p>" +
      "<p><strong>BACK:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>" +
      "<p><strong>LAY:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>"
    ),
    imgs: [
      {
        src: "/img/user-guide/9-smartprotrading-stake-mode-mix.png",
        alt: _t.translate("Mix stake mode")
      }
    ]
  },
  "automatic-bets": {
    level: 1,
    topic: _t.translate("10. Automatic bets"),
    topicUrl: "automatic-bets",
    title: _t.translate("Automatic bets"),
    content: _t.translate(
      "<p>SmartProTrading has tools that become essential for successful trading.</p>" +
      "<p>One of them is the variety of automatic bets that are available in the “Market Settings” icon.</p>"
    ),
  },
  "automatic-bets-tick-offset": {
    level: 2,
    topic: _t.translate("10.1 Tick Offset"),
    topicUrl: "automatic-bets-tick-offset",
    title: _t.translate("Automatic bets - Tick Offset"),
    content: _t.translate(
      "<p>When “TickOffset” is activated, it is a tool that automatically closes a trade when reached the disered profit.</p>" +
      "<p>If you started the trade with a Back bet, the software will automatically place the Lay bet when reached the exit creteria defined.</p>" +
      "<p>If you started the trade with a Lay bet, the software will automatically place the Back bet when reached the exit creteria defined.</p>" +
      "<p>Possible settings in TickOffset:</p>" +
      "<p>You can choose to exit X number of ticks away from the opening bet, or you can choose to exit when the profit reaches X percentage of the opening stake.</p>" +
      "<p>You can choose to exit in Hedge, or not. This is, distributing the profit for all market selections, or not.</p>" +
      "<p>Note: the exit odd will be signaled in green in the ladder view.</p>"
    ),
  },
  "automatic-bets-stop-loss": {
    level: 2,
    topic: _t.translate("10.2 Stop Loss"),
    topicUrl: "automatic-bets-stop-loss",
    title: _t.translate("Automatic bets - Stop Loss"),
    content: _t.translate(
      "<p>When “StopLoss” is activated, the software will place a closing bet if the best available odd moves against you," +
      " to avoid having a loss greater than what you define.</p>" +
      "<p>Possible settings in StopLoss:</p>" +
      "<p>You can choose to exit X number of ticks away from the opening bet, or you can choose to exit when the loss reaches X percentage of the opening stake.</p>" +
      "<p>You can choose to exit in Hedge, or not. This is, distributing the loss for all market selections, or not (keeping the total loss only in this market selection).</p>" +
      "<p>Note: the exit odd will be signaled in red in the ladder view.</p>"
    ),
  },
};
