module.exports = {
  lang: "pt",
  messages: {
    // src\views\afiliado-page.jsx
    "Help us spread the word about {{logoname}}!":
      "Ajude-nos a divulgar o {{logoname}}.",
    "Working in partnership will make us very happy, because together we will develop our products with a potential never seen before.":
      "Trabalharmos em parceria vai deixar-nos muito contentes, porque, juntos desenvolveremos os nossos produtos com um potencial nunca antes visto.",
    "Do you have a website or channel on Youtube? It can transform traffic from your cash networks if you join our affiliate program. Start earning 20% ​​commission on all purchases (except VAT) made by customers associated with you.":
      "Tem um site ou um canal no Youtube? Pode transformar o tráfego das suas redes em dinheiro se aderir ao nosso programa de afiliação. Comece a ganhar 20% de Comissão sobre todas as compras (exceto IVA) feitas pelos clientes associados a si.",
    "Send your application, indicating the name and the link where you want to promote {{logoname}}, to the email {{email}}.":
      "Envie a sua candidatura, indicando o nome e o link onde quer promover o {{logoname}}, para o email {{email}}.",
    "We will review your request and promise to be brief in response.":
      "Analisaremos o seu pedido e prometemos ser breves numa resposta.",
    "See you soon.": "Até Breve",
    //src\views\contact-page.jsx
    "SmartProTrading Contact page": "SmartProTrading contato email",
    "Contact": "Contato",
    "Contact us at {{email}}":
      "Contacte-nos através do email {{email}}",
    "The support team will do its best to respond as quickly as possible, within a maximum of 24 hours (working days).":
      "A equipa de suporte fará os possíveis para responder o mais rapidamente possível, no prazo máximo de 24h (dias úteis).",
    //src\views\faq-page.jsx
    "SmartProTrading FAQ page": "Perguntas Frequentes sobre o SmartProTrading",
    "Can I try the software?": "Posso experimentar o software?",
    "Yes, after downloading, you will have access to a 15-day trial period to test {{logoname}}. That trial period will be associated with your registration, so if you install {{logoname}} on a new platform, the free trial will not start over.":
      "Sim, depois de fazer o download, terá acesso a um período de 15 dias para testar o {{logoname}}. Esse período de teste ficará associado ao seu registo, portanto se instalar o {{logoname}} numa nova plataforma, o período gratuito não vai reiniciar.",
    "How do I register?": "Como faço o registo?",
    "Just download {{logoname}} and on the first login, when registering, you will indicate a valid and accessible email for you, as it will be a fundamental means of contact to communicate with the support team. The software test time count starts at that time. To log in to the software, simply enter your {{betfairLink}} credentials.":
      "Basta fazer o download do {{logoname}} e no primeiro login, será pedido para fazer o registo, vai indicar um email válido e acessível para si, já que será um meio de contato fundamental para comunicar com a equipa do suporte. A contagem de tempo de teste ao software começa nesse momento. Para fazer login no software, basta inserir as suas credencias {{betfairLink}}.",
    "What plans are there? Can I cancel or change at any time?":
      "Que planos existem? Posso cancelar ou alterar a qualquer momento?",
    "To access {{logoname}}, you can choose 3 license plans: Monthly, Annual or Lifetime. The Monthly license get you +31 days, the Annual is +365 days. The Lifetime license is yours forever. There is no need or possiblity to cancel a license, simply do not renew that again. Please make sure you use the free trial period to test the software running on your machine and network configuration, as there is no refunds possible. If you want to change your plan, just make a new purchase on the Prices page.":
      "Para ter acesso ao {{logoname}}, pode optar por 3 planos de licença: Mensal, Anual ou Vitalícia. A licença Mensal representa +31 dias, a Anual são +365 dias. Já a licença Vitalícia é sua para sempre. Não há necessidade ou possibilidade de cancelar uma licença, simplesmente não a renove. Certifique-se de usar o período de teste gratuito para testar o software em execução na sua máquina e na configuração de internet, pois não há reembolso possível. Se pretender alterar o seu plano, basta fazer uma nova compra na página Loja.",
    "Can I use the {{logoname}} website to trade?":
      "Posso usar o site do {{logoname}} para fazer trading?",
    "No, {{logoname}} is a desktop software that requires installation on your computer (Windows, Linux and Mac). The complexity of this type of software requires stability that the browser does not guarantee.":
      "Não, o {{logoname}} é um software que exige instalação no seu computador (Windows, Linux e Mac). A complexidade deste tipo de software obriga a uma estabilidade que o browser não garante.",
    "Where can I see invoices for my purchases?":
      "Onde posso consultar as faturas das minhas compras?",
    "Invoices are automatically sent to the email you used for your {{logoname}} registration, once payments are validated.":
      "As faturas são automaticamente enviadas para o email que usou no registo {{logoname}}, assim que os pagamentos forem validados.",
    "In which countries can {{logoname}} be used?":
      "Em que países o {{logoname}} pode ser usado?",
    "{{logoname}} is available in all countries where {{betfairLink}} operates and accepts customers, except Spain, Italy and Denmark.":
      "O {{logoname}} está disponível em todos os países onde a {{betfairLink}} opera e aceita clientes, excepto Espanha, Itália e Dinamarca.",
    "Have a question? Not sure how to purchase a {{logoname}} license?":
      "Tem uma questão? Não sabe como funciona a compra de uma licença {{logoname}}?",
    "We have collected the answers to the most frequently asked questions.":
      "Reunimos as respostas para as questões mais frequentes que nos são colocadas",
    "If you still haven't found the answer to your question, you can always contact us via email {{email}}.":
      "Se ainda assim não encontrou a resposta para a sua questão, pode sempre contactar-nos através do email {{email}}.",
    //src\views\home-page.jsx
    "SmartProTrading Betfair trading software for MacOs, Linux and Windows":
      "SmartProTrading software de trading na Betfair para MacOs, Linux e Windows",
    "You have found the right trading software for you.":
      "Encontrou o software de trading adequado a si.",
    "Simple, intuitive and customizable": "Simples, intuitivo e personalizável",
    "Trading must be accessible. Without limitations. That's why we simplified the trade - with {{logoname}}.":
      "O trading deve ser acessível. Sem limitações. É por isso que simplificámos o trade - com o {{logoname}}.",
    "A software that responds to the needs of all gamblers and that was created to be everyone's best of the best. Developed by the {{wagertoolLink}} group.":
      "Um software que responde às necessidades de todos os apostadores e que foi criado para ser de todos o melhor dos melhores. Desenvolvido pelo grupo {{wagertoolLink}}.",
    "Bet and Trade accurately, using our integrated response features":
      "Aposte e faça Trading com precisão, através dos nossos recursos de resposta integrados",
    "{{logoname}} is next generation software that will help you with your operations. We designed and developed, in detail, a clean layout suitable for the 3 largest operating systems.":
      "{{logoname}} é o software de nova geração que vai ajudá-lo nas suas operações. Projetámos e desenvolvemos, ao pormenor, um layout limpo e adequado aos 3 maiores sistemas operativos.",
    "Whenever you want, customize {{logoname}} to become a software suited to your trade. Adjust the size of the fonts contained in the Ladder or hide selections that you do not want to work on, for a unique experience.":
      "Quando quiser, personalize o {{logoname}} para se tornar um software adequado ao seu trade. Ajuste o tamanho das fontes contidas na Ladder ou oculte seleções que não quer trabalhar, para obter uma experiência única.",
    "APROVED BY BETFAIR": "APROVADO PELA BETFAIR",
    "Tested and approved by {{betfairLink}}. The security of user information is guaranteed.":
      "Testado e aprovado pela {{betfairLink}}. A segurança das informações do utilizador é garantida.",
    "CUSTOMIZABLE": "CUSTOMIZÁVEL",
    "Designed for professional traders who demand speed and personal customization.":
      "Desenhado para traders profissionais que exigem rapidez e uma costumização pessoal.",
    "MULTIPLATFORM": "MULTIPLATAFORMA",
    "Ready for Windows, Linux and MacOS.":
      "Preparado para Windows, Linux e MacOS.",
    "Download available for": "Download disponível para",
    "15 days free!": "15 dias grátis!",
    "{{logoname}} is cutting edge trading software developed by traders for traders.":
      "{{logoname}} é um software de trading de ponta desenvolvido por traders para traders.",
    "{{logoname}}, built on a multiplatform framework, allows you to keep a single code always up to date from a professional software to do Trading at {{betfairLink}}, and that runs on several operating systems: Windows, MacOS, Linux.":
      "{{logoname}}, construído numa framework multiplataforma, permite manter um só código sempre atualizado de um software profissional para realizar Trading na {{betfairLink}}, e que corre em vários sistemas operativos: Windows, MacOS, Linux.",
    "Trade with speed and precision. Protecting open positions in the market or helping to generate balance make this software unique. Customizable market options facilitate and streamline research, analysis and action.":
      "Faça trading com velocidade e precisão. Proteger posições abertas no mercado ou ajudar a gerar saldo fazem deste software único. As opções personalizáveis ​​de mercado facilitam e agilizam a pesquisa, a análise e a ação.",
    "Use the product and have access to informative content constantly updated and a support team available 7 days a week.":
      "Use o produto e tenha acesso a conteúdo informativo em constante atualização e uma equipa de suporte disponível 7 dias por semana.",
    "The market is always evolving - and so are we. We innovate to offer the latest technology, including advanced features that many of our competitors lack.":
      "O mercado está sempre a evoluir - e nós também. Inovamos para oferecer a tecnologia mais recente, incluindo recursos avançados que muitos dos nossos concorrentes não possuem.",
    "Level up with our professional level trading platform, {{logoname}}":
      "Suba de nível com a nossa plataforma de trading de nível profissional, {{logoname}}",
    "A complete experience, without having to worry about your bankroll.":
      "Uma experiência completa, sem ter que se preocupar com a sua banca.",
    "Grid view, full Ladder view, Multibet view.":
      "Vista de Grelha (Grid), vista completa de Escada (Ladder), vista multi-aposta.",
    "Choose your market view, edit your columns, choose your stake list, define your sound alerts, and more.":
      "Escolha sua vista de mercado, edite as colunas visíveis, defina a sua lista de stakes, e escolha os seus alertas de som.",
    "Simple and flexible pricing to be {{logoname}}.":
      "Preços simples e flexíveis para ser {{logoname}}",
    "Choose the {{logoname}} plan that best suits you.":
      "Escolha o plano {{logoname}} que melhor se adapta a si.",
    "We only offer a 15-day trial period per {{betfairLink}} user that can be used on all platforms. Installing {{logoname}} on a new platform does not restart the trial period.":
      "Apenas oferecemos um período de teste de 15 dias por utilizador {{betfairLink}} que pode ser usado em todas as plataformas. Instalar o {{logoname}} numa nova plataforma não reinicia o período de teste.",
    "Developed by the {{wagertoolLink}} group": "Desenvolvido pelo grupo {{wagertoolLink}}",
    "Testimonials": "Testemunhos",
    "“As a Mac user, I finally found a Betfair trading software that I can use without Windows virtual machine...  Good job team!” Jonathan Souza":
      "“Como usuário de Mac, finalmente encontrei um software de Trading na Betfair que posso usar sem a máquina virtual do Windows... Bom trabalho, equipe!” Jonathan Souza",
    "“It is my favorite software, without a doubt. I can customize it my way and the layout is, of all trading software, the most intuitive.” Diogo Albuquerque":
      "“É o meu software preferido, sem dúvida. Consigo personalizá-lo à minha maneira e o layout é, de todos os softwares de trading, o mais intuitivo.” Diogo Albuquerque",
    "Homepage": "Página Principal",
    //src\views\download-page\downloadRequirements.jsx
    "Technical requirements for {{operatingSystemName}}:": "Requisitos técnicos para {{operatingSystemName}}:",
    "By downloading {{logoname}}, you agree to the terms and conditions.":
      "Ao fazer o download de {{logoname}}, você concorda com os termos e condições.",
    "Software:": "Software:",
    "You need to have a {{betfairLink}} account":
      "Necessita ter uma conta {{betfairLink}}",
    "DOWNLOAD": "DOWNLOAD",
    //src\views\download-page.jsx
    "SmartProTrading download page": "Download SmartProTrading",
    "Trade at {{betfairLink}} with professional tools":
      "Trading na {{betfairLink}} com ferramentas profissionais",
    "Use {{logoname}} for 15 days, free of charge.":
      "Use {{logoname}} por 15 dias, gratuitamente.",
    "You will have full access to all features of the paid version. Your {{logoname}} license is associated with your {{betfairLink}} user. After logging into {{logoname}}, you can use it on any computer without additional licenses.":
      "Você terá acesso total a todos os recursos da versão paga. A sua licença {{logoname}} está associada ao seu usuário {{betfairLink}}. Depois de fazer o login em {{logoname}}, você pode usá-lo em qualquer computador sem licenças adicionais.",
    //src\views\download-page\downloadRequirementsData.js
    "<ul><li> Intel® Core 2 or AMD Athlon® 64 processor; 2 GHz or faster processor</li><li> 2 GB of RAM (4 GB recommended)</li><li> 1 GB of available hard-disk space; additional free space required during installation (cannot install on removable flash storage devices)</li><li> 1280x800 display ( recommended) with 16-bit color and 512 MB of VRAM (1 GB recommended)</li><li> Internet connection: Broadband minimum 4Mbps or high speed mobile (3G).</li></ul>":
      "<ul><li> Intel® Core 2 ou AMD Athlon® 64 processor; processador 2 GHz ou superior</li><li> 2 GB of RAM (4 GB recomendados)</li><li> 1 GB e espaço livre em disco; pode ser necessário espaço adicional durante a instalação (não pode ser instalado em unidades removíveis usb)</li><li> 1280x800 display ( recomendado) com 16-bit color e 512 MB de VRAM (1 GB recomendado)</li><li> Ligação à Internet: banda larga com 4Mbps mínimo ou internet móvel de alta velocidade (3G).</li></ul>",
    "<ul><li> Windows 8.1, Windows 10, Windows Server 2012 R2, Windows Server 2016, Windows Server 2019 or above.</li><li> Microsoft .NET Core 3.1 is required, this will install automatically if not already present on your system.</li></ul>":
      "<ul><li> Windows 8.1, Windows 10, Windows Server 2012 R2, Windows Server 2016, Windows Server 2019 ou superior.</li><li>É necessário ter instalado o Microsoft .NET Core 3.1, que será instalado automaticamente caso necessário.</li></ul>",
    "<ul><li> Mac released after 2013, including a Intel Core i5 dual-core at 1,3 GHz or faster processor</li><li> 4 GB of RAM minimum</li><li> 1 GB of available hard-disk space</li><li> Internet connection: Broadband minimum 4Mbps or high speed mobile (3G).</li></ul>":
      "<ul><li> Mac lançado a partir de 2013, com processador Intel Core i5 dual-core a 1,3 GHz or melhor</li><li> Pelo menos 4 GB de RAM</li><li> 1 GB e espaço livre em disco</li><li> Ligação à Internet: banda larga com 4Mbps mínimo ou internet móvel de alta velocidade (3G).</li></ul>",
    "<ul><li> MacOS® X 10.13 (High Sierra) or above</li></ul>":
      "<ul><li> MacOS® X 10.13 (High Sierra) ou superior</li></ul>",
    "<ul><li> Ubunto18.04LTS or above, or Debian 10 or above</li></ul>":
      "<ul><li> Ubunto18.04LTS ou superior, ou Debian 10 ou superior</li></ul>",
    //\src\components\Header\header.jsx
    "Store": "Loja",
    "TRY NOW": "TESTE AGORA",
    //\src\components\Footer\footer.jsx
    "Copyright © 2020 {{logoname}}. All rights reserved.":
      "Copyright © 2020 {{logoname}}. Todos os direitos reservados.",
    "Terms & Conditions": "Termos & Condições",
    "Affiliate": "Afiliado",
    "Free Trial": "Teste Grátis",
    "Pricing": "Preço",
    "Support": "Suporte",
    "About": "Sobre",
    "FAQ": "FAQ",
    //Store/paymentMethodsPage
    "Choose your payment method": "Escolha o seu método de pagamento",
    "Do not enable recurring payments": "Não ativar pagamentos recorrentes",
    "Continue": "Continuar",
    //Store-tabs
    "Plan": "Plano",
    "Details": "Detalhes",
    "Payment Options": "Métodos de Pagamento",
    "Confirmation": "Confirmação",
    "If the payment page didn't open, please click here": "Se a página de pagamento não abriu automaticamente, por favor clique aqui",
    //priceCards Component
    "Buy": "Comprar",
    "Only {{pricePerDay}} per day": "Apenas {{pricePerDay}} por dia",
    "It's forever yours": "É seu para sempre",
    "Choose your plan": "Escolha o seu plano",
    "Lifetime": "Vitalício",
    "Yearly": "Anual",
    "Monthly": "Mensal",

    //SummaryStore
    "Summary": "Sumário",
    "Price": "Preço",
    " (VAT included)": " (IVA incluído)",
    "Total": "Total",
    //DetailsPage
    "Account details": "Detalhes de conta",
    "Country": "País",
    "Add invoice details": "Adicionar dados de faturação",
    "Betfair Vendor Client ID": "Betfair Vendor Client ID",

    //ConfirmationPage
    "Payment Method": "Método de Pagamento",

    //Input-Vendor-Client-Id
    "How do I know my Betfair Vendor Client ID?":
      "Como saber o meu Betfair Vendor Client ID?",
    "The Betfair Vendor Client ID is a unique number that links your {{betfairLink}} username to Trading software.":
      "O Betfair Vendor Client ID é um número único que liga o seu username {{betfairLink}} a um software de Trading",
    "You can find it within the software by clicking on your username. Or, if you do not currently have access to {{logoname}}, you can view it at login.":
      "Pode encontrá-lo dentro do software, se clicar no seu username. Ou, caso nao tenha acesso neste momento ao {{logoname}}, pode visualizá-lo no login.",
    "Confirm": "Confirmar",
    "Please check your Betfair Vendor Client Id on the software":
      "Por favor verifique o seu Betfair Client Id no software",

    //SwipperHome
    "Market Views": "Vistas de Mercado",
    "Customizable": "Customizável",
    "Training Mode": "Modo de Treino",
    "Snapshot 1": "Vista 1",
    "Snapshot 2": "Vista 2",
    "Snapshot 3": "Vista 3",

    //Input-zipCode
    "Zip-Code": "Código Postal",
    "Zip-Code is required": "Códgio Postal é obrigatório",
    "Zip-Code is not valid": "Código postal não é válido",
    "At least {{minimumLength}} characters required":
      "São necessários pelo menos {{minimumLength}} caracteres",

    //Input-client-vat-number
    "VAT Number": "NIF",
    "VAT number is required": "NIF é obrigatório",
    "VAT number is not valid": "NIF não é válido",

    //input-client-name
    "Name": "Nome",
    "Name is required": "Nome é obrigatório",
    "Name is not valid": "Nome não é válido",

    //input-client-city
    "City": "Cidade",
    "City is required": "Cidade é obrigatório",
    "City is not valid": "Cidade não é válido",

    //input-client-address
    "Billing Address": "Endereço de faturação",
    "Billing address is required": "Endereço de faturação é obrigatório",
    "Billing address is not valid": "Endereço de faturação não é válido",

    //input-client-id-number
    "Id Number": "Número de Identificação",
    "Id number is required": "Número de Identificação é obrigatório",
    "Id number is not valid": "Número de Identificação não é válido",

    // src\views\loja-page.jsx
    "SmartProTrading Shop page": "Loja SmartProTrading",
    // src\views\loja-return-page.jsx
    "Thank you for buying a SmartProTrading license!": "Obrigado por comprar uma licença SmartProTrading!",
    "Thank you for buying a {{logoname}} license!": "Obrigado por comprar uma licença {{logoname}}!",
    "Something went wrong... try again?": "Algo correu mal... tentar novamente?",
    "Loading...": "Loading...",
    "Order completed": "Compra completa",
    "Order canceled...": "Compra cancelada...",
    "Your license has been automatically credited, <strong>you have {{numberDays}} more days of license</strong>. The purchase invoice was sent to your software registration email.": "A sua licença foi creditada automaticamente, <strong>tem mais {{numberDays}} dias de licença</strong>. A fatura de compra foi enviada para o seu email de registo no software.",
    "If you still had remaining license days, this new license only added days to your end date, you didn't lose a single day.": "Caso ainda tivesse dias de licença válidos, esta nova licença apenas somou dias à sua data final, nao perdeu dia nenhum.",
    "You can now continue using {{logoname}}, professional trading software for Betfair.": "Já pode continuar a utilizar o {{logoname}}, software profissional de trading na Betfair.",
    "If you have any questions regarding the use of the software, consult our <a class='isLink' href='/user-guide'>User Guide</a> or contact us via email {{email}}": "Se tiver alguma dúvida na utilização do software, consulte o nosso <a class='isLink' href='/user-guide'>Guia de Utilizador</a> ou entre em contato conosco através do e-mail {{email}}",
    "Payment is being processed, it may take a few minutes.": "O pagamento está a ser processado, pode levar alguns minutos.",
    "As soon as the license is credited, you will receive the purchase invoice in your software registration email.": "Assim que a licença for creditada, a fatura de compra será enviada para o seu email de registo no software.",
    "The payment has been canceled.": "O pagamento foi cancelado.",
    "Please click on <a class='isLink' href='/user-guide'>Store</a> again to repeat the purchase process.": "Clique novamente em <a class='isLink' href='/user-guide'>Loja</a> para repetir o processo de compra.",
    "If you have any questions or problems, contact us via email {{email}}": "Se tiver alguma dúvida ou problema, entre em contato conosco através do e-mail {{email}}",
    "An error occurred, payment not completed.": "Ocorreu um erro, o pagamento não foi concluído.",
    "Error code:": "Código de erro:",
    "Error description:": "Descrição do erro:",

    //src\views\guia-page.jsx
    "User Guide": "Guia de Utilizador",

    //src\views\userGuide-page\userGuide-topic.jsx
    "SmartProTrading User Guide": "Guia de Utilizador SmartProTrading",
    //src\views\userGuide-page\userGuideListData.js
    "1. Register": "1. Registo",
    "SmartProTrading registration": "Registo no SmartProTrading",
    "<p>After installing SmartProTrading on your computer, fill in the blanks and provide a valid and accessible email for you, as it will be a fundamental means of contact to communicate with the support team. The trial period starts at this point.</p>":
      "<p>Depois da instalação do SmartProTrading no seu computador, preencha os espaços em branco e faculte um email válido e acessível para si, já que será um meio de contacto fundamental para comunicar com a equipa do suporte. A contagem de tempo de teste ao software começa neste momento.</p>",
    "2. Login": "2. Login",
    "2.1 Login betfair": "2.1 Login betfair",
    "Login with Betfair": "Login com conta Betfair",
    "<p>To login to the software, simply enter your Betfair credentials.</p><p>If you activate the “Remember Username” option, only your username will be saved. For security reasons, Betfair does not allow the password to be saved, even on your own computer.</p>":
      "<p>Para fazer login no software, basta inserir as suas credencias Betfair.</p><p>Se ativar a opção “Recordar Username”, apenas o seu username será salvo. Por questões de segurança, a Betfair não permite que a senha seja guardada, nem mesmo no seu próprio computador.</p>",
    "Login with Betfair in SmartProTrading software": "Login conta conta Betfair no software SmartProTrading",
    "2.2 Two step authentication": "2.2 Autenticação de dois passos",
    "Login with Two-step authentication": "Login com autenticação de dois passos",
    "<p>If two-step authentication is enabled on your Betfair account, you will need to go through this step at SmartProTrading login: simply enter both values in the PASSWORD field, this is, enter your Betfair password followed by the verification code in the PASSWORD field (without spaces or any other separator).</p>":
      "<p>Se a autenticação de pois passos estiver ativada na sua conta Betfair, você terá de fazer isto no login do SmartProTrading: basta inserir os dois valores no campo PASSWORD, isto é, inserir sua senha Betfair seguida do código de verificação (sem espaços ou qualquer outro separador).</p>",
    "3. Training mode": "3. Modo de treino",
    "Training mode": "Modo de treino",
    "<p>You don't know the features of the software and you are afraid to risk your money? Activate the Training Mode for a complete experience, without having to worry about your bankroll.</p><p>The Training Mode will allow you to simulate many of the actions you want to do in real markets.</p><p>However, a note: it will not be possible to mix real and simulated bets, that is, when you place a bet in this mode, it is automatically matched, if money is available on the market, unlike real bets, which may take a while to be matched.</p>":
      "<p>Não conhece as funcionalidades do software e tem receio de arriscar o seu dinheiro? Ative o Modo de treino para uma experiência completa, sem ter que se preocupar com a sua banca.</p><p>O Modo de Treino vai permitir-lhe simular muitas das ações que quer fazer em mercados reais.</p><p>No entanto, uma nota: não será possível misturar apostas reais e simuladas, ou seja, quando coloca uma aposta neste modo, a mesma é automaticamente correspondida, caso haja dinheiro disponível no mercado, ao contrário das apostas reais, que podem tardar um pouco a serem correspondidas.</p>",
    "Practice mode is available in SmartProTrading": "O mode de treino está disponível no SmartProTrading",
    "4. SmartProTrading Layout": "4. Layout do SmartProTrading",
    "SmartProTrading layout": "Layout do SmartProTrading",
    "<p>The SmartProTrading layout is organized in independent windows, which means that the software offers the possibility to be adjusted and positioned by the user.</p><p>The main bar window (left sidebar) will be the only window that will remain open as long as the software is connected to your computer. All other windows can be resized or even closed.</p>":
      "<p>O layout do SmartProTrading está organizado em janelas independentes, o que quer dizer que o software oferece a possibilidade de ser ajustado e posicionado pelo utilizador.</p><p>A janela da barra principal será a única janela que se manterá aberta, enquanto o software estiver ligado no seu computador. Todas as outras janelas podem ser redimensionadas ou até fechadas.</p>",
    "SmartProTrading floating layout allow you to open as many markets as needed": "O layout flutuante SmartProTrading permite abrir simultaneamente todos os mercados que necessitar",
    "5. Main bar of SmartProTrading": "5. Barra principal do SmartProTrading",
    "Main bar of SmartProTradingt (left sidebar)": "Barra principal do SmartProTrading (à esquerda)",
    "<p>The main bar of SmartProTrading is divided into 4 tabs: Markets, Sports, Active Markets and Settings.</p><p>Above these tabs, there is an area with user information, including the available balance and exposure, a search bar to facilitate the search for a certain event, and also the button to turn on/off the training mode.</p>":
      "<p>A barra principal do SmartProTrading divide-se em 4 tabs: Mercados, Desportos, Mercados Ativos e Definições.</p><p>Fora destas tabs, há uma área com informações do user, incluindo o saldo disponível e exposição, uma barra de pesquisa, de forma a facilitar a procura de um determinado evento e ainda o botão para Ligar/Desligar o Modo de treino.</p>",
    "5.1 Markets": "5.1 Mercados",
    "Main bar - Markets Filters": "Barra principal - Filtros de mercados",
    "<p>In the Markets tab, you can see the events that are taking place or that are going to start soon.<p><p>Default filters are made for the most wanted sports: Football, Tennis and Horse Racing. But you can edit and add your prefered filters.</p><p>Note that changing the total dropdown will allow you to see more markets.</p><p>When you click on each event, an independent window will open with market information. By default it opens the Grid view - there is the possibility to change the standard interface when opening the market in “Settings”).</p>":
      "<p>Na tab Mercados, consegue visualizar os eventos que estão a decorrer ou que vão iniciar brevemente.</p><p>Os filtros padrão são feitos para os desportos mais procurados: Futebol, Tênis e Corridas de Cavalos. Mas você pode editar e adicionar seus filtros preferidos.</p><p>Observe que alterar o dropdown de total de mercados, permitirá que você veja mais resultados do filtro.</p><p>Ao dar um clique em cima de cada evento, irá abrir-se uma janela independente com informação do mercado (por defeito abre a vista Grid - há a possibilidade de alterar a interface padrão ao abrir o mercado em “Definições”).</p>",
    "SmartProTrading custom market filters results on the main sidebar": "Resultados de filtros de mercado personalizados na barra lateral principal",
    "5.2 Sports": "5.2 Desportos",
    "Main bar - Sports": "Barra principal - Desportos",
    "<p>As an alternative to the search bar at the top of the SmartProTrading Main Bar, it is possible to search for a specific event in this “Sports” tab.</p><p>The search for markets is presented in the form of a tree: sport-competition-event-market, in which competitions and markets are alphabetically ordered.</p>":
      "<p>Em alternativa à barra de pesquisa que se encontra no topo da Barra Principal do SmartProTrading, é possível procurar um determinado evento nesta tab “Sports”.</p><p>A busca de mercados é apresentada em forma de árvore: desporto- competição-evento-mercado, na qual as competições e mercados estão ordenados alfabeticamente.</p>",
    "Betfair all markets navigation, on the main sidebar, sports tab": "Navegação de mercados completa da Betfair, na barra lateral principal, tab Desportos",
    "5.3 Active markets": "5.3 Mercados ativos",
    "Main bar - Active markets": "Barra principal - Mercados ativos",
    "<p>In “Active Markets”, at the top, you can see the total CashOut value.</p><p>When this tab is selected, the list of all markets where you have open bets appears that you can easily use to access each of the markets, or even cash out each market individually.</p>":
      "<p>Em “Mercados Ativos”, logo no topo, é possível ver o valor de CashOut total.</p><p>Quando selecionada esta tab, aparece a lista de todos os mercados onde tem apostas abertas que pode usar, facilmente, para aceder a cada um dos mercados, ou mesmo fazer cash out de cada mercado individualmente.</p>",
    "Quick view of all the active markets where you have open bets. Global cashout button is also available here.": "",
    "5.4 Settings": "5.4 Definições",
    "Main bar - Settings": "Barra principal - Definições",
    "<p>Customize your SmartProTrading! There are 5 tabs of settings that you can use to make it in your own way:</p><p><strong>GENERAL:</strong> change the software language; change the view when opening a new market (Grid, Ladder and Multi-bet); choose to see or not see Live Scores; and edit how the Weight of Money is calculated.</p><p><strong>MARKET:</strong> confirm bet before placing it; include Betfair commission in cashout and hedge column values; configure Cash Out Mode, this is, choose if you want or not to keep unmatched bets when using the cash out and hedge.</p><p><strong>STAKES:</strong> edit your personal list of stakes that you will use to quickly change the stake in the market view, without any limit on the number of stakes you can add.</p><p><strong>LADDER:</strong> you can define the line height and font size in the ladder view; you can choose to always see the profit in the Hedge column or restart each time you close a trade; and you can add or hide some visible columns.</p><p><strong>SOUNDS:</strong> you can turn on/off alert sounds for several actions, like: market is going live, market has entered in-play, market suspended, bet placed, bet matched, bet partially matched, bet error, cashout.</p>":
      "<p>Personalize o seu SmartProTrading! Existem 5 tabs de definições que pode utilizar para personalizar o seu software:</p><p><strong>GERAL:</strong> alteração do idioma do software; alteração da vista pré-definida ao abrir um novo mercado (Grelha, Escada e Multiaposta); optar por ver ou não ver Live Scores; e editar a forma como o Peso do Dinheiro é calculado.</p><p><strong>MERCADO:</strong> confirmar aposta antes de colocá-la; incluir comissão Betfair nos valores do cashout e coluna hedge; configure o Modo de Cash Out, isto é, escolha se quer manter ou não as apostas não correspondidas ao usar a função de cash out/hedge.</p><p><strong>STAKES:</strong> edite a sua lista de stakes pessoal que irá usar para rapidamente alterar a stake na vista de mercado, sem qualquer limite para o número de stakes que pode acrescentar.</p><p><strong>LADDER:</strong> pode definir a altura da linha e o tamanho da letra na vista de escada; pode optar por ver sempre o lucro na coluna Hedge ou reiniciar a cada fez que fecha um trade; e pode acrescentar ou ocultar algumas colunas visíveis.</p><p><strong>SONS:</strong> Na tab sons pode LIGAR e DESLIGAR os sons de sistema para as seguintes ações: mercado vai entrar ao vivo, mercado ao vivo, mercado suspenso, aposta colocada, aposta correspondida, aposta parcialmente correspondida, erro de aposta, Cash Out.</p>",
    "SmartProTrading software settings, general tab": "Definições do software SmartProTrading, tab geral",
    "SmartProTrading software settings, market tab": "Definições do software SmartProTrading, tab mercado",
    "SmartProTrading software settings, stakes and keyboard shortcuts": "Definições do software SmartProTrading, tab stakes e atalhos de teclado",
    "SmartProTrading software settings, ladder layout customization": "Definições do software SmartProTrading, tab visualização da ladder",
    "SmartProTrading software settings, sound alerts": "Definições do software SmartProTrading, tab alertas de sons",
    "6. Grid view": "6. Vista de grelha (Grid)",
    "Grid view": "Vista de grelha (Grid)",
    "<p>The grid view of SmartProTrading has a betting interface very similar to the one found on the Betfair website, however, it is faster and gathers important information for a very effective performance.</p><p>Suitable for markets with many selections, such as horse racing, or for certain football events, the grid view has a simple and intuitive layout.</p><p>In addition to the grid itself, there is the bar shown at the top, where you can find information about the event, the value traded on the market and some buttons: Cashout button, change to other Market of the same event, change to Ladder view, and the Market Settings button where you can choose the type of stake and the type of Automatic Betting.</p>":
      "<p>A vista de grelha do SmartProTrading tem uma interface de aposta muito semelhante a que encontra no site da Betfair, no entanto, é mais rápida e reúne informações importantes para um desempenho muito eficaz.</p><p>Indicada para mercados com muitas seleções, como é o caso das corridas de cavalos, ou para determinados eventos de futebol, a vista de grelha apresenta um layout simples e intuitivo.</p><p>Para além da grelha propriamente dita, existe a barra apresentada no topo, onde se encontram informações sobre o evento, o valor transacionado no mercado e alguns botões: botão de Cashout, troca de Mercado, troca de Vista e o botão das Definições, onde pode escolher o tipo de stake e o tipo de Apostas Automáticas.</p>",
    "Grid view, good to keep an eye on several runners at once": "Visualização em grelha, permite ficar de olho em vários corredores ao mesmo tempo",
    "7. Ladder view": "7. Vista de escada (Ladder)",
    "Ladder view": "Vista de escada (Ladder)",
    "<p>The ladder view is not available on the Betfair website, but it is one of the most popular views among users of trading software.</p><p>This view has a global and intuitive view of the odds ladder, so you can easily understand the behavior of the market and place bets, which will be immediately matched or will be on the waiting list to be matched as soon as the market moves.</p><p>You will be able to see that there are many graphic indications, for example, through the additional columns, which will allow you to anticipate the way the market reacts.</p><p>The top of this view is very similar to that of the Grid View, where you can change stake, change market, use market settings and cashout.</p>":
      "<p>A vista de escada não está disponível no site da Betfair, mas é uma das mais populares entre os utilizadores de softwares de trading de apostas.</p><p>Esta vista tem uma visão global e intuitiva da escada de odds, por isso pode facilmente perceber o comportamento do Mercado e colocar apostas, que serão imediatamente correspondidas ou ficarão em lista de espera para serem correspondidas assim que o mercado se mova.</p><p>Vai conseguir perceber que existem muitas indicações gráficas, por exemplo, através das colunas adicionais, que lhe vão permitir antecipar a forma como o mercado reage. O topo desta vista assemelha-se bastante ao da Vista em Grelha onde pode mudar a sua Stake, mudar de mercado, abrir as definições do mercado ou usar o cashout.</p>",
    "Ladder view, best view to understand market movements, and to make the market.": "Visão de escada, a melhor visão para entender os movimentos do mercado e colocar apostas em espera.",
    "8. Multi-bet view": "8. Vista multi-aposta",
    "Multi-bet view": "Vista multi-aposta",
    "<p>The multi bet view is another betting interface that SmartProTrading offers and which is not available on the Betfair website.</p><p>When selected, it will allow you to place several bets at the same time on the market you have chosen.</p><p>There are 4 trading strategies that you should know and that you can use within the software: two for Back bets (Dutching and Back-the-Field) and two for Lay bets (Bookmaking and Lay-the-Field).</p><p><strong>BACK - Dutching:</strong> select the runners that you think can win and quickly place bets in all of them;</p><p><strong>BACK - Back-the-Field:</strong> identical view to Dutching, but with an input to define a global stake that will automatically be divided for the selected runners (according to their odds, in order to hedge the profits).</p><p><strong>LAY - Bookmaking:</strong> select the runners that will not win and quickly place bets in all of them;</p><p><strong>LAY - Lay-the-Field:</strong> identical view to Bookmaking, but with an input to define a global stake that will automatically be divided for the selected runners (according to their odds, in order to hedge the profits).</p>":
      "<p>A vista multiaposta é outra das interfaces de aposta que o SmartProTrading disponibiliza e que não está disponível no site da Betfair.</p><p>Quando selecionada, vai permitir-lhe a colocação de várias apostas ao mesmo tempo no mercado que escolheu.</p><p>Na prática, existem 4 estratégias de trading que deve conhecer e que pode utilizar dentro do software: duas para apostas Back (Dutching e Back the Field) e duas para apostas Lay (Bookmaking e Lay the Field).</p><p><strong>Apostar BACK - Dutching:</strong> selecione os corredores que você acha que podem ganhar e faça apostas rapidamente em todos eles;</p><p><strong>Apostar BACK - Back-the-Field:</strong> visão idêntica à Dutching, mas pode definir uma aposta global que será automaticamente dividida pelos corredores selecionados (de acordo com suas odds, a fim de distribuir os lucros);</p><p><strong>Apostar LAY - Bookmaking:</strong> selecione os corredores que não ganharão e faça apostas rapidamente em todos eles;</p><p><strong>Apostar LAY - Lay-the-Field:</strong> vista idêntica à do Bookmaking, mas com um input para definir uma aposta global que será automaticamente dividida pelos corredores selecionados (de acordo com as suas odds, de forma a distribuir os lucros).</p>",
    "Multibet view, bookmaking": "Vista multi-aposta, bookmaking",
    "Multibet view, lay the field strategy optimization": "Vista multi-aposta, vista otimizada para a estratégia lay-the-field",
    "9. Stake types": "9. Tipos de Stake",
    "Stake types overview": "Resumo dos tipos de aposta",
    "<p>Make your entries, varying between the types of stake that SmartProTrading offers.</p><p>There are 4 stake types and each has a characteristic color: Stake (blue), Liability (pink), Profit (green) and Mix (purple).</p>":
      "<p>Faça as suas entradas, variando entre os tipos de stake que o SmartProTrading disponibiliza.</p><p>São 4 tipos e cada um tem uma cor característica: Stake (azul), Liability (rosa), Profit (verde) e Mix (roxo).</p>",
    "9.1 Stake": "9.1 Stake",
    "Stake": "Stake",
    "<p>In “STAKE” mode, the value you define for your stake (marked in blue) corresponds to the total stake of the Back bets and the potential profit in the case of Lay bets.</p><p><strong>BACK:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p><p><strong>LAY:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>":
      "<p>No modo “STAKE”, o valor escolhido por si da sua lista de stakes (assinalado a azul) corresponde à stake total das apostas Back e ao lucro potencial no caso das apostas Lay.</p><p><strong>BACK:</strong> a aposta escolhida é a quantia que você <strong>corre o risco de perder</strong>.<p><strong>LAY:</strong> a aposta escolhida é a quantia que você <strong>pode ganhar</strong>.</p>",
    "Normal stake mode": "Modo normal Stake",
    "9.2 Liability": "9.2 Responsabilidade",
    "Liability": "Liability (Responsabilidade)",
    "<p>In “LIABILITY” mode, the value you define for your stake (marked in pink) corresponds to the amount you risk losing both in the Back and Lay bets.</p><p><strong>BACK:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p><p><strong>LAY:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>":
      "<p>No modo “LIABILITY”, o montante selecionado na sua lista de stakes personalizada (assinalado a rosa) corresponde à Stake Total das apostas Back e à responsabilidade total no caso das apostas Lay.</p><p><strong>BACK</strong> a aposta escolhida é a quantia que você <strong>corre o risco de perder</strong>.</p><p><strong>LAY</strong> a aposta escolhida é a quantia que você <strong>corre o risco de perder</strong>.</p>",
    "Liability stake mode": "Modo de stake Responsabilidade",
    "9.3 Profit": "9.3 Profit",
    "Profit": "Profit (lucro)",
    "<p>In “PROFIT” mode, the value you define for your stake (marked in green) corresponds to the potential profit both in the Back and Lay bets.</p><p><strong>BACK:</strong> the chosen stake is the amount you <strong>can win</strong>.</p><p><strong>LAY:</strong> the chosen stake is the amount you <strong>can win</strong>.</p>":
      "<p>No modo “PROFIT”, o montante que se encontra sobre o retângulo verde refere-se ao lucro potencial nas apostas Back e também nas apostas Lay.</p><p><strong>BACK</strong> a aposta escolhida é a quantia que você <strong>pode ganhar</strong>.</p><p><strong>LAY</strong> a aposta escolhida é a quantia que você <strong>pode ganhar</strong>.</p>",
    "Profit stake mode": "Modo de stake Profit",
    "9.4 Mix": "9.4 Mix",
    "Mix": "Mix",
    "<p>In “MIX” mode is the inverse of Stake mode. In the “MIX” mode, the value you define for your stake (marked in purple) corresponds to the potential profit in Back bets and the amount you risk losing in the case of Lay bets.</p><p><strong>BACK:</strong> the chosen stake is the amount you <strong>can win</strong>.</p><p><strong>LAY:</strong> the chosen stake is the amount you <strong>risk losing</strong>.</p>":
      "<p>O tipo “MIX” é o inverso do modo Stake. Quando selecionado o modo “Mix” tem a cor roxa e significa que, em apostas Back é o lucro potencial e, em apostas Lay é a responsabilidade total.</p><p><strong>BACK</strong> a aposta escolhida é a quantia que você <strong>pode ganhar</strong>.</p><p><strong>LAY</strong> a aposta escolhida é a quantia que você <strong>corre o risco de perder</strong>.</p>",
    "Mix stake mode": "Modo de stake Mix",
    "10. Automatic bets": "10. Apostas automáticas",
    "Automatic bets": "Apostas automáticas",
    "<p>SmartProTrading has tools that become essential for successful trading.</p><p>One of them is the variety of automatic bets that are available in the “Market Settings” icon.</p>":
      "<p>O SmartProTrading dispõe de ferramentas que se tornam fundamentais para um trading de sucesso.</p><p>Uma delas é a variedade de apostas automáticas que estão disponíveis no ícone de “Definições da Vista de Mercado”.</p>",
    "10.1 Tick Offset": "10.1 Tick offset",
    "Automatic bets - Tick Offset": "Apostas automáticas - Tick offset",
    "<p>When “TickOffset” is activated, it is a tool that automatically closes a trade when reached the disered profit.</p><p>If you started the trade with a Back bet, the software will automatically place the Lay bet when reached the exit creteria defined.</p><p>If you started the trade with a Lay bet, the software will automatically place the Back bet when reached the exit creteria defined.</p><p>Possible settings in TickOffset:</p><p>You can choose to exit X number of ticks away from the opening bet, or you can choose to exit when the profit reaches X percentage of the opening stake.</p><p>You can choose to exit in Hedge, or not. This is, distributing the profit for all market selections, or not.</p><p>Note: the exit odd will be signaled in green in the ladder view.</p>":
      "<p>Quando o “Tick Offsiet” (ou Auto-fecho) é ativado, o software fecha automaticamente um trade quanto atinge o lucro definido previamente por si.</p><p>Se o trade começou com uma aposta Back, o software colocará uma aposta Lay quanto atingir o critério de saída defindo por si.</p><p>Se op trade começa com uma aposta Lay, então a aposta automática  de fecho em lucro será uma aposta Back.</p><p>Configurações possíveis no TickOffset:</p><p>Você pode escolher sair de um número X de ticks distante da aposta inicial, ou pode escolher sair quando o lucro atingir uma percentagem X da aposta inicial.</p><p>Você pode escolher sair em Hedge ou não. Isto é, distribuir o lucro para todas as seleções de mercado, ou não.</p><p>Observação: a odd de saída será sinalizada em verde na vista da Ladder.</p>",
    "10.2 Stop Loss": "10.2 Stop loss",
    "Automatic bets - Stop Loss": "Apostas automáticas - Stop loss",
    "<p>When “StopLoss” is activated, the software will place a closing bet if the best available odd moves against you, to avoid having a loss greater than what you define.</p><p>Possible settings in StopLoss:</p><p>You can choose to exit X number of ticks away from the opening bet, or you can choose to exit when the loss reaches X percentage of the opening stake.</p><p>You can choose to exit in Hedge, or not. This is, distributing the loss for all market selections, or not (keeping the total loss only in this market selection).</p><p>Note: the exit odd will be signaled in red in the ladder view.</p>":
      "<p>Quando “StopLoss” é ativado, o software fará uma aposta de fecho automática se a melhor odd disponível se mover contra você, para evitar uma perda maior do que você definir.</p><p>Possíveis configurações em StopLoss:</p><p>Você pode escolher sair com um número X de ticks distante da aposta inicial ou pode escolher sair quando a perda atingir uma percentagem X da aposta inicial.</p><p>Você pode escolher sair em Hedge ou não. Isto é, distribuir a perda por todas as seleções de mercado, ou não (mantendo a perda total apenas nesta seleção de mercado).</p><p>Observação: a odd de saída será sinalizada em vermelho na vista da Ladder.</p>",
    // "": "",

  },
};
