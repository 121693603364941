import { defaultPaymentMethods } from "../../model/myTypesDefault";
import { defaultOrderPaymentResult } from "../../model/myTypesDefault";

const initialStateOptionsReducer = {
  lang: "en",
  langUserChanged: false,
  currency: "USD",
  currencyUserChanged: false,
  countryGeoIp: "UK", // will be changed by "geoip_record
};

const initialStateStoreReducer = {
  products: [],
  currentStep: 1,
  paymentMethods: [defaultPaymentMethods],
  tempVendorClientId: "",
  tempClientVatNumber: "",
  tempClientZipCode: "",
  order: {
    productId: "",
    currency: "",
    vendorClientId: "",
    invoiceDetails: {
      isCustomDetails: false,
      countryIso2: "",
      name: "",
      address: "",
      zipCode: "",
      city: "",
      vatNumber: "",
    },
    paymentTypeId: "-1",
  },
  isLoadingOrder:false,
  orderResponse: {
    hasError: false,
    errorMessage: "",
    paymentMethodId: 1,
    name: "",
    Url: "",
  },
  orderPaymentResult: { ...defaultOrderPaymentResult },
};

export const INITIAL_STATE = {
  options: initialStateOptionsReducer,
  store: initialStateStoreReducer,
};
