import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./details-page.module.css";
import { _t } from "src/redux/store";
import renderHTML from "html-react-parser";
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { ButtonBase } from "@material-ui/core";
import {
  checkVendorClientId,
  changeTemporaryVendorClientId,
  cleanInvoiceDetails,
} from "src/redux/actions/action-store.js";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import { TextField } from "@material-ui/core";
import { renderBetfairText } from "src/components/SmartProTrading/renderLinks";

const BF_VENDOR_CLIENT_ID_TUTORIAL_IMG = 'Betfair_VendorCliendId.jpg';
const BF_VENDOR_CLIENT_ID_TUTORIAL_IMG_ALT = 'Betfair Vendor Client ID';

class InputVendorClientId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewer: {
        visible: false,
        img: "",
        width: "",
        height: "",
        alt: "",
      },
    };
  }

  handleClickToView = () => {
    this.setState({
      viewer: {
        img: "/img/" + BF_VENDOR_CLIENT_ID_TUTORIAL_IMG,
        width: 1080,
        height: 1466,
        alt: BF_VENDOR_CLIENT_ID_TUTORIAL_IMG_ALT,
        visible: true,
      },
    });
  };

  handleCloseViewer() {
    this.setState({
      viewer: {
        ...this.state.viewer,
        visible: false,
      },
    });
  }

  handleOnChangeVendorClientId = (event) => {
    this.props.changeTemporaryVendorClientId(event.target.value);
  };

  handleConfirmVendorClientId = () => {
    this.props.checkVendorClientId(this.props.tempVendorClientId);
  };

  handleResetVendorClientId = () => {
    this.props.changeTemporaryVendorClientId(this.props.orderVendorClientId);
    this.props.cleanInvoiceDetails();
  };

  render() {
    const hasError = this.props.tempVendorClientId === -1;
    const isButtonActive = this.props.tempVendorClientId.toString().length > 0;
    const isVendorClientIdConfirmed = this.props.orderVendorClientId !== "";
    return (
      <div className={styles.inputVendorId}>
        <div className={styles.betFairForm}>
          <div className={styles.textInputBox}>
            <TextField
              classes={{
                root: classnames(
                  hasError === true
                    ? styles.vendorClientInputError
                    : styles.vendorClientInput
                ),
              }}
              required
              error={hasError === true}
              id="bf-vendor-client-id"
              label="Betfair Vendor Client Id"
              helperText={
                hasError === true
                  ? _t.translate(
                    "Please check your Betfair Vendor Client Id on the software"
                  )
                  : ""
              }
              variant="outlined"
              onChange={this.handleOnChangeVendorClientId}
              disabled={isVendorClientIdConfirmed === true}
              defaultValue={
                isVendorClientIdConfirmed === true
                  ? this.props.orderVendorClientId
                  : this.props.tempVendorClientId === -1
                    ? ""
                    : this.props.tempVendorClientId
              }
            />
          </div>
          {isVendorClientIdConfirmed === false ? (
            <ButtonBase
              type="submit"
              classes={{
                root: classnames(
                  isButtonActive === true && !hasError
                    ? styles.confBtnAct
                    : styles.confBtnDeact
                ),
              }}
              disabled={isButtonActive === false || hasError}
              onClick={this.handleConfirmVendorClientId}
            >
              {_t.translate("Confirm")}
            </ButtonBase>
          ) : (
            <div className={styles.icons}>
              <span>
                <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
              </span>
              <ButtonBase onClick={this.handleResetVendorClientId}>
                <EditIcon></EditIcon>
              </ButtonBase>
            </div>
          )}
        </div>
        <div className={styles.popupTip}>
          {isVendorClientIdConfirmed === false && (
            <div>
              <div>
                <br />
                <p
                  id="popup"
                  onClick={() => this.handleClickToView()}
                  className={styles.vendorText}
                >
                  {_t.translate("How do I know my Betfair Vendor Client ID?")}
                </p>
                <p className={styles.smallText}>
                  {renderHTML(_t.translate(
                    "The Betfair Vendor Client ID is a unique number that links your {{betfairLink}} username to Trading software.",
                    { betfairLink: renderBetfairText() }
                  ))}
                </p>
                <p className={styles.textSmall} ref={(el) => setInnerLogo(el)}>
                  {renderHTML(
                    _t.translate(
                      "You can find it within the software by clicking on your username. Or, if you do not currently have access to {{logoname}}, you can view it at login.",
                      {
                        logoname: '<span class="logoHere"></span>',
                      }
                    )
                  )}
                </p>
              </div>
              <PhotoSwipe
                isOpen={this.state.viewer.visible}
                items={[
                  {
                    src: this.state.viewer.img,
                    thumbnail: this.state.viewer.img,
                    w: this.state.viewer.width,
                    h: this.state.viewer.height,
                    title: this.state.viewer.alt,
                  },
                ]}
                onClose={() => this.handleCloseViewer()}
              />
              <div className={styles.betfairVendor}>
                <ButtonBase onClick={this.handleClickToView}>
                  <img src={"/img/" + BF_VENDOR_CLIENT_ID_TUTORIAL_IMG} height="150px" alt={BF_VENDOR_CLIENT_ID_TUTORIAL_IMG_ALT}></img>
                </ButtonBase>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tempVendorClientId: state.store.tempVendorClientId,
    orderVendorClientId: state.store.order.vendorClientId,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    checkVendorClientId: (vendorClientId) => {
      dispatch(checkVendorClientId(vendorClientId));
    },
    changeTemporaryVendorClientId: (newVendorClientId) => {
      dispatch(changeTemporaryVendorClientId(newVendorClientId));
    },
    cleanInvoiceDetails: () => {
      dispatch(cleanInvoiceDetails());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputVendorClientId);
