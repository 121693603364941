/**
 * CONSTANT options for the application
 *  import { homepageUri } from 'src/myEnv';
 */
export const homepageUri = `${window.location.protocol}//${window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/`;

export const REQ_INVOICE_INPUT_MIN_LENGTH = 4;

export const EMAIL = {
  name: 'info', domain: '&#64;smartprotrading.com'
}

export const WAGERTOOL_LINK = "https://www.wagertool.com/";

export const BETFAIR_LINK = "http://ads.betfair.com/redirect.aspx?pid=2496922&bid=10201";
