import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./sidebar.module.css";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import {
  changeLang,
  getGeoIpCountry,
  changeCurrency,
} from "src/redux/actions/action-options.js";
import { _t } from "src/redux/store";
import { changeStep } from "src/redux/actions/action-store";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.props.getGeoIpCountry();
  }
  handleChangeStep = () => {
    this.props.changeStep(1);
  };

  handleChangeFlag = (event) => {
    this.props.changeLang(event.target.value);

    document.location.reload();
  };

  handleChangeCurrency = (event) => {
    this.props.changeCurrency(event.target.value);
    document.location.reload();
  };

  render() {
    return (
      <div className={styles.sidebarOverlay}>
        <div className={styles.sidebar}>
          <ButtonBase className={styles.sidebarItem} href="/">
            <span className={styles.font900}>SMART</span>
            <span className={styles.font300}>PRO</span>
            <span className={styles.font900}>
              TRADING<span>&#174;</span>
            </span>
          </ButtonBase>
          <div className={styles.sidebarItem}>
            <ButtonBase
              href="/store"
              onClick={this.handleChangeStep}
              classes={{
                root: classnames(
                  styles.btnHead,
                  document.location.pathname === "/store" && styles.active
                ),
              }}
            >
              {_t.translate("Store")}
            </ButtonBase>
          </div>
          <div className={styles.sidebarItem}>
            <ButtonBase
              href="/user-guide/"
              classes={{
                root: classnames(
                  styles.btnHead,
                  document.location.pathname === "/user-guide/" && styles.active
                ),
              }}
            >
              {_t.translate("User Guide")}
            </ButtonBase>
          </div>
          <div className={styles.sidebarItem}>
            <ButtonBase
              href="/download"
              classes={{ root: styles.btn }}
            ></ButtonBase>
            <p className={styles.small}>{_t.translate("15 days free!")}</p>
          </div>
          <div className={styles.sidebarItem}>
            <div className={styles.seperator}></div>
          </div>
          <div className={styles.sidebarItem}>
            <select
              value={this.props.lang}
              onChange={this.handleChangeFlag}
              className={styles.selects}
            >
              <option value="pt">Português</option>
              <option value="es">Español</option>
              <option value="en">English</option>
            </select>
          </div>
          <div className={styles.sidebarItem}>
            <select
              value={this.props.currency}
              onChange={this.handleChangeCurrency}
              className={styles.selects}
            >
              <option value="EUR">EUR</option>
              <option value="BRL">BRL</option>
              <option value="USD">USD</option>
            </select>
          </div>
        </div>
        <ButtonBase className={styles.close} onClick={this.props.handleClose}>
          &#10005;
        </ButtonBase>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.options.lang,
    currency: state.options.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeLang: (langCode) => {
      dispatch(changeLang(langCode));
    },
    getGeoIpCountry: () => {
      dispatch(getGeoIpCountry());
    },
    changeCurrency: (currencyCode) => {
      dispatch(changeCurrency(currencyCode));
    },
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
