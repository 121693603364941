import {
  GET_PRODUCTS,
  CHECK_VENDOR_CLIENT_ID_SUCCESS,
  CHECK_VENDOR_CLIENT_ID_ERROR,
  CHANGE_TEMP_VENDOR_CLIENT_ID,
  CHANGE_PRODUCT_ID,
  CHANGE_VAT_RATE,
  CHANGE_COUNTRY_ISO2,
  GET_PAYMENT_METHODS,
  CHANGE_STEP,
  SAVE_PAYMENT_METHOD,
  SAVE_ORDER_DETAILS,
  POST_ORDER_SUCCESS,
  CHANGE_CLIENT_NAME,
  CHANGE_CLIENT_VAT_NUMBER,
  CHANGE_TEMP_CLIENT_VAT_NUMBER,
  CHANGE_CLIENT_CITY,
  CHANGE_CLIENT_ADDRESS,
  CHANGE_CLIENT_ZIP_CODE,
  CHANGE_TEMP_CLIENT_ZIP_CODE,
  CLEAN_INVOICE_DETAILS,
  GET_PAYMENT_DETAIL,
  IS_LOADING_GET_PAYMENT_DETAIL,
  IS_LOADING_ORDER,
  CHANGE_IS_LOADING_ORDER
} from "../actions/action-store";
import { objectKeysToLowerCase } from "./../../utils/apiResponseUtils";
import { defaultOrderPaymentResult,defaultOrderResponse } from "../../model/myTypesDefault";

import { INITIAL_STATE } from "./initial-state";
const initialState = { ...INITIAL_STATE.store };

export default function stateStore(state, action) {
  // console.log('action.type', action.type)
  if (state === undefined) {
    return { ...initialState };
  }
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: objectKeysToLowerCase(action.payload),
      };

    case CHECK_VENDOR_CLIENT_ID_SUCCESS:
      return {
        ...state,
        // tempVendorClientId: "",
        order: {
          ...state.order,
          vendorClientId: action.payload,
        },
      };

    case CHECK_VENDOR_CLIENT_ID_ERROR:
      return {
        ...state,
        tempVendorClientId: -1,
        order: {
          ...state.order,
          vendorClientId: "",
        },
      };

    case CHANGE_TEMP_VENDOR_CLIENT_ID:
      return {
        ...state,
        tempVendorClientId: action.payload,
        order: {
          ...state.order,
          vendorClientId: "",
        },
      };

    // case RESET_VENDOR_CLIENT_ID:
    //   return{
    //     ...state,
    //     order:{
    //       ...state.order,
    //       vendorClientId:
    //     }
    //   }

    case CHANGE_COUNTRY_ISO2:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            countryIso2: action.payload,
          },
        },
      };

    case CHANGE_VAT_RATE:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            vatRate: action.payload,
          },
        },
      };

    case CHANGE_CLIENT_NAME:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            name: action.payload,
          },
        },
      };

    case CHANGE_TEMP_CLIENT_VAT_NUMBER:
      return {
        ...state,
        tempClientVatNumber: action.payload,
      };

    case CHANGE_CLIENT_VAT_NUMBER:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            vatNumber: action.payload,
          },
        },
      };

    case CHANGE_CLIENT_CITY:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            city: action.payload,
          },
        },
      };

    case CHANGE_CLIENT_ADDRESS:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            address: action.payload,
          },
        },
      };

    case CHANGE_TEMP_CLIENT_ZIP_CODE:
      return {
        ...state,
        tempClientZipCode: action.payload,
      };

    case CHANGE_CLIENT_ZIP_CODE:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            zipCode: action.payload,
          },
        },
      };

    case CHANGE_PRODUCT_ID:
      return {
        ...state,
        order: {
          ...state.order,
          productId: action.payload,
        },
        currentStep: 2, //change current step on store to 2
      };

    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: objectKeysToLowerCase(action.payload),
      };

    case CHANGE_STEP:
      return {
        ...state,
        currentStep: action.payload * 1,
      };

    case SAVE_PAYMENT_METHOD:
      return {
        ...state,
        order: {
          ...state.order,
          paymentTypeId: action.payload.paymentMethodId,
        },
      };

    case SAVE_ORDER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          vendorClientId: action.payload.vendorClientId,
          invoiceDetails: action.payload.invoiceDetails,
        },
      };

    case POST_ORDER_SUCCESS:
      return {
        ...state,
        orderResponse: action.payload,
        isLoading: false,
      };

    case CLEAN_INVOICE_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          invoiceDetails: {
            ...state.order.invoiceDetails,
            /*             adress: "", */
            /*             city: "", */
            countryIso2: "",
            /*             name: "", */
            vatNumber: "",
            zipCode: "",
          },
        },
        tempClientVatNumber: "",
        tempClientZipCode: "",
      };

    case IS_LOADING_GET_PAYMENT_DETAIL:
      return {
        ...state,
        orderPaymentResult: {
          ...state.orderPaymentResult,
          isLoading: true,
        },
      };

    case GET_PAYMENT_DETAIL:
      const invalidGetPaymentDetail = action.payload === '' || typeof action.payload.Id === 'undefined';
      let newOrderPaymentResult = { ...defaultOrderPaymentResult };
      if (invalidGetPaymentDetail === false) {
        newOrderPaymentResult = {
          isLoading: false,
          errorCode: action.payload.ErrorCode,
          errorDescription: action.payload.ErrorDescription,
          hasError: action.payload.HasError,
          id: action.payload.Id,
          statusId: action.payload.StatusId,
          productId: action.payload.SubscriptionTypeId,
          paymentMethodId: action.payload.TypeId,
        }
      } else {
        newOrderPaymentResult.isLoading = false;
        newOrderPaymentResult.hasError = true;
      }
      return {
        ...state,
        orderPaymentResult: { ...newOrderPaymentResult },
        order: {
          ...state.order,
          productId: invalidGetPaymentDetail === false
            ? newOrderPaymentResult.productId
            : state.order.productId, // needed in component SummaryStore
        }
      };
      case IS_LOADING_ORDER:
        return{
          ...state,
          isLoading: true,
        }
        case CHANGE_IS_LOADING_ORDER:
          return{
            ...state,
            isLoading: false,
            orderResponse:{...defaultOrderResponse}
            

          }
    default:
      return {
        ...state,
      };
  }
}
