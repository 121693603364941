import { WAGERTOOL_LINK, BETFAIR_LINK } from "src/model/myEnv";

export const renderWagertoolText = (mode) => {
  let className = 'isLink';
  if (mode === 'blend') {
    className = ''; // do not add class to highlight link style
  }
  return (
    '<a class="' + className + '" href="' + WAGERTOOL_LINK + '">' +
    'Wagertool®' +
    '</a>'
  )
};

export const renderBetfairText = (mode) => {
  let className = 'isLink';
  if (mode === 'blend') {
    className = ''; // do not add class to highlight link style
  }
  return (
    '<a class="' + className + '" href="' + BETFAIR_LINK + '" target="_blank" rel="noopener noreferrer">' +
    'Betfair' +
    '</a>'
  )
};
