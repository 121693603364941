import en from "./en";
import pt from "./pt";

export const DEFAULT_LANG = "en";

export default class TranslationsClass {
  constructor(defaultLang = DEFAULT_LANG) {
    this.dictionary = {
      en,
      pt,
    };
    this.lang = defaultLang;
  }

  /**
   *
   * @param {String} keyToTranslate
   * @param {Object} args OPTIONAL are the extra values to be replaced, in the format { key1: value1, key2: value2, ... }
   */
  translate(keyToTranslate, args) {
    let translatedValue = this.dictionary[this.lang].messages[keyToTranslate];
    if (typeof translatedValue === "undefined") {
      translatedValue = keyToTranslate;
      // if (this.lang !== DEFAULT_LANG) {
      //   console.info("New key to translate (in lang=", this.lang, "): ", keyToTranslate);
      // }
    }
    if (typeof args !== "undefined") {
      translatedValue = this._replaceArgs(translatedValue, args);
    }
    return translatedValue;
  }

  _replaceArgs(target, args) {
    Object.keys(args).forEach((key) => {
      target = target.replace(new RegExp("{{" + key + "}}", "g"), args[key]);
    });
    return target;
  }
}
