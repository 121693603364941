import React, { Component } from "react";
import styles from "src/views/home-page.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { _t } from "src/redux/store";
import "swiper/swiper.scss";
import "./swiperOverview-pagination.css";

SwiperCore.use([Pagination, Autoplay]);

const overviewTitle = [
  _t.translate("Training mode"),
  _t.translate("Market Views"),
  _t.translate("Customizable"),
];

class SwiperOverview extends Component {
  renderPaginationBullet = (index, className) => {
    return (
      '<span class="' +
      className +
      '">' +
      overviewTitle[index].toUpperCase() +
      "</span>"
    );
  };
  render() {
    return (
      <div className={styles.swiperContainer}>
        <Swiper
          loop={true}
          autoplay={{
            delay: 4000,
          }}
          centeredSlides
          slidesPerView={1}
          spaceBetween={100}
          pagination={{
            clickable: true,
            renderBullet: this.renderPaginationBullet,
          }}
        >
          <SwiperSlide key={1}>
            <div className={styles.slide2}>
              <div className={styles.text}>
                <p className={styles.textOverview}>{overviewTitle[0]}</p>
                <p className={styles.font300}>
                  {_t.translate(
                    "A complete experience, without having to worry about your bankroll."
                  )}
                </p>
              </div>
              <img
                className={styles.w100}
                src="img/1_modo_treino.jpg"
                width="1000"
                alt={_t.translate("Training Mode")}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className={styles.slide2}>
              <div className={styles.text}>
                <p className={styles.textOverview}>{overviewTitle[1]}</p>
                <p className={styles.font300}>
                  {_t.translate(
                    "Grid view, full Ladder view, Multibet view."
                  )}
                </p>
              </div>
              <img
                className={styles.w100}
                src="img/2_vistas_mercado.jpg"
                width="1000"
                alt={_t.translate("Market Views")}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div className={styles.slide2}>
              <div className={styles.text}>
                <p className={styles.textOverview}>{overviewTitle[2]}</p>
                <p className={styles.font300}>
                  {_t.translate(
                    "Choose your market view, edit your columns, choose your stake list, define your sound alerts, and more."
                  )}
                </p>
              </div>
              <img
                className={styles.w100}
                src="img/3_costumizavel.jpg"
                width="1000"
                alt={_t.translate("Customizable")}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}
export default SwiperOverview;
