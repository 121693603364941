export const defaultPaymentInfo = {
  subscriptionTypeId: "",
  currency: "",
  vendorClientId: "",
  invoiceDetails: {
    isCustomDetails: false,
    countryIso2: "",
    name: "",
    address: "",
    zipCode: "",
    city: "",
    vatNumber: "",
  },
  paymentTypeId: "",
};

export const defaultPaymentMethods = {
  currenciesSupported: [],
  name: "",
  paymentMethodId: "-1",
  supportsRecurringPayments: false,
};

export const defaultOrderPaymentResult = {
  isLoading: true,
  errorCode: null, // error returned by the payment method
  errorDescription: null, // error description returned by the payment method
  hasError: false,
  id: null, // order id
  statusId: null, // order StatusId: 1 (sucesso), 2 (pendente), 3 (erro), 4 (cancelado)
  productId: null, // this is productId (stored in state.store.products[])
  paymentMethodId: null, // this is paymentMethodId (stored in state.store.paymentMethods[])
};

export const defaultOrderResponse= {
  hasError: false,
  errorMessage: "",
  paymentMethodId: 1,
  name: "",
  Url: "",
}