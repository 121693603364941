import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./terms-conditions-page.module.css";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import { DEFAULT_LANG } from "src/translations/translations-class";
import { renderEmailText } from "src/components/SmartProTrading/renderEmail";
import renderHTML from "html-react-parser";

class TermsConditionsPage extends Component {
  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>SmartProTrading Terms and Conditions</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <h1 className={styles.font300}>SmartProTrading Terms and Conditions</h1>
        {this.props.lang !== DEFAULT_LANG && (
          <p className={classnames(styles.fontRed, styles.spaceBottom)}>
            We apologize, but only ENGLISH version is available for the Terms and Conditions.
          </p>
        )}
        <div className={styles.textContainer}>
          <p>1. Agreeing to the Terms and Conditions</p>
          <p>1.1. By previously registering an account with Betfair (www.betfair.com), becoming an active account holder and accepting the SmartProTrading Terms and Conditions, you enter into a legally binding agreement, which includes the general conditions and the terms and conditions of use which regulate the use of SmartProTrading and its services. You agree to the use of electronic communications in order to access to the Terms and Conditions and you waive any rights or requirements under applicable law or regulations in any jurisdiction which requires an original (non-electronic) signature.</p>
          <p>1.2. Products and services provided by SmartProTrading (via its website or applications). Services include computer software, the data supplied with them, online and electronic documents, and may also include associated media and printed materials. Such services are identified as “SmartProTrading”, “SmartProTrading software” or “SmartProTrading application”. This contract and its Terms and Conditions include all of these services.</p>
          <p className={styles.spaceTop}>2. Parties</p>
          <p>2.1. These Terms and Conditions are a binding legal between you and Keepitsoft – Programação Lda, a company registered in Portugal, with offices in Travessa dos Mareantes, n.º 3 – escritório G, 2520-537 Peniche, which is the sole proprietor of “SmartProTrading”.</p>
          <p>2.2. References in Terms and Conditions to "us", "our", "we" or "Licensor" are references to Keepitsoft – Programação Lda.</p>
          <p>2.3. References in Terms and Conditions to "you" and "your" are references to you, the end user of the Services.</p>
          <p className={styles.spaceTop}>3. Conditions of Use</p>
          <p>3.1. Access and use of SmartProTrading is subject to the terms and conditions of use (the "Terms") presented on the software setup wizard.</p>
          <p>3.2. The user must be a registered Betfair account holder to have access to the information and services available on SmartProTrading.</p>
          <p>3.3. The licensor concedes the user a personal license, not transferable to any third parties and not exclusive (and, when applicable, limited in time), to access and use SmartProTrading. The user is not allowed to:</p>
          <p>(i) multiply or, by any means, copy the application or any content /products/services to any third parties;</p>
          <p>(ii) rent, loan, sublicense, distribute or transfer to any third parties the application or content/products/services;</p>
          <p>(iii) decompile or reverse engineer the application or any content/products/services; and</p>
          <p>(iv) modify, expand or transform the application or any content/products/services, separate it into different parts, extract parts of it, merge it with other products, install them or use them in other products.</p>
          <p className={styles.spaceTop}>4. Content and Proprietary Rights</p>
          <p>4.1. SmartProTrading provides information services to all Betfair account holders. It is also not intended for publication in or used by any person, in any country or jurisdiction, where such Application or use would be contrary to local law or regulations. It is your exclusive responsibility to ensure that your access and use of SmartProTrading is lawful in your jurisdiction.</p>
          <p>4.2. Any personal information you supply to SmartProTrading when using its services will be used in accordance with our Privacy Policy included in these Terms and Conditions.</p>
          <p>4.3. The information at the SmartProTrading website is provided on an "as is" and "as available" basis. The licensor does not warrant that SmartProTrading is compatible with your computer or that it will run uninterrupted. Therefore, the licensor does not guarantee in any way:</p>
          <p>(i) that SmartProTrading will meet any needs or expectations;</p>
          <p>(ii) that SmartProTrading will run uninterrupted, safely, error-free or infinitely;</p>
          <p>(iii) that the results obtained through SmartProTrading will be accurate or true.</p>
          <p>4.4. All content on SmartProTrading website or SmartProTrading computer software and all material made available on the Services, including (but not limited to) text, video and audio content, images and photographs, software codes, graphics, illustrations, animations, artwork, names and logos, and the selection and arrangement thereof, is protected by trademarks, copyright, database rights and other intellectual property rights owned by Keepitsoft – Programação Lda and/or a third party, not being permitted its use or reproduction, at all time, without prior express consent of the licensor.</p>
          <p>4.5. Nothing contained in these Terms and Conditions should be interpreted as authorization to use any trade mark, patent, design right or copyright that belongs to the licensor or any third party.</p>
          <p>4.6. The licensor will make all necessary efforts to ensure the accuracy of the information available on SmartProTrading, but we cannot guarantee it over time. However, it is our commitment to, within our capabilities, keep all information available and updated. </p>
          <p>4.7. The user acknowledges that the licensor may change the content and technical specifications of SmartProTrading software at any time.</p>
          <p className={styles.spaceTop}>5. Commitment of the licensee</p>
          <p>Except when expressly established otherwise in Terms and Conditions, permitted by any local laws or previously agreed in writing with Keepitsoft – Programação Lda, the licensee shall undertake:</p>
          <p>a) not to rent, lease, sublicense, loan, translate, merge, adapt, vary or modify the SmartProTrading software;</p>
          <p>b) not to make alterations to, or modifications of, the whole or any part of the SmartProTrading software, nor permit the SmartProTrading software or any part of it to be combined with, or become incorporated in, any other programs;</p>
          <p>c) to replace the current version of the SmartProTrading Software with any updated or upgraded version or new release provided by SmartProTrading under these Terms and Conditions immediately on receipt;</p>
          <p>d) not to provide or otherwise make available the SmartProTrading Software in whole or in part (including but not limited to program listings, object and source program listings, object code and source code), in any form to any person without prior written consent from Keepitsoft – Programação Lda.</p>
          <p className={styles.spaceTop}>6. Accessibility</p>
          <p>User agrees that from time to time, SmartProTrading Software may be inaccessible or inoperable for any reason, including, without limitation: (i) equipment (hardware) malfunctions, (ii) software malfunctions, (iii) periodic routine maintenance procedures, nightly backups, upgrades or repairs which we may undertake from time to time, or (iv) causes beyond our reasonable control or not reasonably foreseeable by us. Keepitsoft – Programação Lda is not responsible, directly or indirectly, for the performance of any equipment or the user's Internet Service Provider. As acknowledged by the user, the SmartProTrading Software product shall not be accessed in source code form, in unlocked coding or with comments.</p>
          <p className={styles.spaceTop}>7. Transfer</p>
          <p>You shall not transfer, assign, charge or otherwise dispose of these Terms and Conditions, or any of your rights or obligations arising under it, without prior written consent. Keepitsoft – Programação Lda may transfer, assign, charge, sub-contract or otherwise dispose of these Terms and Conditions, or any rights or obligations arising under it, at any time during the term of the Agreement.</p>
          <p className={styles.spaceTop}>8. Changes to the Terms and Conditions</p>
          <p>8.1. Terms and Conditions govern your use of the Services provided by SmartProTrading and supersede any and all prior agreements between you and us in respect of the same.</p>
          <p>8.2. Terms and Conditions might be subject to alterations from time to time (including to comply with applicable law or a change in regulatory requirements). Such alterations and/or technical developments will be automatically updated, and its adoption will be necessary to proceed with the use of SmartProTrading application. If any change is unacceptable to you, you should stop using our Services. It is your sole responsibility to review the Terms and Conditions and any amendments to them each time you use the Services.</p>
          <p className={styles.spaceTop}>9. Limitation of Liability</p>
          <p>9.1. The licensor isn’t liable, under the Terms and Conditions, for any damage or loss suffered or incurred by you as a result of:</p>
          <p>a) any use of our Services in breach of these Terms and Conditions (including any use for commercial and business purposes);</p>
          <p>b) failures caused by the equipment you use to access our websites or applications, or failures in any network (including failures by your internet service provider);</p>
          <p>c) damage to your equipment (e.g. desktop, laptop, mobile, smartphone, tablet, games console or other internet-enabled device) or for any loss or corruption of data that results from your use of our websites or applications (and we cannot and do not guarantee that any files that you download are free from viruses, contamination or destructive features);</p>
          <p>d) any failure on our part to observe any self-exclusion policies that we may have in place from time to time;</p>
          <p>e) the accuracy, completeness or currency of any information services provided by us or third parties (including but not limited to prices, times, results or general statistics);</p>
          <p>f) any loss whatsoever arising from your abuse or misuse of our Services;</p>
          <p>9.2. The licensor does not have any responsibility or control over the content of external websites linked to SmartProTrading and accept no responsibility or liability for any losses or damage you suffer as a result of using such web sites. Please refer to the terms and conditions of each of those web sites.</p>
          <p>9.3. Being exclusively a software provider, the licensor is not responsible for any type of losses (including, but not limited to, loss of profits, business, revenue, opportunity, data or software) whether based on contract, tort (including negligence), or otherwise, resulting directly or indirectly in loss or damage.</p>
          <p>9.4. The software is provided "as is" at the moment of download, without warranty of any kind, express or implied, including, but not limited to, the warranties of merchantability, fitness for a particular purpose and noninfringement. In no event shall the licensor be liable for any claim, damages or other liability, whether in an action or contract, tort or otherwise, arising from the use of SmartProTrading software.</p>
          <p>9.5. SmartProTrading does not take responsibility by any change in the legal context that enforces the closure of its services locally or globally. This change in the legal context comprehends, but is not limited, to prohibition or requirement of specific local licenses.</p>
          <p>9.6. SmartProTrading’s service and obligations cease in the moment of its termination.</p>
          <p className={styles.spaceTop}>10. Software License</p>
          <p>10.1. Grant of License for the Use of SmartProTrading Software.</p>
          <p>In consideration of payment of the agreed license fee and abideness to the terms of these Terms and Conditions, the Licensor grants you a non-exclusive, non-transferable license, to make and use copies of SmartProTrading Software.</p>
          <p>10.2. Licensee Rights</p>
          <p>The Licensee may:</p>
          <p>a) download, install and use SmartProTrading Software for private purposes only;</p>
          <p>b) transfer SmartProTrading Software from one device to the other;</p>
          <p>c) receive and use any free supplementary software code or update of the SmartProTrading Software incorporating "patches" and corrections of errors as may be provided by the Licensor, at the Licensor absolute discretion, from time to time. Any supplemental software code provided to the Licensee as part of this agreement shall be considered part of the SmartProTrading Software and subject to the present Terms and Conditions.</p>
          <p>10.3. Lifetime License</p>
          <p>Purchasing a lifetime license to use the SmartProTrading Software, you agree that without limitation, certain features of the software may not be available or supported in perpetuity. The Licensee also agrees that the Licensor shall have the right to change features associated with the software / service at its sole discretion, and that it may choose to discontinue support of the software / service at any time. The Licensor shall not be entitled to a refund of the lifetime license fee, under any circumstances.</p>
          <p className={styles.spaceTop}>11. Payment</p>
          <p>11.1. Subscription Plans</p>
          <p>You acknowledge that you will be granted unlimited usage for the subscription requested period. Once expired, you must purchase a new subscription.</p>
          <p>11.2. Payment Dispute</p>
          <p>If, for any reason, the Licensee is not satisfied with the services provided or would like to raise a payment dispute, he may send an e-mail to {renderHTML(renderEmailText())}.</p>
          <p>11.3. Return Policy</p>
          <p>The Licensor will return the funds spent on subscriptions if in a period of over 72h (seventy-two hours), the software happens to have any sort of malfunction or error that may render it unusable for the intended purpose, for reasons directly attributable to the Licensor, according to the subscribed plan. The amount refunded shall be proportional to the period of software malfunction, if such period exceeds 72h (seventy-two hours).</p>
          <p className={styles.spaceTop}>12. Privacy Policy</p>
          <p>12.1. The Licensor is the sole owner of the information collected by the software, with the exception of the information held by Betfair.</p>
          <p>12.2. The software does not collect Betfair username information, Betfair account balance, betting amounts or betting history.</p>
          <p>12.3. This privacy policy applies to information collected by the software.</p>
          <p>12.4 The Licensor, with the consent of the Licensee, may transfer to third parties, duly identified, the information collected by the software.</p>
          <p className={styles.spaceTop}>13. Contact Information </p>
          <p>13.1. Please direct any questions regarding the Privacy Policy or the Terms and Conditions or any comments about our services to:</p>
          <p>Keepitsoft – Programação Lda<br />Travessa dos Mareantes, n.º 3 – Escritório G<br />2520-537 Peniche</p>
          <p>Email: {renderHTML(renderEmailText())}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.options.lang,
  };
};

export default connect(mapStateToProps, null)(TermsConditionsPage);
