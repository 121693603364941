export function objectKeysToLowerCase(responseData) {
  if (typeof responseData !== "object") return responseData;
  if (Array.isArray(responseData))
    return responseData.map(objectKeysToLowerCase);
  return Object.keys(responseData).reduce(function (newObj, key) {
    let val = responseData[key];
    let newVal = typeof val === "object" ? objectKeysToLowerCase(val) : val;
    newObj[camelize(key)] = newVal;
    return newObj;
  }, {});
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
