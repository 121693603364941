import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./summaryStore.module.css";
import { _t } from "src/redux/store";
import classnames from "classnames";
import { withRouter } from "react-router";
import { getVatRateByCountry } from "src/app-constant";

class SummaryStore extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency !== this.props.currency) {
      this.renderSummary();
    }
  }

  renderSummary() {
    let product = {};
    let newPrice = "";
    let oldPrice = "";
    let hasDiscount = false;
    this.props.products.some((eachProduct) => {
      if (eachProduct.productId === this.props.productIdStore) {
        product = eachProduct;
        return true;
      } else {
        return false;
      }
    });
    let price = {};
    if (product.hasOwnProperty("prices") === true) {
      product.prices.some((el) => {
        if (el.currency === this.props.currency) {
          price = el;
          return true;
        } else {
          return false;
        }
      });
    }

    if (price.hasOwnProperty("valueWithDiscount")) {
      newPrice = price.valueWithDiscount;
      oldPrice = price.valueFull;
      hasDiscount = true;
    }
    let showPrice = price.hasOwnProperty("valueWithDiscount")
      ? price.valueWithDiscount
      : price.valueFull;

    return (
      <div>
        <div className={styles.summaryBox}>
          <div className={styles.cell}>
            <p className={styles.rightCellTitle}>{_t.translate("Summary")}</p>
            <p>
              {_t.translate("Plan")}
              <span className={styles.alignRight}>
                <strong>{_t.translate(product.name)}</strong>
              </span>
            </p>
            <p className={styles.summary}>
              {_t.translate("Price")}
              <span className={styles.smallText}>
                {_t.translate(" (VAT included)")}
              </span>
              {hasDiscount ? (
                <span className={styles.alignRight}>
                  <span className={styles.oldPrice}>{oldPrice}</span>
                  <span className={styles.newPrice}>{newPrice}</span>
                  {this.props.currency}
                </span>
              ) : (
                <span className={styles.alignRight}>
                  {showPrice + " " + this.props.currency}
                </span>
              )}
            </p>
            <p className={styles.descrTitle}>
              {_t.translate("Total")}
              <span className={classnames(styles.alignRight, styles.totalSize)}>
                {showPrice +
                  (getVatRateByCountry(this.state.selectedCountry) *
                    showPrice) /
                    100 +
                  " " +
                  this.props.currency}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const isReady = this.props.products.length > 0;
    return isReady ? <div>{this.renderSummary()}</div> : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.store.products,
    productIdStore: state.store.order.productId,
    currency: state.options.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryStore)
);
