import React, { Component } from "react";
import styles from "./store-tab.module.css";

import classnames from "classnames";
import { ButtonBase } from "@material-ui/core";
import { BUYSTEPS } from "src/views/store/store-tabData";
import { changeStep } from "src/redux/actions/action-store";
import { connect } from "react-redux";

class StoreTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeStep = (stepNumber) => {
    this.props.changeStep(stepNumber);
  };

  renderTabs() {
    return Object.keys(BUYSTEPS).map((stepKey) => {
      const item = BUYSTEPS[stepKey];

      return (
        <ButtonBase
          onClick={() => {
            this.handleChangeStep(item.id);
          }}
          classes={{
            root: classnames(styles.btnOS),
          }}
          disabled={this.props.currentStep < item.id}
          key={item.id}
        >
          <div className={styles.buttonTab}>
            <p
              className={classnames(
                this.props.currentStep > item.id && styles.hoverText,
                this.props.currentStep === item.id && styles.active,
                styles.text
              )}
            >
              {item.name}
            </p>
            <div
              className={classnames(
                this.props.currentStep > item.id && styles.hoverSpan,
                this.props.currentStep === item.id && styles.activeSpan,
                styles.borderBottom
              )}
            ></div>
          </div>
        </ButtonBase>
      );
    });
  }

  render() {
    return <div className={styles.cell}>{this.renderTabs()}</div>;
  }
}
const mapStateToProps = (state) => {
  return {
    productId: state.store.order.productId,
    currentStep: state.store.currentStep,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeStep: (stepNumber) => {
      dispatch(changeStep(stepNumber));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreTabs);
