import React, { Component } from "react";
import classnames from "classnames";
import styles from "./cancelar-page.module.css";
import { ButtonBase } from "@material-ui/core";
// import { PhotoSwipe } from "react-photoswipe";
import { connect } from "react-redux";
import "react-photoswipe/lib/photoswipe.css";
// import { Helmet } from "react-helmet";
import { _t } from "src/redux/store";
import renderHTML from 'html-react-parser';
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import InputVendorClientId from "./store/input-vendor-client-id";

 class CancelarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidVendorClientId: false,
      viewer: {
        visible: false,
        img: "",
        width: "",
        height: "",
        alt: "",
      },
    };
  }
  
  render() {
    const isVendorClientIdConfirmed = this.props.orderVendorClientId !== "";
    return (
      <div className={styles.main}>
        {/* <Helmet>
          <title>
            {_t.translate("SmartProTrading cancel subscription")}
          </title>
           <meta name="description" content="OLA MUNDO" />
        </Helmet> */}
        <div className={styles.column}>
          <div className={styles.rowTop}>
            <h1 className={styles.title}>Cancelar subscrição</h1>
            {/* <!-- falta aqui uma imagem --> */}
          </div>
          <div className={styles.rowTop}>
            <div className={styles.leftCell}>
              <p ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    " Se comprou uma licença {{logoname}} através da Neteller," +
                      "e quer cancelar a assinatura, insira o seu Betfair Vendor Client" +
                      "ID.",
                    {
                      logoname: '<span class="logoHere"></span>',
                    }
                  )
                )}
              </p>
              <p className={styles.smallTextGray}>
                {_t.translate(
                  "Esta ação não irá anular a sua licença atual, apenas vai cancelar a renovação automática."
                )}
              </p>
            </div>
            <div className={styles.rightCell}>
              <div className={styles.centerItems}>
                <InputVendorClientId
                  onCheckIsValid={this.handleChangeIsValidVendorClientId}
                />
              </div>
              <div className={styles.centerItems}>
                <ButtonBase
                  classes={{
                    root: classnames(
                      isVendorClientIdConfirmed === true &&
                        styles.BtnAct,
                      styles.BtnDeact
                    ),
                  }}
                >
                  {_t.translate("Cancelar Subscrição")}
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderVendorClientId: state.store.order.vendorClientId,
  };
};

function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelarPage);