import React, { Component } from "react";
// import classnames from 'classnames';
import styles from "src/views/home-page.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import { _t } from "src/redux/store";

SwiperCore.use([Autoplay]);



class SwiperTestimonials extends Component {
  render() {
    return (
      <div className={styles.swiperContainer}>
        <Swiper
          loop={true}
          autoplay={{
            delay: 2000,
          }}
          centeredSlides
          slidesPerView={1}
          spaceBetween={100}
        >
          <SwiperSlide key={1}>
            <div className={styles.slide}>
            <p className={styles.font300}>
                {_t.translate(
                  "“As a Mac user, I finally found a Betfair trading software that I can use without Windows virtual machine...  Good job team!” Jonathan Souza"
                )}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className={styles.slide}>
            <p className={styles.font300}>
                {_t.translate(
                  "“It is my favorite software, without a doubt. I can customize it my way and the layout is, of all trading software, the most intuitive.” Diogo Albuquerque"
                )}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}
export default SwiperTestimonials;
