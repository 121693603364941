import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./loja-page.module.css";
import stylesStoreTabs from "src/views/store/store-tab.module.css";
import stylesConfirmation from "src/views/store/confirmation-page.module.css";
import classnames from "classnames";
import { Helmet } from "react-helmet";
import { _t } from "src/redux/store";
import renderHTML from 'html-react-parser';
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import qs from "qs";
import { getPaymentDetail } from "src/redux/actions/action-store";
import SummaryStore from "src/components/SummaryStore/summaryStore";
import { renderEmailText } from "src/components/SmartProTrading/renderEmail";

class LojaReturnPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.urlParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.paymentTypeName = this.urlParams.paymentType; // possible values: "paypal", "pay4fun"
    this.paymentInfo = this.urlParams.info; // possible values: "success", "cancel"
    this.paymentToken = this.urlParams.token; // is available in "paypal" and in "pay4fun"
    this.isSuccess = this.paymentInfo === 'success';
    this.props.getPaymentDetail(this.paymentTypeName, this.paymentToken); // step 1 getPaymentDetail about the current return url
  }

  renderContentLoading() {
    return (
      <div className={stylesConfirmation.centerItems}>
        <div className={'loader-circle'}></div>
      </div>
    )
  }

  renderContent() {
    let htmlContent = '';
    // order StatusId: 1 (sucesso), 2 (pendente), 3 (erro), 4 (cancelado)
    if (this.props.orderPaymentResult.hasError === false && this.props.orderPaymentResult.statusId === 1) {
      // case 1: order payed successfully
      let lengthDays = '';
      this.props.products.some(product => {
        if (Number(product.productId) === Number(this.props.orderPaymentResult.productId)) {
          lengthDays = product.lengthDays;
          return true;
        } else {
          return false;
        }
      })
      htmlContent = (
        <React.Fragment>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "Your license has been automatically credited, <strong>you have {{numberDays}} more days of license</strong>. The purchase invoice was sent to your software registration email.",
              { numberDays: lengthDays }
            ))}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {_t.translate(
              "If you still had remaining license days, this new license only added days to your end date, you didn't lose a single day."
            )}
          </p>
          <p className={stylesConfirmation.spaceBottom} ref={(el) => setInnerLogo(el)}>
            {renderHTML(
              _t.translate(
                "You can now continue using {{logoname}}, professional trading software for Betfair.",
                { logoname: '<span class="logoHere"></span>' }
              )
            )}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "If you have any questions regarding the use of the software, consult our <a class='isLink' href='/user-guide'>User Guide</a> or contact us via email {{email}}",
              { email: renderEmailText() }
            ))}
          </p>
        </React.Fragment>
      );
    } else if (this.props.orderPaymentResult.hasError === false && this.props.orderPaymentResult.statusId === 4) {
      // case 2: order canceled
      htmlContent = (
        <React.Fragment>
          <p className={stylesConfirmation.spaceBottom}>
            {_t.translate(
              "The payment has been canceled."
            )}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "Please click on <a class='isLink' href='/store'>Store</a> again to repeat the purchase process."
            ))}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "If you have any questions or problems, contact us via email {{email}}",
              { email: renderEmailText() }
            ))}
          </p>
        </React.Fragment>
      );
    } else if (this.props.orderPaymentResult.hasError === false && this.props.orderPaymentResult.statusId === 2) {
      // case 3: order pending
      htmlContent = (
        <React.Fragment>
          <p className={stylesConfirmation.spaceBottom}>
            {_t.translate("Payment is being processed, it may take a few minutes.")}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {_t.translate("As soon as the license is credited, you will receive the purchase invoice in your software registration email.")}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "If you have any questions regarding the use of the software, consult our <a class='isLink' href='/user-guide'>User Guide</a> or contact us via email {{email}}",
              { email: renderEmailText() }
            ))}
          </p>
        </React.Fragment>
      );
    } else {
      // case 4: order with errors
      const hasErrorCode = typeof this.props.orderPaymentResult.errorCode === 'string' && this.props.orderPaymentResult.errorCode.length > 0;
      const hasErrorDescription = typeof this.props.orderPaymentResult.errorDescription === 'string' && this.props.orderPaymentResult.errorDescription.length > 0;
      htmlContent = (
        <React.Fragment>
          <p className={classnames(stylesConfirmation.spaceBottom, stylesConfirmation.error)}>
            {_t.translate("An error occurred, payment not completed.")}
          </p>
          {hasErrorCode === true && (
            <p className={stylesConfirmation.spaceBottom}>
              <strong>{_t.translate("Error code:")}</strong><br />
              <span className={stylesConfirmation.error}>{this.props.orderPaymentResult.errorCode}</span>
            </p>
          )}
          {hasErrorDescription === true && (
            <p className={stylesConfirmation.spaceBottom}>
              <strong>{_t.translate("Error description:")}</strong><br />
              <span className={stylesConfirmation.error}>{this.props.orderPaymentResult.errorDescription}</span>
            </p>
          )}
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "Please click on <a class='isLink' href='/store'>Store</a> again to repeat the purchase process."
            ))}
          </p>
          <p className={stylesConfirmation.spaceBottom}>
            {renderHTML(_t.translate(
              "If you have any questions or problems, contact us via email {{email}}",
              { email: renderEmailText() }
            ))}
          </p>
        </React.Fragment>
      );
    }

    return (
      <div className={stylesConfirmation.row}>
        <div className={stylesConfirmation.leftCell}>
          {htmlContent}
        </div>
        <div className={stylesConfirmation.rightCell}>
          <SummaryStore />
        </div>
      </div>
    );
  }

  renderTitle() {
    return this.props.isLoading === false ? (
      <div className={styles.textCenter}>
        <p className={styles.titleCenter} ref={(el) => setInnerLogo(el)}>
          {this.isSuccess === true ? renderHTML(
            _t.translate("Thank you for buying a {{logoname}} license!", {
              logoname: '<span class="logoHere"></span>',
            })
          ) :
            _t.translate("Something went wrong... try again?")
          }
        </p>
      </div>
    ) : (
      <div className={styles.textCenter}>
        <p className={styles.titleCenter}>{_t.translate("Loading...")}</p>
      </div>
    )
  }

  renderTop() {
    return this.props.isLoading === false ? (
      <div className={stylesStoreTabs.buttonTab}>
        <p
          className={classnames(
            this.isSuccess === true && stylesStoreTabs.hoverText,
            stylesStoreTabs.text
          )}
        >
          {this.isSuccess === true ? _t.translate("Order completed") : _t.translate("Order canceled...")}
        </p>
        <div
          className={classnames(
            this.isSuccess === true && stylesStoreTabs.hoverSpan,
            stylesStoreTabs.borderBottom
          )}
        ></div>
      </div>
    ) : (
      <div className={stylesStoreTabs.buttonTab}>
        <p className={stylesStoreTabs.text}>&nbsp;</p>
        <div
          className={classnames(
            stylesStoreTabs.borderBottom, stylesStoreTabs.noColor, 'loading-gradient'
          )}
        ></div>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>{_t.translate("Thank you for buying a SmartProTrading license!")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        {this.renderTitle()}
        {this.renderTop()}
        <div className={styles.container}>
          {this.props.isLoading === false
            ? this.renderContent()
            : this.renderContentLoading()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.store.orderPaymentResult.isLoading,
    orderPaymentResult: state.store.orderPaymentResult,
    products: state.store.products,
    currency: state.options.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getPaymentDetail: (paymentTypeName, paymentToken) => {
      dispatch(getPaymentDetail(paymentTypeName, paymentToken));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LojaReturnPage);
