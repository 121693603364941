import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import OptionsReducer from "./reducers/reducer-options";
import StoreReducer from "./reducers/reducer-store";

import merge from "lodash/merge";
import throttle from "lodash/throttle";
import { INITIAL_STATE } from "./reducers/initial-state";
import { loadState, saveState, clearStorage } from "./storage/session-storage";
import TranslationsClass from "src/translations/translations-class";

let persistedState = "";
let shouldClearCache = true;

// check if shouldClearCache
let lastActivityTimestamp = localStorage.getItem("lastActivityTimestamp") * 1;
if (lastActivityTimestamp > 0) {
  const nowInUserTimezone = new Date();
  const nowMilliseconds = nowInUserTimezone.getTime();
  shouldClearCache =
    nowMilliseconds - lastActivityTimestamp >
    process.env.REACT_APP_MAX_INACTIVITY_FORCE_CLEARCACHE_MINS * 60 * 1000;
}

if (shouldClearCache === false) {
  // Case 1: use localstorage information merged with initial state
  // using lodash.merge function to get persisted state from localstorage, and fill the rest of the values with the initial state
  // this is to prevent old users from crashing the site (with new features that needed extra variables in the app state)
  persistedState = merge({ ...INITIAL_STATE }, { ...loadState() });
} else {
  // Case 2: do not use localstorage
  clearStorage(); // ensure localstorage gets cleared
  persistedState = { ...INITIAL_STATE };
}

const reducers = combineReducers({
  options: OptionsReducer,
  store: StoreReducer,
});

export const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(thunk)
);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

let loadUserLang;
try {
  loadUserLang = persistedState.options.lang;
} catch (error) {
  loadUserLang = "en";
}
export const _t = new TranslationsClass(loadUserLang);
