import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import styles from "./footer.module.css";
import { changeStep } from "src/redux/actions/action-store";
import { _t } from "src/redux/store";
import renderHTML from "html-react-parser";
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { Link } from "react-router-dom";
import { scrollTop } from "src/utils/link-utils";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLinks(uri) {
    this.props.history.push(uri);
  }
  handleChangeStep = () => {
    this.props.changeStep(1);
    scrollTop();
  };

  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <p ref={(el) => setInnerLogo(el)}>
            {renderHTML(
              _t.translate(
                "Copyright © 2020 {{logoname}}. All rights reserved.",

                {
                  logoname: '<span class="logoHere"></span>',
                }
              )
            )}
          </p>
          <p><a href="/terms-and-conditions">{_t.translate("Terms & Conditions")}</a></p>
          <div className={styles.paymentLogos}>
            <img
              className={styles.logo}
              src="img/paymentmethod-paypal-logo.png"
              height="40px"
              alt="Paypal"
            />
            {(this.props.countryGeoIp === "br" ||
              this.props.currency === "BRL") && (
                <img
                  className={styles.logo}
                  src="img/paymentmethod-payforfun-logo.png"
                  height="40px"
                  alt="Pay4fun"
                />
              )}
          </div>
        </div>
        <div className={styles.footerRight}>
          {/* <div className={styles.columnRight}>
            <p className={styles.font700}>{_t.translate("About")}</p>
            <div className={styles.columnItems}>
              <ButtonBase component={Link} to={"/affiliate"} className={styles.bottomSpace}>
                {_t.translate("Affiliate")}
              </ButtonBase>
              <ButtonBase classes={{ root: styles.bottomSpace }}>
                {_t.translate("GambleAware")}
              </ButtonBase>
            </div>
          </div> */}

          <div className={styles.columnRight}>
            <p className={styles.font700}>{_t.translate("Store")}</p>
            <div className={styles.columnItems}>
              <ButtonBase
                className={styles.bottomSpace}
                component={Link}
                to={"/download"}
                onClick={(() => scrollTop())}
              >
                {_t.translate("Free Trial")}
              </ButtonBase>
              <ButtonBase
                onClick={this.handleChangeStep}
                component={Link}
                to={"/store"}
                className={styles.bottomSpace}
              >
                {_t.translate("Pricing")}
              </ButtonBase>
              <ButtonBase
                component={Link}
                to={"/download"}
                classes={{ root: styles.bottomSpace }}
                onClick={(() => scrollTop())}
              >
                Download
              </ButtonBase>
            </div>
          </div>
          <div className={styles.columnRight}>
            <p className={styles.font700}>{_t.translate("Support")}</p>
            <div className={styles.columnItems}>
              <ButtonBase
                component={Link}
                to={"/user-guide"}
                classes={{ root: styles.bottomSpace }}
                onClick={(() => scrollTop())}
              >
                {_t.translate("User Guide")}
              </ButtonBase>
              <ButtonBase
                component={Link}
                to={"/faq"}
                classes={{ root: styles.bottomSpace }}
                onClick={(() => scrollTop())}
              >
                {_t.translate("FAQ")}
              </ButtonBase>
              <ButtonBase
                component={Link}
                to={"/contact"}
                classes={{ root: styles.bottomSpace }}
                onClick={(() => scrollTop())}
              >
                {_t.translate("Contact")}
              </ButtonBase>

              {/* <ButtonBase
                classes={{ root: styles.bottomSpace }}
                href="https://www.facebook.com/"
              >
                Facebook
              </ButtonBase> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countryGeoIp: state.options.countryGeoIp,
    currency: state.options.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
