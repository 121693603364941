import { PureComponent } from 'react';
import throttle from 'lodash/throttle';

// Array of DOM events that should be interpreted as user activity.
const ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
// Update activity detection once every X seconds
const ACTIVITY_DETECTION_INTERVAL_SECS = process.env.REACT_APP_ACTIVITY_DETECTION_INTERVAL_SECS;

export default class UserActivityWatcher extends PureComponent {
  constructor(props) { 
    super(props);
    this.eventListeners = {};
    this.addActivityListeners();
  }

  componentWillUnmount() {
    this.removeActivityListeners();
    this.eventListeners = null;
  }

  addActivityListeners() {
    ACTIVITY_EVENTS.forEach(eventName => {
      this.eventListeners[eventName] = document.addEventListener(eventName, throttle(this.logActivity, ACTIVITY_DETECTION_INTERVAL_SECS * 1000), true);
    });
  }

  removeActivityListeners() {
    ACTIVITY_EVENTS.forEach(eventName => {
      document.removeEventListener(eventName, this.eventListeners[eventName]);
    });
  }

  logActivity = () => {
    const nowInUserTimezone = new Date();
    const nowMilliseconds = nowInUserTimezone.getTime();
    localStorage.setItem("lastActivityTimestamp", nowMilliseconds);
  }

  render() {
    return null;
  }
}
