const defaultCurrencyCode = 'GBP';
const defaultUserLocale = 'en';

/**
 * Returns de currency formated with user betfair account currency, and 2 decimal places.
 * @param {*} amount 
 * @param {*} currencyCode 
 * @param {*} userLocale 
 */
export const convertCurrency = (amount, currencyCode = defaultCurrencyCode, userLocale = defaultUserLocale) => {
  const options = {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  };
  let formatted = "";
  try {
    formatted = (new Intl.NumberFormat(userLocale, options).format(Number(amount)));
  } catch (error) {
    formatted = currencyCode + " " + Number(amount).toFixed(2);
  }
  return formatted;
}

export const convertCurrencyNoDecimals = (amount, currencyCode = defaultCurrencyCode, userLocale = defaultUserLocale) => {
  const amountNoDecimals = Math.round(Number(amount));
  const options = {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0
  };
  let formatted = "";
  try {
    formatted = (new Intl.NumberFormat(userLocale, options).format(amountNoDecimals));
  } catch (error) {
    formatted = currencyCode + " " + amountNoDecimals.toFixed(0);
  }
  return formatted;
}
