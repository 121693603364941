import React, { Component } from "react";
import { _t } from "src/redux/store";
import { connect } from "react-redux";
import { changeClientCity } from "src/redux/actions/action-store.js";
import FormControl from "@material-ui/core/FormControl";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./details-page.module.css";
import { TextField } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "./../../model/myEnv";

class InputClientCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCityEdited: false,
      tempValue: "",
    };
  }

  handleClientCityChange = (event) => {
    this.setState({ isCityEdited: true, tempValue: event.target.value });
    if (event.target.value.length >= REQ_INVOICE_INPUT_MIN_LENGTH) {
      this.props.changeClientCity(event.target.value);
    } else {
      this.props.changeClientCity("");
    }
  };

  render() {
    const isCityEmpty = this.state.tempValue === "";
    const cityHasError = isCityEmpty && this.state.isCityEdited;
    const hasRequiredLength =
      this.state.tempValue.length >= REQ_INVOICE_INPUT_MIN_LENGTH;

    return (
      <div className={styles.invoice}>
        <div className={styles.inputDetails}>
          <FormControl className={styles.formControl}>
            <TextField
              classes={{
                root: cityHasError ? styles.textFieldError : styles.textField,
              }}
              variant="outlined"
              required
              error={cityHasError}
              id="client-city"
              label={_t.translate("City")}
              helperText={
                cityHasError === true
                  ? _t.translate("City is required")
                  : !hasRequiredLength && this.state.isCityEdited
                  ? _t.translate(
                      "At least {{minimumLength}} characters required",
                      {
                        minimumLength: REQ_INVOICE_INPUT_MIN_LENGTH,
                      }
                    )
                  : ""
              }
              onChange={this.handleClientCityChange}
              defaultValue={this.props.invoiceDetailsCity}
            />
          </FormControl>
        </div>
        <div className={styles.checkIcon}>
          {this.props.invoiceDetailsCity !== "" ? (
            <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoiceDetailsCity: state.store.order.invoiceDetails.city,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientCity: (newClientCity) => {
      dispatch(changeClientCity(newClientCity));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputClientCity);
