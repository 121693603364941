import React, { Component } from "react";
import { _t } from "src/redux/store";
import { connect } from "react-redux";
import {
  changeClientZipCode,
  changeTempClientZipCode,
} from "src/redux/actions/action-store.js";
import FormControl from "@material-ui/core/FormControl";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./details-page.module.css";
import { TextField } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "./../../model/myEnv";

class InputClientZipCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isZipCodeEdited: false,
    };
  }

  handleClientZipCodeChange = (event) => {
    this.setState({ isZipCodeEdited: true });
    this.props.changeTempClientZipCode(event.target.value);
    if (event.target.value.length >= REQ_INVOICE_INPUT_MIN_LENGTH) {
      this.props.changeClientZipCode(event.target.value);
    } else {
      this.props.changeClientZipCode("");
    }
  };

  render() {
    const isZipCodeEmpty = this.props.store.tempClientZipCode === "";
    const zipCodeIsReq = isZipCodeEmpty && this.state.isZipCodeEdited;
    const hasRequiredLength =
      this.props.store.tempClientZipCode.length >= REQ_INVOICE_INPUT_MIN_LENGTH;
    return (
      <div className={styles.invoice}>
        <div className={styles.inputDetails}>
          <FormControl className={styles.formControl}>
            <TextField
              classes={{
                root: zipCodeIsReq ? styles.textFieldError : styles.textField,
              }}
              variant="outlined"
              required
              error={zipCodeIsReq}
              id="vendor-client-zip-code"
              label={_t.translate("Zip-Code")}
              helperText={
                zipCodeIsReq === true
                  ? _t.translate("Zip-Code is required")
                  : !hasRequiredLength && this.state.isZipCodeEdited
                  ? _t.translate(
                      "At least {{minimumLength}} characters required",
                      {
                        minimumLength: REQ_INVOICE_INPUT_MIN_LENGTH,
                      }
                    )
                  : ""
              }
              onChange={this.handleClientZipCodeChange}
              value={this.props.store.tempClientZipCode}
            />
          </FormControl>
        </div>
        <div className={styles.checkIcon}>
          {hasRequiredLength ? (
            <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    store: state.store,
    selectedCountryCode: state.store.order.invoiceDetails.countryIso2,
    countryGeoIp: state.options.countryGeoIp,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientZipCode: (newClientZipCode) => {
      dispatch(changeClientZipCode(newClientZipCode));
    },
    changeTempClientZipCode: (newTempClientZipCode) => {
      dispatch(changeTempClientZipCode(newTempClientZipCode));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputClientZipCode);
