import React, { Component } from "react";
import classnames from "classnames";
import styles from "./faq-page.module.css";
import "./faq-page.module.css";
import renderHTML from "html-react-parser";
import { _t } from "src/redux/store";
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { Helmet } from "react-helmet";
import { renderEmailText } from "src/components/SmartProTrading/renderEmail";
import { renderBetfairText } from "src/components/SmartProTrading/renderLinks";

const FAQ = [
  {
    id: 1,
    question: "",
    answer: _t.translate(
      "Have a question? Not sure how to purchase a {{logoname}} license?",
      {
        logoname: '<span class="logoHere"></span>',
      }
    ),
  },
  {
    id: 2,
    question: "",
    answer: _t.translate(
      "We have collected the answers to the most frequently asked questions."
    ),
  },
  {
    id: 3,
    question: "",
    answer: _t.translate(
      "If you still haven't found the answer to your question, you can always contact us via email {{email}}.",
      {
        email: renderEmailText()
      }
    ),
  },
];

const QUESTIONS = [
  {
    id: 1,
    question: _t.translate("Can I try the software?"),
    answer: _t.translate(
      "Yes, after downloading, you will have access to a" +
      " 15-day trial period to test {{logoname}}. That trial period will be associated" +
      " with your registration, so if you install {{logoname}} on a new platform," +
      " the free trial will not start over.",
      {
        logoname: '<span class="logoHere"></span>',
      }
    ),
  },
  {
    id: 2,
    question: _t.translate("How do I register?"),
    answer: _t.translate(
      "Just download {{logoname}} and on the first login, when registering, you will indicate" +
      " a valid and accessible email for you, as it will be a fundamental means" +
      " of contact to communicate with the support team. The software test time" +
      " count starts at that time. To log in to the software, simply enter your {{betfairLink}} credentials.",
      {
        logoname: '<span class="logoHere"></span>',
        betfairLink: renderBetfairText()
      }
    ),
  },
  {
    id: 3,
    question: _t.translate(
      "What plans are there? Can I cancel or change at any time?"
    ),
    answer: _t.translate(
      "To access {{logoname}}, you can choose 3 license plans:" +
      " Monthly, Annual or Lifetime. The Monthly license get you +31 days, the Annual is +365 days." +
      " The Lifetime license is yours forever. There is no need or possiblity to cancel a license, simply do not renew that again." +
      " Please make sure you use the free trial period to test the software running on your machine and network configuration, as there is no refunds possible." +
      " If you want to change your plan, just make a new purchase on the Prices page.",
      {
        logoname: '<span class="logoHere"></span>',
      }
    ),
  },
  {
    id: 4,
    question: _t.translate("Can I use the {{logoname}} website to trade?",
      {
        logoname: '<span class="logoHere"></span>',
      }),
    answer: _t.translate(
      "No, {{logoname}} is a desktop software that requires installation on your computer (Windows, " +
      "Linux and Mac). The complexity of this type of software requires stability that the browser does not guarantee.",
      {
        logoname: '<span class="logoHere"></span>',
      }
    ),
  },
  {
    id: 5,
    question: _t.translate("Where can I see invoices for my purchases?"),
    answer: _t.translate(
      "Invoices are automatically sent to the email you used" +
      " for your {{logoname}} registration, once payments are validated.",
      {
        logoname: '<span class="logoHere"></span>',
      }
    ),
  },
  {
    id: 6,
    question: _t.translate("In which countries can {{logoname}} be used?",
      {
        logoname: '<span class="logoHere"></span>',
      }),
    answer: _t.translate(
      "{{logoname}} is available in all countries where {{betfairLink}}" +
      " operates and accepts customers, except Spain, Italy and Denmark.",
      {
        logoname: '<span class="logoHere"></span>',
        betfairLink: renderBetfairText(),
      }
    ),
  },
];

export default class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderQuestion(questionItem) {
    return (
      <div key={questionItem.id} className={styles.spaceBottom}>
        <p className={styles.textTitle} ref={(elm) => setInnerLogo(elm)}>
          {renderHTML(questionItem.question)}
        </p>
        <p
          className={classnames("answer", styles.spaceBottom)}
          ref={(el) =>
            setInnerLogo(el, { spaceLeft: false, spaceRight: false })
          }
        >
          {renderHTML(questionItem.answer)}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>{_t.translate("SmartProTrading FAQ page")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <div className={styles.column}>
          <div className={styles.rowTop}>
            <h1 className={styles.title}>FAQ</h1>
          </div>
          <div className={styles.rowTop}>
            <div className={styles.leftCell}>
              <div className={styles.spaceBottom} ref={setInnerLogo}>
                {FAQ.map((each) => this.renderQuestion(each))}
              </div>
            </div>
            <div className={classnames(styles.rightCell, styles.content)}>
              {QUESTIONS.map((each) => this.renderQuestion(each))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
