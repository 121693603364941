import React, { Component } from "react";
import { _t } from "src/redux/store";
import { connect } from "react-redux";
import { changeClientName } from "src/redux/actions/action-store.js";
import FormControl from "@material-ui/core/FormControl";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./details-page.module.css";
import { TextField } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "./../../model/myEnv";

class InputClientName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameEdited: false,
      tempValue: "",
    };
  }

  handleClientNameChange = (event) => {
    this.setState({ isNameEdited: true, tempValue: event.target.value });
    if (event.target.value.length >= REQ_INVOICE_INPUT_MIN_LENGTH) {
      this.props.changeClientName(event.target.value);
    } else {
      this.props.changeClientName("");
    }
  };

  render() {
    const isNameEmpty = this.state.tempValue === "";
    const nameHasError = isNameEmpty && this.state.isNameEdited;
    const hasRequiredLength =
      this.state.tempValue.length >= REQ_INVOICE_INPUT_MIN_LENGTH;

    return (
      <div className={styles.invoice}>
        <div className={styles.inputDetails}>
          <FormControl className={styles.formControl}>
            <TextField
              classes={{
                root: nameHasError ? styles.textFieldError : styles.textField,
              }}
              variant="outlined"
              required
              error={nameHasError}
              id="client-name"
              label={_t.translate("Name")}
              helperText={
                nameHasError === true
                  ? _t.translate("Name is required")
                  : !hasRequiredLength && this.state.isNameEdited
                  ? _t.translate(
                      "At least {{minimumLength}} characters required",
                      {
                        minimumLength: REQ_INVOICE_INPUT_MIN_LENGTH,
                      }
                    )
                  : ""
              }
              onChange={this.handleClientNameChange}
              defaultValue={this.props.invoiceDetailsName}
            />
          </FormControl>
        </div>
        <div className={styles.checkIcon}>
          {this.props.invoiceDetailsName !== "" ? (
            <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoiceDetailsName: state.store.order.invoiceDetails.name,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientName: (newClientName) => {
      dispatch(changeClientName(newClientName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputClientName);
