import React, { Component } from "react";
import styles from "./afiliado-page.module.css";
import { _t } from "src/redux/store";
import renderHTML from "html-react-parser";
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { Helmet } from "react-helmet";
import { renderEmailText } from "src/components/SmartProTrading/renderEmail";

export default class AfiliadoPage extends Component {
  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>{_t.translate("SmartProTrading affiliate page ")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <h1 className={styles.font300Blue}>{_t.translate("Affiliate")}</h1>
        <p
          className={styles.spaceBottom}
          ref={(el) => setInnerLogo(el, { spaceLeft: true })}
        >
          {renderHTML(
            _t.translate("Help us spread the word about {{logoname}}!", {
              logoname: '<span class="logoHere"></span>',
            })
          )}
        </p>
        <p className={styles.spaceBottom}>
          {_t.translate(
            "Working in partnership will make us very happy, because" +
            " together we will develop our products with a potential never seen before."
          )}
        </p>
        <p className={styles.spaceBottom}>
          {_t.translate(
            "Do you have a website or channel on Youtube? It can" +
            " transform traffic from your cash networks if you join our affiliate" +
            " program. Start earning 20% ​​commission on all purchases (except VAT) made by customers associated with you."
          )}
        </p>
        <p
          className={styles.spaceBottom}
          ref={(el) => setInnerLogo(el, { spaceLeft: true })}
        >
          {renderHTML(
            _t.translate(
              "Send your application, indicating the name and the" +
              " link where you want to promote {{logoname}}, to the email {{email}}.",
              {
                logoname: '<span class="logoHere"></span>',
                email: renderEmailText(),
              }
            )
          )}
        </p>
        <p className={styles.spaceBottom}>
          {_t.translate(
            "We will review your request and promise to be brief in response."
          )}
        </p>
        <p className={styles.spaceBottom}>{_t.translate("See you soon.")}</p>
      </div>
    );
  }
}
