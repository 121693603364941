export const LANG_AND_CURRENCY_BY_COUNTRY = {
  AF: { 'name': 'Afganistan', 'lang': "en", 'currency': 'USD' },
  AX: { 'name': "Aland Islands", 'lang': "en", 'currency': 'USD' },
  AL: { 'name': "Albania", 'lang': "en", 'currency': 'USD' },
  DZ: { 'name': "Algeria", 'lang': "en", 'currency': 'USD' },
  AS: { 'name': "American Samoa", 'lang': "en", 'currency': 'USD' },
  AD: { 'name': "Andorra", 'lang': "en", 'currency': 'EUR' },
  AO: { 'name': "Angola", 'lang': "pt", 'currency': 'USD' },
  AI: { 'name': "Anguilla", 'lang': "en", 'currency': 'USD' },
  AQ: { 'name': "Antarctica", 'lang': "en", 'currency': 'USD' },
  AG: { 'name': "Antigua and Barbuda", 'lang': "en", 'currency': 'USD' },
  AR: { 'name': "Argentina", 'lang': "es", 'currency': 'USD' },
  AM: { 'name': "Armenia", 'lang': "en", 'currency': 'USD' },
  AW: { 'name': "Aruba", 'lang': "en", 'currency': 'USD' },
  AU: { 'name': "Australia", 'lang': "en", 'currency': 'USD' },
  AT: { 'name': "Austria", 'lang': "en", 'currency': 'EUR' },
  AZ: { 'name': "Azerbaijan", 'lang': "en", 'currency': 'USD' },
  BS: { 'name': "Bahamas", 'lang': "en", 'currency': 'USD' },
  BH: { 'name': "Bahrain", 'lang': "en", 'currency': 'USD' },
  BD: { 'name': "Bangladesh", 'lang': "en", 'currency': 'USD' },
  BB: { 'name': "Barbados", 'lang': "en", 'currency': 'USD' },
  BY: { 'name': "Belarus", 'lang': "en", 'currency': 'USD' },
  BE: { 'name': "Belgium", 'lang': "en", 'currency': 'EUR' },
  BZ: { 'name': "Belize", 'lang': "en", 'currency': 'USD' },
  BJ: { 'name': "Benin", 'lang': "en", 'currency': 'USD' },
  BM: { 'name': "Bermuda", 'lang': "en", 'currency': 'USD' },
  BT: { 'name': "Bhutan", 'lang': "en", 'currency': 'USD' },
  BO: { 'name': "Bolivia, Plurinational State of", 'lang': "es", 'currency': 'USD' },
  BQ: { 'name': "Bonaire, Sint Eustatius and Saba", 'lang': "en", 'currency': 'USD' },
  BA: { 'name': "Bosnia and Herzegovina", 'lang': "en", 'currency': 'USD' },
  BW: { 'name': "Botswana", 'lang': "en", 'currency': 'USD' },
  BV: { 'name': "Bouvet Island", 'lang': "en", 'currency': 'USD' },
  BR: { 'name': "Brazil", 'lang': "pt", 'currency': 'BRL' },
  IO: { 'name': "British Indian Ocean Territory", 'lang': "en", 'currency': 'USD' },
  BN: { 'name': "Brunei Darussalam", 'lang': "en", 'currency': 'USD' },
  BG: { 'name': "Bulgaria", 'lang': "en", 'currency': 'USD' },
  BF: { 'name': "Burkina Faso", 'lang': "en", 'currency': 'USD' },
  BI: { 'name': "Burundi", 'lang': "en", 'currency': 'USD' },
  KH: { 'name': "Cambodia", 'lang': "en", 'currency': 'USD' },
  CM: { 'name': "Cameroon", 'lang': "en", 'currency': 'USD' },
  CA: { 'name': "Canada", 'lang': "en", 'currency': 'USD' },
  CV: { 'name': "Cape Verde", 'lang': "pt", 'currency': 'USD' },
  KY: { 'name': "Cayman Islands", 'lang': "en", 'currency': 'USD' },
  CF: { 'name': "Central African Republic", 'lang': "en", 'currency': 'USD' },
  TD: { 'name': "Chad", 'lang': "en", 'currency': 'USD' },
  CL: { 'name': "Chile", 'lang': "es", 'currency': 'USD' },
  CN: { 'name': "China", 'lang': "en", 'currency': 'USD' },
  CX: { 'name': "Christmas Island", 'lang': "en", 'currency': 'USD' },
  CC: { 'name': "Cocos (Keeling) Islands", 'lang': "en", 'currency': 'USD' },
  CO: { 'name': "Colombia", 'lang': "es", 'currency': 'USD' },
  KM: { 'name': "Comoros", 'lang': "en", 'currency': 'USD' },
  CG: { 'name': "Congo", 'lang': "en", 'currency': 'USD' },
  CD: { 'name': "Congo, the Democratic Republic of the", 'lang': "en", 'currency': 'USD' },
  CK: { 'name': "Cook Islands", 'lang': "en", 'currency': 'USD' },
  CR: { 'name': "Costa Rica", 'lang': "es", 'currency': 'USD' },
  CI: { 'name': "C\u00f4te d'Ivoire", 'lang': "en", 'currency': 'USD' },
  HR: { 'name': "Croatia", 'lang': "en", 'currency': 'USD' },
  CU: { 'name': "Cuba", 'lang': "es", 'currency': 'USD' },
  CW: { 'name': "Cura\u00e7ao", 'lang': "en", 'currency': 'USD' },
  CY: { 'name': "Cyprus", 'lang': "en", 'currency': 'EUR' },
  CZ: { 'name': "Czech Republic", 'lang': "en", 'currency': 'USD' },
  DK: { 'name': "Denmark", 'lang': "en", 'currency': 'USD' },
  DJ: { 'name': "Djibouti", 'lang': "en", 'currency': 'USD' },
  DM: { 'name': "Dominica", 'lang': "en", 'currency': 'USD' },
  DO: { 'name': "Dominican Republic", 'lang': "es", 'currency': 'USD' },
  EC: { 'name': "Ecuador", 'lang': "es", 'currency': 'USD' },
  EG: { 'name': "Egypt", 'lang': "en", 'currency': 'USD' },
  SV: { 'name': "El Salvador", 'lang': "es", 'currency': 'USD' },
  GQ: { 'name': "Equatorial Guinea", 'lang': "es", 'currency': 'USD' },
  ER: { 'name': "Eritrea", 'lang': "en", 'currency': 'USD' },
  EE: { 'name': "Estonia", 'lang': "en", 'currency': 'EUR' },
  ET: { 'name': "Ethiopia", 'lang': "en", 'currency': 'USD' },
  FK: { 'name': "Falkland Islands (Malvinas)", 'lang': "en", 'currency': 'USD' },
  FO: { 'name': "Faroe Islands", 'lang': "en", 'currency': 'USD' },
  FJ: { 'name': "Fiji", 'lang': "en", 'currency': 'USD' },
  FI: { 'name': "Finland", 'lang': "en", 'currency': 'EUR' },
  FR: { 'name': "France", 'lang': "en", 'currency': 'EUR' },
  GF: { 'name': "French Guiana", 'lang': "en", 'currency': 'USD' },
  PF: { 'name': "French Polynesia", 'lang': "en", 'currency': 'USD' },
  TF: { 'name': "French Southern Territories", 'lang': "en", 'currency': 'USD' },
  GA: { 'name': "Gabon", 'lang': "en", 'currency': 'USD' },
  GM: { 'name': "Gambia", 'lang': "en", 'currency': 'USD' },
  GE: { 'name': "Georgia", 'lang': "en", 'currency': 'USD' },
  DE: { 'name': "Germany", 'lang': "en", 'currency': 'EUR' },
  GH: { 'name': "Ghana", 'lang': "en", 'currency': 'USD' },
  GI: { 'name': "Gibraltar", 'lang': "en", 'currency': 'USD' },
  GR: { 'name': "Greece", 'lang': "en", 'currency': 'EUR' },
  GL: { 'name': "Greenland", 'lang': "en", 'currency': 'USD' },
  GD: { 'name': "Grenada", 'lang': "en", 'currency': 'USD' },
  GP: { 'name': "Guadeloupe", 'lang': "en", 'currency': 'USD' },
  GU: { 'name': "Guam", 'lang': "en", 'currency': 'USD' },
  GT: { 'name': "Guatemala", 'lang': "es", 'currency': 'USD' },
  GG: { 'name': "Guernsey", 'lang': "en", 'currency': 'USD' },
  GN: { 'name': "Guinea", 'lang': "en", 'currency': 'USD' },
  GW: { 'name': "Guinea-Bissau", 'lang': "pt", 'currency': 'USD' },
  GY: { 'name': "Guyana", 'lang': "en", 'currency': 'USD' },
  HT: { 'name': "Haiti", 'lang': "en", 'currency': 'USD' },
  HM: { 'name': "Heard Island and McDonald Islands", 'lang': "en", 'currency': 'USD' },
  VA: { 'name': "Holy See (Vatican City State)", 'lang': "en", 'currency': 'EUR' },
  HN: { 'name': "Honduras", 'lang': "es", 'currency': 'USD' },
  HK: { 'name': "Hong Kong", 'lang': "en", 'currency': 'USD' },
  HU: { 'name': "Hungary", 'lang': "en", 'currency': 'USD' },
  IS: { 'name': "Iceland", 'lang': "en", 'currency': 'USD' },
  IN: { 'name': "India", 'lang': "en", 'currency': 'USD' },
  ID: { 'name': "Indonesia", 'lang': "en", 'currency': 'USD' },
  IR: { 'name': "Iran, Islamic Republic of", 'lang': "en", 'currency': 'USD' },
  IQ: { 'name': "Iraq", 'lang': "en", 'currency': 'USD' },
  IE: { 'name': "Ireland", 'lang': "en", 'currency': 'EUR' },
  IM: { 'name': "Isle of Man", 'lang': "en", 'currency': 'USD' },
  IL: { 'name': "Israel", 'lang': "en", 'currency': 'USD' },
  IT: { 'name': "Italy", 'lang': "en", 'currency': 'EUR' },
  JM: { 'name': "Jamaica", 'lang': "en", 'currency': 'USD' },
  JP: { 'name': "Japan", 'lang': "en", 'currency': 'USD' },
  JE: { 'name': "Jersey", 'lang': "en", 'currency': 'USD' },
  JO: { 'name': "Jordan", 'lang': "en", 'currency': 'USD' },
  KZ: { 'name': "Kazakhstan", 'lang': "en", 'currency': 'USD' },
  KE: { 'name': "Kenya", 'lang': "en", 'currency': 'USD' },
  KI: { 'name': "Kiribati", 'lang': "en", 'currency': 'USD' },
  KP: { 'name': "Korea, Democratic People's Republic of", 'lang': "en", 'currency': 'USD' },
  KR: { 'name': "Korea, Republic of", 'lang': "en", 'currency': 'USD' },
  KW: { 'name': "Kuwait", 'lang': "en", 'currency': 'USD' },
  KG: { 'name': "Kyrgyzstan", 'lang': "en", 'currency': 'USD' },
  LA: { 'name': "Lao People's Democratic Republic", 'lang': "en", 'currency': 'USD' },
  LV: { 'name': "Latvia", 'lang': "en", 'currency': 'EUR' },
  LB: { 'name': "Lebanon", 'lang': "en", 'currency': 'USD' },
  LS: { 'name': "Lesotho", 'lang': "en", 'currency': 'USD' },
  LR: { 'name': "Liberia", 'lang': "en", 'currency': 'USD' },
  LY: { 'name': "Libya", 'lang': "en", 'currency': 'USD' },
  LI: { 'name': "Liechtenstein", 'lang': "en", 'currency': 'USD' },
  LT: { 'name': "Lithuania", 'lang': "en", 'currency': 'EUR' },
  LU: { 'name': "Luxembourg", 'lang': "en", 'currency': 'EUR' },
  MO: { 'name': "Macao", 'lang': "pt", 'currency': 'USD' },
  MK: { 'name': "Macedonia, the Former Yugoslav Republic of", 'lang': "en", 'currency': 'USD' },
  MG: { 'name': "Madagascar", 'lang': "en", 'currency': 'USD' },
  MW: { 'name': "Malawi", 'lang': "en", 'currency': 'USD' },
  MY: { 'name': "Malaysia", 'lang': "en", 'currency': 'USD' },
  MV: { 'name': "Maldives", 'lang': "en", 'currency': 'USD' },
  ML: { 'name': "Mali", 'lang': "en", 'currency': 'USD' },
  MT: { 'name': "Malta", 'lang': "en", 'currency': 'EUR' },
  MH: { 'name': "Marshall Islands", 'lang': "en", 'currency': 'USD' },
  MQ: { 'name': "Martinique", 'lang': "en", 'currency': 'USD' },
  MR: { 'name': "Mauritania", 'lang': "en", 'currency': 'USD' },
  MU: { 'name': "Mauritius", 'lang': "en", 'currency': 'USD' },
  YT: { 'name': "Mayotte", 'lang': "en", 'currency': 'USD' },
  MX: { 'name': "Mexico", 'lang': "es", 'currency': 'USD' },
  FM: { 'name': "Micronesia, Federated States of", 'lang': "en", 'currency': 'USD' },
  MD: { 'name': "Moldova, Republic of", 'lang': "en", 'currency': 'USD' },
  MC: { 'name': "Monaco", 'lang': "en", 'currency': 'EUR' },
  MN: { 'name': "Mongolia", 'lang': "en", 'currency': 'USD' },
  ME: { 'name': "Montenegro", 'lang': "en", 'currency': 'EUR' },
  MS: { 'name': "Montserrat", 'lang': "en", 'currency': 'USD' },
  MA: { 'name': "Morocco", 'lang': "en", 'currency': 'USD' },
  MZ: { 'name': "Mozambique", 'lang': "pt", 'currency': 'USD' },
  MM: { 'name': "Myanmar", 'lang': "en", 'currency': 'USD' },
  NA: { 'name': "Namibia", 'lang': "en", 'currency': 'USD' },
  NR: { 'name': "Nauru", 'lang': "en", 'currency': 'USD' },
  NP: { 'name': "Nepal", 'lang': "en", 'currency': 'USD' },
  NL: { 'name': "Netherlands", 'lang': "en", 'currency': 'EUR' },
  NC: { 'name': "New Caledonia", 'lang': "en", 'currency': 'USD' },
  NZ: { 'name': "New Zealand", 'lang': "en", 'currency': 'USD' },
  NI: { 'name': "Nicaragua", 'lang': "es", 'currency': 'USD' },
  NE: { 'name': "Niger", 'lang': "en", 'currency': 'USD' },
  NG: { 'name': "Nigeria", 'lang': "en", 'currency': 'USD' },
  NU: { 'name': "Niue", 'lang': "en", 'currency': 'USD' },
  NF: { 'name': "Norfolk Island", 'lang': "en", 'currency': 'USD' },
  MP: { 'name': "Northern Mariana Islands", 'lang': "en", 'currency': 'USD' },
  NO: { 'name': "Norway", 'lang': "en", 'currency': 'USD' },
  OM: { 'name': "Oman", 'lang': "en", 'currency': 'USD' },
  PK: { 'name': "Pakistan", 'lang': "en", 'currency': 'USD' },
  PW: { 'name': "Palau", 'lang': "en", 'currency': 'USD' },
  PS: { 'name': "Palestine, State of", 'lang': "en", 'currency': 'USD' },
  PA: { 'name': "Panama", 'lang': "es", 'currency': 'USD' },
  PG: { 'name': "Papua New Guinea", 'lang': "en", 'currency': 'USD' },
  PY: { 'name': "Paraguay", 'lang': "es", 'currency': 'USD' },
  PE: { 'name': "Peru", 'lang': "es", 'currency': 'USD' },
  PH: { 'name': "Philippines", 'lang': "en", 'currency': 'USD' },
  PN: { 'name': "Pitcairn", 'lang': "en", 'currency': 'USD' },
  PL: { 'name': "Poland", 'lang': "en", 'currency': 'USD' },
  PT: { 'name': "Portugal", 'lang': "pt", 'currency': 'EUR' },
  PR: { 'name': "Puerto Rico", 'lang': "en", 'currency': 'USD' },
  QA: { 'name': "Qatar", 'lang': "en", 'currency': 'USD' },
  RE: { 'name': "R\u00e9union", 'lang': "en", 'currency': 'USD' },
  RO: { 'name': "Romania", 'lang': "en", 'currency': 'USD' },
  RU: { 'name': "Russian Federation", 'lang': "en", 'currency': 'USD' },
  RW: { 'name': "Rwanda", 'lang': "en", 'currency': 'USD' },
  BL: { 'name': "Saint Barth\u00e9lemy", 'lang': "en", 'currency': 'EUR' },
  SH: { 'name': "Saint Helena, Ascension and Tristan da Cunha", 'lang': "en", 'currency': 'USD' },
  KN: { 'name': "Saint Kitts and Nevis", 'lang': "en", 'currency': 'USD' },
  LC: { 'name': "Saint Lucia", 'lang': "en", 'currency': 'USD' },
  MF: { 'name': "Saint Martin (French part)", 'lang': "en", 'currency': 'USD' },
  PM: { 'name': "Saint Pierre and Miquelon", 'lang': "en", 'currency': 'EUR' },
  VC: { 'name': "Saint Vincent and the Grenadines", 'lang': "en", 'currency': 'USD' },
  WS: { 'name': "Samoa", 'lang': "en", 'currency': 'USD' },
  SM: { 'name': "San Marino", 'lang': "en", 'currency': 'EUR' },
  ST: { 'name': "Sao Tome and Principe", 'lang': "pt", 'currency': 'USD' },
  SA: { 'name': "Saudi Arabia", 'lang': "en", 'currency': 'USD' },
  SN: { 'name': "Senegal", 'lang': "en", 'currency': 'USD' },
  RS: { 'name': "Serbia", 'lang': "en", 'currency': 'USD' },
  SC: { 'name': "Seychelles", 'lang': "en", 'currency': 'USD' },
  SL: { 'name': "Sierra Leone", 'lang': "en", 'currency': 'USD' },
  SG: { 'name': "Singapore", 'lang': "en", 'currency': 'USD' },
  SX: { 'name': "Sint Maarten (Dutch part)", 'lang': "en", 'currency': 'USD' },
  SK: { 'name': "Slovakia", 'lang': "en", 'currency': 'EUR' },
  SI: { 'name': "Slovenia", 'lang': "en", 'currency': 'EUR' },
  SB: { 'name': "Solomon Islands", 'lang': "en", 'currency': 'USD' },
  SO: { 'name': "Somalia", 'lang': "en", 'currency': 'USD' },
  ZA: { 'name': "South Africa", 'lang': "en", 'currency': 'USD' },
  GS: { 'name': "South Georgia and the South Sandwich Islands", 'lang': "en", 'currency': 'USD' },
  SS: { 'name': "South Sudan", 'lang': "en", 'currency': 'USD' },
  ES: { 'name': "Spain", 'lang': "es", 'currency': 'EUR' },
  LK: { 'name': "Sri Lanka", 'lang': "en", 'currency': 'USD' },
  SD: { 'name': "Sudan", 'lang': "en", 'currency': 'USD' },
  SR: { 'name': "Suri'name'", 'lang': "en", 'currency': 'USD' },
  SJ: { 'name': "Svalbard and Jan Mayen", 'lang': "en", 'currency': 'USD' },
  SZ: { 'name': "Swaziland", 'lang': "en", 'currency': 'USD' },
  SE: { 'name': "Sweden", 'lang': "en", 'currency': 'USD' },
  CH: { 'name': "Switzerland", 'lang': "en", 'currency': 'USD' },
  SY: { 'name': "Syrian Arab Republic", 'lang': "en", 'currency': 'USD' },
  TW: { 'name': "Taiwan, Province of China", 'lang': "en", 'currency': 'USD' },
  TJ: { 'name': "Tajikistan", 'lang': "en", 'currency': 'USD' },
  TZ: { 'name': "Tanzania, United Republic of", 'lang': "en", 'currency': 'USD' },
  TH: { 'name': "Thailand", 'lang': "en", 'currency': 'USD' },
  TL: { 'name': "Timor-Leste", 'lang': "pt", 'currency': 'USD' },
  TG: { 'name': "Togo", 'lang': "en", 'currency': 'USD' },
  TK: { 'name': "Tokelau", 'lang': "en", 'currency': 'USD' },
  TO: { 'name': "Tonga", 'lang': "en", 'currency': 'USD' },
  TT: { 'name': "Trinidad and Tobago", 'lang': "en", 'currency': 'USD' },
  TN: { 'name': "Tunisia", 'lang': "en", 'currency': 'USD' },
  TR: { 'name': "Turkey", 'lang': "en", 'currency': 'USD' },
  TM: { 'name': "Turkmenistan", 'lang': "en", 'currency': 'USD' },
  TC: { 'name': "Turks and Caicos Islands", 'lang': "en", 'currency': 'USD' },
  TV: { 'name': "Tuvalu", 'lang': "en", 'currency': 'USD' },
  UG: { 'name': "Uganda", 'lang': "en", 'currency': 'USD' },
  UA: { 'name': "Ukraine", 'lang': "en", 'currency': 'USD' },
  AE: { 'name': "United Arab Emirates", 'lang': "en", 'currency': 'USD' },
  UK: { 'name': "United Kingdom", 'lang': "en", 'currency': 'USD' },
  US: { 'name': "United States", 'lang': "en", 'currency': 'USD' },
  UM: { 'name': "United States Minor Outlying Islands", 'lang': "en", 'currency': 'USD' },
  UY: { 'name': "Uruguay", 'lang': "es", 'currency': 'USD' },
  UZ: { 'name': "Uzbekistan", 'lang': "en", 'currency': 'USD' },
  VU: { 'name': "Vanuatu", 'lang': "en", 'currency': 'USD' },
  VE: { 'name': "Venezuela, Bolivarian Republic of", 'lang': "es", 'currency': 'USD' },
  VN: { 'name': "Viet Nam", 'lang': "en", 'currency': 'USD' },
  VG: { 'name': "Virgin Islands, British", 'lang': "en", 'currency': 'USD' },
  VI: { 'name': "Virgin Islands, U.S.", 'lang': "en", 'currency': 'USD' },
  WF: { 'name': "Wallis and Futuna", 'lang': "en", 'currency': 'USD' },
  EH: { 'name': "Western Sahara", 'lang': "en", 'currency': 'USD' },
  YE: { 'name': "Yemen", 'lang': "en", 'currency': 'USD' },
  ZM: { 'name': "Zambia", 'lang': "en", 'currency': 'USD' },
  ZW: { 'name': "Zimbabwe", 'lang': "en", 'currency': 'EUR' },
}
export function getLanguageAndCurrencyByCountry(countryCode2) {
  return LANG_AND_CURRENCY_BY_COUNTRY[countryCode2.toUpperCase()] || { 'name': "", 'lang': "en", 'currency': 'USD' };
};

export function getVatRateByCountry(countryCode2) {
  // rates from https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm#shortcut-7 
  // last update in 2020-09-08
  const VAT_RATE_BY_COUNTRY = {
    AT: 20,
    BE: 21,
    BG: 20,
    CY: 19,
    CZ: 21,
    DE: 16,
    DK: 25,
    EE: 20,
    EL: 24,
    ES: 21,
    FI: 24,
    FR: 20,
    HR: 25,
    HU: 27,
    IE: 23,
    IT: 22,
    LT: 21,
    LU: 17,
    LV: 21,
    MT: 18,
    NL: 21,
    PL: 23,
    PT: 23,
    RO: 19,
    SE: 25,
    SI: 22,
    SK: 20,
    UK: 20,
  }
  return VAT_RATE_BY_COUNTRY[countryCode2] || 0;
}