import { getInstanceGeoIp } from "./axios";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_GEO_IP_COUNTRY = "GET_GEO_IP_COUNTRY";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

export function changeLang(langCode) {
  return {
    type: CHANGE_LANGUAGE,
    payload: langCode,
  };
}

export function changeCurrency(currencyCode) {
  return {
    type: CHANGE_CURRENCY,
    payload: currencyCode,
  };
}

export function getGeoIpCountry() {
  return function action(dispatch) {
    const request = getInstanceGeoIp()({
      method: "get",
    });
    return request
      .then((response) => {
        const countryCode2 = response.data.result.country_code;
        if (countryCode2.length === 2) {
          dispatch(getGeoIpCountrySuccess(countryCode2.toLowerCase()));
        } else {
          console.log(
            "error in getGeoIpCountry (not length 2): countryCode2=",
            countryCode2
          );
        }
      })
      .catch((error) => {
        // do nothing
        console.log("error in getGeoIpCountry", error);
      });
  };
}
function getGeoIpCountrySuccess(countryCode2) {
  return {
    type: GET_GEO_IP_COUNTRY,
    payload: countryCode2,
  };
}
