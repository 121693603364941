import React, { Component } from "react";
// import classnames from 'classnames';
import styles from "./home-page.module.css";
import { ButtonBase } from "@material-ui/core";
import PriceCards from "../components/priceCards/priceCards";
import { _t } from "src/redux/store";
import renderHTML from 'html-react-parser';
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { Helmet } from "react-helmet";
import SwiperDownload from "../components/Home/swiperDownload";
import SwiperOverview from "../components/Home/swiperOverview";
import SwiperTestimonials from "../components/Home/swiperTestimonials";
import { renderBetfairText, renderWagertoolText } from "src/components/SmartProTrading/renderLinks";

export default class HomePage extends Component {
  render() {
    return (
      <div className={styles.homePage}>
        <Helmet>
          <title>{_t.translate("SmartProTrading Betfair trading software for MacOs, Linux and Windows")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <div className={styles.containerInicial}>
          <div className={styles.leftCell}>
            <h3>
              {_t.translate(
                "You have found the right trading software for you."
              )}
              <br />
              <span className={styles.font900}>
                {_t.translate("Simple, intuitive and customizable")}
              </span>
            </h3>
            <p className={styles.spaceBottom} ref={(el) => setInnerLogo(el)}>
              {renderHTML(
                _t.translate(
                  "Trading must be accessible. Without limitations. That's why we simplified the trade - with {{logoname}}.",
                  {
                    logoname: '<span class="logoHere"></span>',
                  }
                )
              )}
            </p>
            <p>
              {renderHTML(_t.translate(
                "A software that responds to the needs of all gamblers and that was created to be everyone's best of the best. Developed by the {{wagertoolLink}} group.",
                { wagertoolLink: renderWagertoolText('blend') }
              ))}
            </p>
          </div>
          <div className={styles.rightCell}>
            <img
              className={styles.w100}
              src="img/homepage-1.jpg"
              alt={_t.translate("Homepage")}
              width="850"
            />
          </div>
        </div>
        <div className={styles.containerSecond}>
          <div className={styles.textCenter}>
            <p className={styles.title}>
              {_t.translate(
                "Bet and Trade accurately, using our integrated response features"
              )}
            </p>
          </div>
          <div className={styles.line}>
            <div className={styles.leftCell}>
              <p className={styles.spaceBottom} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "{{logoname}} is next generation software that will" +
                    " help you with your operations. We designed and developed, in detail," +
                    " a clean layout suitable for the 3 largest operating systems.",
                    {
                      logoname: '<span class="logoHere"></span>',
                    }
                  )
                )}
              </p>
            </div>
            <div className={styles.rightCell}>
              <p className={styles.spaceBottom} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "Whenever you want, customize {{logoname}} to become" +
                    " a software suited to your trade. Adjust the size of the fonts contained in the" +
                    " Ladder or hide selections that you do not want to work on, for a unique experience.",
                    {
                      logoname: '<span class="logoHere"></span>',
                    }
                  )
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.containerThird}>
          <div className={styles.cell}>
            <p className={styles.title}>{_t.translate("APROVED BY BETFAIR")}</p>
            <p className={styles.text}>
              {renderHTML(_t.translate(
                "Tested and approved by {{betfairLink}}. The security of user information is guaranteed.",
                { betfairLink: renderBetfairText() }
              ))}
            </p>
          </div>
          <div className={styles.cell}>
            <p className={styles.title}>{_t.translate("CUSTOMIZABLE")}</p>
            <p className={styles.text}>
              {_t.translate(
                "Designed for professional traders who demand speed and personal customization."
              )}
            </p>
          </div>
          <div className={styles.cell}>
            <p className={styles.title}>MULTIPLATFORM</p>
            <p className={styles.text}>
              {_t.translate("Ready for Windows, Linux and MacOS.")}
            </p>
          </div>
        </div>
        <div className={styles.containerFourth}>
          <div className={styles.textCenter}>
            <p className={styles.title}>
              {_t.translate("Download available for")}
            </p>
          </div>
          <div className={styles.row}>
            <div className={styles.cell}>
              <img
                className={styles.w100}
                src="img/windows.png"
                width="120"
                alt="Windows"
              />
            </div>
            <div className={styles.cell}>
              <img
                className={styles.w100}
                src="img/macos.png"
                width="120"
                alt="macos"
              />
            </div>
            <div className={styles.cell}>
              <img
                className={styles.w100}
                src="img/linux.png"
                width="120"
                alt="linux"
              />
            </div>
          </div>
        </div>
        <div className={styles.containerFifth}>
          <SwiperDownload />
          <div className={styles.cell}>
            <ButtonBase
              href="/download"
              classes={{ root: styles.btn }}
            >
              <p className={styles.download}>
                {_t.translate("DOWNLOAD")}
              </p>
              <p className={styles.tryNow}>
                {_t.translate("TRY NOW")}
              </p>
            </ButtonBase>
            <p className={styles.small}>{_t.translate("15 days free!")}</p>
          </div>
        </div>
        <div className={styles.containerSixth}>
          <div className={styles.cellTop}>
            <p className={styles.double} ref={(el) => setInnerLogo(el)}>
              {renderHTML(
                _t.translate(
                  "{{logoname}} is cutting edge trading software developed by traders for traders.",
                  {
                    logoname: '<span class="logoHere"></span>',
                  }
                )
              )}
            </p>
          </div>
          <div className={styles.row}>
            <div className={styles.leftCell}>
              <p className={styles.bottomSpace} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "{{logoname}}, built on a multiplatform framework, allows you to keep" +
                    " a single code always up to date from a professional software to do Trading at {{betfairLink}}, and" +
                    " that runs on several operating systems: Windows, MacOS, Linux.",
                    {
                      logoname: '<span class="logoHere"></span>',
                      betfairLink: renderBetfairText(),
                    }
                  )
                )}
              </p>
              <p className={styles.bottomSpace}>
                {_t.translate(
                  "Trade with speed and precision. Protecting" +
                  " open positions in the market or helping to generate balance make this" +
                  " software unique. Customizable market options facilitate and streamline research, analysis and action."
                )}
              </p>
            </div>
            <div className={styles.rightCell}>
              <p className={styles.bottomSpace}>
                {_t.translate(
                  "Use the product and have access to informative content constantly" +
                  " updated and a support team available 7 days a week."
                )}
              </p>
              <p className={styles.bottomSpace}>
                {_t.translate(
                  "The market is always evolving - and so are we. We innovate to offer" +
                  " the latest technology, including advanced features that many of our competitors lack."
                )}
              </p>
              <p className={styles.bottomSpace} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "Level up with our professional level trading platform, {{logoname}}",
                    {
                      logoname: '<span class="logoHere"></span>',
                    }
                  )
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.containerSeventh}>
          <div className={styles.swiperContainer}>
            <SwiperOverview />
          </div>
        </div>
        <div className={styles.containerEighth}>
          <div className={styles.textCenter}>
            <p className={styles.title} ref={(el) => setInnerLogo(el)}>
              {renderHTML(
                _t.translate(
                  "Simple and flexible pricing to be {{logoname}}.",
                  {
                    logoname: '<span class="logoHere"></span>',
                  }
                )
              )}
            </p>
            <p className={styles.text} ref={(el) => setInnerLogo(el)}>
              {renderHTML(
                _t.translate(
                  "Choose the {{logoname}} plan that best suits you.",
                  {
                    logoname: '<span class="logoHere"></span>',
                  }
                )
              )}
            </p>
          </div>
          <PriceCards url={this.props.location}></PriceCards>
          <div>
            <p className={styles.small} ref={(el) => setInnerLogo(el)}>
              {renderHTML(
                _t.translate(
                  "We only offer a 15-day trial period per {{betfairLink}}" +
                  " user that can be used on all platforms. Installing {{logoname}} on a new" +
                  " platform does not restart the trial period.",
                  {
                    logoname: '<span class="logoHere"></span>',
                    betfairLink: renderBetfairText(),
                  }
                )
              )}
            </p>
          </div>
        </div>
        <div className={styles.textCenter}>
          <p className={styles.double} ref={(el) => setInnerLogo(el)}>
            {renderHTML('<span class="logoHere"></span>')}
          </p>
          <p className={styles.wagertool}>
            {renderHTML(_t.translate("Developed by the {{wagertoolLink}} group", { wagertoolLink: renderWagertoolText() }))}
          </p>
        </div>
        <div className={styles.containerTestimonials}>
          <p className={styles.title}>{_t.translate("Testimonials")}</p>
          <div className={styles.text}>
            <SwiperTestimonials />
            {/* <p className={styles.font300}>
              {_t.translate(
                "“It is my favorite software, without a doubt. I can customize it my way and the layout is, of all trading software, the most intuitive.” Diogo Albuquerque"
              )}
            </p> */}
          </div>
        </div>
      </div>
    );
  }
}
