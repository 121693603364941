import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import styles from "./header.module.css";
import {
  changeLang,
  getGeoIpCountry,
  changeCurrency,
} from "src/redux/actions/action-options.js";
import { _t } from "src/redux/store";
import Sidebar from "../Sidebar/sidebar";

//import css module
import { changeStep } from "src/redux/actions/action-store";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
    this.props.getGeoIpCountry();
    this.headerStickyElement = undefined;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handlePageScroll); // remove the event handler
  }

  setHeaderStickyRef = (el) => {
    if (el !== null && this.headerStickyElement !== el) {
      this.headerStickyElement = el;
      window.addEventListener("scroll", this.handlePageScroll, {
        passive: true,
      });
    }
  };

  handlePageScroll = (event) => {
    if (this.headerStickyElement !== undefined && this.headerStickyElement !== null) {
      if (event.currentTarget.scrollY > 1) {
        // page has scroll vertical (> 1px)
        this.headerStickyElement.classList.add(styles.shadowBottom);
      } else {
        // page is on top
        this.headerStickyElement.classList.remove(styles.shadowBottom);
      }
    }
  };

  handleChangeStep = () => {
    this.props.changeStep(1);
  };

  handleChangeFlag = (event) => {
    this.props.changeLang(event.target.value);
    setTimeout(() => {
      document.location.reload();
    }, 200);
  };

  handleChangeCurrency = (event) => {
    this.props.changeCurrency(event.target.value);
  };

  handleSidebar = () => {
    if (!this.state.sidebarOpen) {
      this.setState({
        sidebarOpen: true,
      });
    } else {
      this.setState({
        sidebarOpen: false,
      });
    }
  };

  render() {
    return (
      <div className={styles.header} ref={this.setHeaderStickyRef}>
        <div className={styles.headerItem}>
          <ButtonBase
            className={styles.sidebarBtn}
            onClick={this.handleSidebar}
          >
            <div className={styles.bracket__1}></div>
            <div className={styles.bracket__2}></div>
            <div className={styles.bracket__3}></div>
          </ButtonBase>
        </div>
        <div className={styles.headerLogo}>
          <ButtonBase href="/">
            <span className={styles.containerDesktop}>
              <img
                className={styles.imgLogo}
                alt="SmartProTrading"
                src="/img/SmartProTrading-logo.svg"
              ></img>
            </span>
            <span className={styles.containerMobile}>
              <img
                className={styles.imgLogo}
                alt="SmartProTrading"
                src="/img/SmartProTrading-icon.svg"
              ></img>
            </span>
          </ButtonBase>
        </div>
        <div className={styles.headerItem}>
          <ButtonBase
            href="/store"
            onClick={this.handleChangeStep}
            classes={{
              root: classnames(
                styles.btnHead,
                document.location.pathname === "/store" && styles.active
              ),
            }}
          >
            {_t.translate("Store")}
          </ButtonBase>
        </div>
        <div className={styles.headerItem}>
          <ButtonBase
            href="/user-guide/"
            classes={{
              root: classnames(
                styles.btnHead,
                document.location.pathname === "/user-guide/" && styles.active
              ),
            }}
          >
            {_t.translate("User Guide")}
          </ButtonBase>
        </div>
        <div className={styles.headerItem}>
          <ButtonBase
            href="/download"
            classes={{ root: styles.btn }}
          >
            <p className={styles.download}>
              {_t.translate("DOWNLOAD")}
            </p>
            <p className={styles.tryNow}>
              {_t.translate("TRY NOW")}
            </p>
          </ButtonBase>
          <p className={styles.small}>{_t.translate("15 days free!")}</p>
        </div>
        <div className={styles.headerItem}>
          <select
            value={this.props.lang}
            onChange={this.handleChangeFlag}
            className={styles.selects}
          >
            <option value="en">English</option>
            {/* <option value="es">Español</option> */}
            <option value="pt">Português</option>
          </select>
        </div>
        <div className={styles.headerItem}>
          <select
            value={this.props.currency}
            onChange={this.handleChangeCurrency}
            className={styles.selects}
          >
            <option value="EUR">EUR</option>
            <option value="BRL">BRL</option>
            <option value="USD">USD</option>
          </select>
        </div>
        {this.state.sidebarOpen && (
          <Sidebar
            handleClose={this.handleSidebar}
            show={this.state.sidebarOpen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.options.lang,
    currency: state.options.currency,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeLang: (langCode) => {
      dispatch(changeLang(langCode));
    },
    getGeoIpCountry: () => {
      dispatch(getGeoIpCountry());
    },
    changeCurrency: (currencyCode) => {
      dispatch(changeCurrency(currencyCode));
    },
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
