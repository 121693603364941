import React from "react";
import SmartProTrading from "src/components/SmartProTrading/SmartProTrading";
import ReactDOM from "react-dom";

export const setInnerLogo = (el = {}, props = {}) => {
  if (el !== null) {
    let listArray = Array.from(el.children);
    listArray.forEach((element) => {
      if (element.className === "logoHere") {
        ReactDOM.render(
          <SmartProTrading
            spaceLeft={props.spaceLeft}
            spaceRight={props.spaceRight}
            mainColorClass={props.mainColorClass}
            secondaryColorClass={props.secondaryColorClass}
          />,
          element
        );
      }
    });
  }
};
