import React, { Component } from "react";
import styles from "./details-page.module.css";
import { ButtonBase } from "@material-ui/core";
import { _t } from "src/redux/store";
import "react-photoswipe/lib/photoswipe.css";
import classnames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getGeoIpCountry } from "src/redux/actions/action-options.js";
import {
  saveOrderDetails,
  checkVendorClientId,
  changeTemporaryVendorClientId,
} from "src/redux/actions/action-store.js";
import { changeStep } from "src/redux/actions/action-store";
import InputVendorClientId from "./input-vendor-client-id";
import SummaryStore from "src/components/SummaryStore/summaryStore.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InvoiceDetails from "./invoice-details";
import { defaultPaymentInfo } from "./../../model/myTypesDefault";

class DetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVatInfo: false,
      isInvoiceValid: false,
    };
    this.props.getGeoIpCountry();
  }

  handleSubmitForm = () => {
    let invoiceDetails = defaultPaymentInfo.invoiceDetails;
    if (this.state.showVatInfo === true) {
      invoiceDetails = { ...this.props.invoiceDetails };
    }
    const vendorClientId = this.props.vendorClientId;
    if (
      vendorClientId !== "" &&
      (this.state.showVatInfo === false || this.state.isInvoiceValid)
    ) {
      this.props.saveOrderDetails(this.props.vendorClientId, invoiceDetails);
      this.props.changeStep(3);
    }
  };

  handleChangeValidInvoice = (isValid) => {
    this.setState({
      isInvoiceValid: isValid,
    });
  };

  handleShowVatInfo = (event) => {
    this.setState({ showVatInfo: event.target.checked });
  };

  renderInvoiceOption() {
    const isVendorClientIdConfirmed = this.props.vendorClientId !== "";
    return (
      <React.Fragment>
        <div className={styles.inputWrapper}>
          <InputVendorClientId />
          {isVendorClientIdConfirmed && (
            <FormControlLabel
              className={styles.paddingTop}
              control={
                <Checkbox
                  name="Invoice details"
                  checked={this.state.showVatInfo}
                  classes={{
                    root: classnames(styles.inputCheckBox),
                  }}
                  onChange={this.handleShowVatInfo}
                />
              }
              label={_t.translate("Add invoice details")}
            />
          )}
          {this.state.showVatInfo ? (
            <InvoiceDetails
              changeValidInvoice={this.handleChangeValidInvoice}
            />
          ) : (
            ""
          )}
        </div>
        <div className={styles.continueBtn}>
          {isVendorClientIdConfirmed === true && (
            <ButtonBase
              onClick={this.handleSubmitForm}
              classes={{
                root:
                  this.state.isInvoiceValid || this.state.showVatInfo === false
                    ? styles.BtnAct
                    : styles.BtnDeact,
              }}
              disabled={
                !this.state.isInvoiceValid && this.state.showVatInfo === true
              }
            >
              {_t.translate("Continue")}
            </ButtonBase>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div>
        <div className={styles.textCenter}>
          <p className={styles.title}>{_t.translate("Account details")}</p>
        </div>
        <div className={styles.row}>
          <div className={styles.leftCell}>
            <div>{this.renderInvoiceOption()}</div>
          </div>
          <div className={styles.rightCell}>
            <div>
              <SummaryStore />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countryGeoIp: state.options.countryGeoIp,
    invoiceDetails: state.store.order.invoiceDetails,
    vendorClientId: state.store.order.vendorClientId,
    paymentMethods: state.store.paymentMethods,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getGeoIpCountry: () => {
      dispatch(getGeoIpCountry());
    },
    saveOrderDetails: (vendorClientId, invoiceDetails) => {
      dispatch(saveOrderDetails(vendorClientId, invoiceDetails));
    },
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
    checkVendorClientId: (vendorClientId) => {
      dispatch(checkVendorClientId(vendorClientId));
    },
    changeTemporaryVendorClientId: (newVendorClientId) => {
      dispatch(changeTemporaryVendorClientId(newVendorClientId));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsPage)
);
