import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPaymentMethods,
  savePaymentMethod,
  changeIsLoading
} from "src/redux/actions/action-store";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import styles from "./paymentMethods-page.module.css";
import classnames from "classnames";
import { _t } from "src/redux/store";
import { ButtonBase } from "@material-ui/core";
import { changeStep } from "src/redux/actions/action-store";
import SummaryStore from "src/components/SummaryStore/summaryStore";

class PaymentMethodsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPaymentId: this.props.SelectedPayment,
      selectedPaymentName: "",
    };

    this.selectedBtn = false;
    this.props.getPaymentMethods();
    this.props.changeIsLoading();
  }

  componentDidMount() {
    //if payment is already selected change btn and get name
    if (this.props.SelectedPayment !== "-1") {
      let name = this.props.paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.paymentMethodId === parseInt(this.props.SelectedPayment)
      ).name;

      this.setState({
        selectedBtn: true,
        selectedPaymentName: name,
        selectedPaymentId: this.props.SelectedPayment,
      });
    }
  }

  handleRadioChange = (event) => {
    this.setState({
      selectedPaymentId: event.target.value,
      selectedPaymentName: event.target.name,
      selectedBtn: true,
    });
  };

  handleConfirmPaymentMethod = () => {
    this.props.savePaymentMethod(this.state.selectedPaymentId);
    this.props.changeStep(4);
  };

  renderPaymentMethods() {
    const paymentMethodsArray = this.props.paymentMethods;
    const activeCurrency = this.props.currency;

    return paymentMethodsArray.map((paymentMethod) => {
      const paymentMethodId = paymentMethod.paymentMethodId;
      const paymentName = paymentMethod.name;

      if (paymentMethod.currenciesSupported.indexOf(activeCurrency) === -1) {
        // case 1: not support active currency
        return null;
      } else {
        // case 2: supports active currency
        return (
          <FormControlLabel
            key={paymentMethodId}
            value={paymentMethodId.toString()}
            name={paymentName}
            control={
              <Radio
                classes={{
                  root: classnames(styles.selects),
                }}
              />
            }
            label={paymentName}
          />
        );
      }
    });
  }

  render() {
    return (
      <div className={styles.paymentContainer}>
        <div className={styles.textCenter}>
          <p className={styles.title}>
            {_t.translate("Choose your payment method")}
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.leftCell}>
            <FormControl component="fieldset">
              <RadioGroup
                onChange={this.handleRadioChange}
                value={this.state.selectedPaymentId}
              >
                {this.renderPaymentMethods()}
              </RadioGroup>
            </FormControl>
            <div className={styles.continueBtn}>
              <ButtonBase
                onClick={this.handleConfirmPaymentMethod}
                disabled={!this.state.selectedBtn}
                classes={{
                  root: classnames(
                    !this.state.selectedBtn && styles.BtnDeact,
                    this.state.selectedBtn && styles.BtnAct
                  ),
                }}
              >
                {_t.translate("Continue")}
              </ButtonBase>
            </div>
          </div>
          <div className={styles.rightCell}>
            <SummaryStore />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentMethods: state.store.paymentMethods,
    currency: state.options.currency,
    SelectedPayment: state.store.order.paymentTypeId,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getPaymentMethods: () => {
      dispatch(getPaymentMethods());
    },
    savePaymentMethod: (paymentMethodId) => {
      dispatch(savePaymentMethod(paymentMethodId));
    },
    changeStep: (step) => {
      dispatch(changeStep(step));
    },
    changeIsLoading : () => {
      dispatch(changeIsLoading());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage);
