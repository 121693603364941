import React, { Component } from "react";
import renderHTML from 'html-react-parser';
import { USER_GUIDE_TOPICS } from "src/views/userGuide-page/userGuideListData.js";
import { Helmet } from "react-helmet";
import { _t } from "src/redux/store";
import styles from "./userGuide-topic.module.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { scrollTop } from "src/utils/link-utils";

export default class UserGuideTopic extends Component {
  renderImages(imgsArray) {
    return (<div className={styles.imagesWrapper}>
      {imgsArray.map((img, index) => {
        return (
          <ModalImage
            key={index}
            className={styles.image}
            small={img.src}
            large={img.src}
            alt={img.alt}
            hideDownload={true}
          />
        );
      })}
    </div>)
  }

  render() {
    const title = USER_GUIDE_TOPICS[this.props.match.params.topicKey].title;
    const content = USER_GUIDE_TOPICS[this.props.match.params.topicKey].content;
    const imgsArray = USER_GUIDE_TOPICS[this.props.match.params.topicKey].imgs || [];

    // check if has next button
    let hasNextTopic = false;
    let nextTopicUrl = "";
    const topicIndex = Object.keys(USER_GUIDE_TOPICS).indexOf(this.props.match.params.topicKey);
    const maxIndex = Object.keys(USER_GUIDE_TOPICS).length - 1;
    if (topicIndex >= 0 && topicIndex < maxIndex) {
      hasNextTopic = true;
      const nextTopicKey = Object.keys(USER_GUIDE_TOPICS)[topicIndex + 1];
      nextTopicUrl = USER_GUIDE_TOPICS[nextTopicKey].topicUrl;
    }

    // check if has previous button
    let hasPreviousTopic = false;
    let previousTopicUrl = "";
    if (topicIndex >= 1 && topicIndex <= maxIndex) {
      hasPreviousTopic = true;
      let previousTopicKey = Object.keys(USER_GUIDE_TOPICS)[topicIndex - 1];
      // check if previous is a simple redirect topic, to choose 2 items before in that case
      if (USER_GUIDE_TOPICS[previousTopicKey].topicUrl !== previousTopicKey) {
        previousTopicKey = Object.keys(USER_GUIDE_TOPICS)[topicIndex - 2];
      }
      previousTopicUrl = USER_GUIDE_TOPICS[previousTopicKey].topicUrl;
    }

    return (
      <div className={styles.userGuideContent}>
        <Helmet>
          <title>
            {title + " - " + _t.translate("SmartProTrading User Guide")}
          </title>
        </Helmet>
        <h3>{title}</h3>
        {renderHTML(content)}
        {this.renderImages(imgsArray)}
        <div className={styles.navigationBtns}>
          <ButtonBase
            classes={{ root: styles.btn, disabled: styles.disabledBtn }}
            component={Link}
            to={"/user-guide/" + previousTopicUrl}
            onClick={(() => scrollTop())}
            disabled={hasPreviousTopic === false}
          >
            Previous article
            </ButtonBase>
          <ButtonBase
            classes={{ root: styles.btn, disabled: styles.disabledBtn }}
            component={Link}
            to={"/user-guide/" + nextTopicUrl}
            onClick={(() => scrollTop())}
            disabled={hasNextTopic === false}
          >
            Next article
            </ButtonBase>
        </div>
      </div>
    );
  }
}
