import React, { Component } from "react";
import styles from "./download-page.module.css";
import { Route, Switch, Redirect } from "react-router";
import DownloadData from "src/views/download-page/downloadRequirements";
import classnames from "classnames";
import { ButtonBase } from "@material-ui/core";
import { DOWNLOAD_REQUIREMENTS } from "src/views/download-page/downloadRequirementsData";
import { _t } from "src/redux/store";
import renderHTML from 'html-react-parser';
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
import { NavLink } from "react-router-dom";
import { renderBetfairText } from "src/components/SmartProTrading/renderLinks";

export default class DownloadPage extends Component {
  renderOSButtons() {
    return Object.keys(DOWNLOAD_REQUIREMENTS).map((topicKey) => {
      const item = DOWNLOAD_REQUIREMENTS[topicKey];
      return (
        <div className={styles.cell} key={topicKey}>
          <ButtonBase
            component={NavLink}
            to={item.tabUrl}
            classes={{ root: styles.btnOS }}
            activeClassName={styles.active}
          >
            <div className={styles.buttonTab}>
              <p className={styles.text}>{item.os}</p>
              <span className={styles.borderBottom}></span>
            </div>
          </ButtonBase>
        </div>
      );
    });
  }

  renderDownloadButton() {
    let selectedItem = {};
    Object.keys(DOWNLOAD_REQUIREMENTS).some(topicKey => {
      const item = DOWNLOAD_REQUIREMENTS[topicKey];
      if (this.props.location.pathname === item.tabUrl) {
        selectedItem = item;
        return true;
      } else {
        return false;
      }
    });

    return (
      <div className={styles.btnDownload}>
        <ButtonBase
          disabled={false}
          classes={{ root: styles.btn, disabled: styles.btnDisable }}
          href={selectedItem.downloadLink} download
        >
          <span className={classnames(styles.icon, styles[selectedItem.os])}></span>DOWNLOAD
        </ButtonBase>
        <p className={styles.smallText} ref={(el) => setInnerLogo(el)}>
          {renderHTML(
            _t.translate(
              "By downloading {{logoname}}, you agree to the terms and conditions.",
              {
                logoname: '<span class="logoHere"></span>',
              }
            )
          )}
        </p>
      </div>);
  }

  render() {
    return (
      <div className={styles.main}>
        <h1 className={styles.title}>
          {renderHTML(_t.translate("Trade at {{betfairLink}} with professional tools", { betfairLink: renderBetfairText('blend') }))}
        </h1>
        <div className={styles.column}>
          <div className={styles.rowTop}>
            <div className={styles.leftCell}>
              <p className={styles.textTitle} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "Use {{logoname}} for 15 days, free of charge.",
                    {
                      logoname: '<span class="logoHere"></span>',
                    }
                  )
                )}
              </p>
              <p className={styles.darkBlue} ref={(el) => setInnerLogo(el)}>
                {renderHTML(
                  _t.translate(
                    "You will have full access to all features of the" +
                    " paid version. Your {{logoname}} license is associated with your {{betfairLink}} user." +
                    " After logging into {{logoname}}, you can use it on any computer without additional licenses.",
                    {
                      logoname: '<span class="logoHere"></span>',
                      betfairLink: renderBetfairText()
                    }
                  )
                )}
              </p>
              <div className={styles.containerBtns} id="container-btns">
                {this.renderOSButtons()}
              </div>
              {this.renderDownloadButton()}
            </div>
            <div className={styles.rightCell}>
              <Switch>
                <Route
                  exact
                  path="/download/:os"
                  component={DownloadData}
                />
                <Redirect from="/download" to="/download/windows" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
