import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./loja-page.module.css";
import { Helmet } from "react-helmet";
import { _t } from "src/redux/store";
import renderHTML from 'html-react-parser';
import { setInnerLogo } from "src/components/SmartProTrading/renderLogo";
// import { Route, Switch, Redirect } from "react-router";
import StoreTabs from "./store/store-tab";
import PriceCards from "src/components/priceCards/priceCards";
import DetailsPage from "./store/details-page";
import PaymentMethodsPage from "./store/paymentMethods-page";
import ConfirmationPage from "./store/confirmation-page";

class LojaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTabs() {
    return <StoreTabs></StoreTabs>;
  }

  renderTabContent() {
    if (this.props.currentStep === 1) {
      return (
        <div className={styles.textCenter}>
          <p className={styles.title}>{_t.translate("Choose your plan")}</p>
          <PriceCards />
        </div>
      );
    } else if (this.props.currentStep === 2) {
      return <DetailsPage />;
    } else if (this.props.currentStep === 3) {
      return <PaymentMethodsPage />;
    } else if (this.props.currentStep === 4) {
      return <ConfirmationPage />;
    }
  }

  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>{_t.translate("SmartProTrading Shop page")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <div className={styles.textCenter}>
          <p className={styles.titleCenter} ref={(el) => setInnerLogo(el)}>
            {renderHTML(
              _t.translate("Simple and flexible pricing to be {{logoname}}.", {
                logoname: '<span class="logoHere"></span>',
              })
            )}
          </p>
        </div>
        <div>{this.renderTabs()}</div>
        <div className={styles.container}>{this.renderTabContent()}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    countryGeoIp: state.options.countryGeoIp,
    currency: state.options.currency,
    currentStep: state.store.currentStep,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LojaPage);
