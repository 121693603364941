import { getInstanceBackend, getLongInstanceBackend } from "./axios";
import { objectKeysToLowerCase } from "./../../utils/apiResponseUtils";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const CHECK_VENDOR_CLIENT_ID_SUCCESS = "CHECK_VENDOR_CLIENT_ID_SUCCESS";
export const CHECK_VENDOR_CLIENT_ID_ERROR = "CHECK_VENDOR_CLIENT_ID_ERROR";
export const CHANGE_TEMP_VENDOR_CLIENT_ID = "CHANGE_TEMP_VENDOR_CLIENT_ID";
export const CHANGE_COUNTRY_ISO2 = "CHANGE_COUNTRY_ISO2";
export const CHANGE_VAT_RATE = "CHANGE_VAT_RATE";
export const CHANGE_CLIENT_NAME = "CHANGE_CLIENT_NAME";
export const CHANGE_CLIENT_ID_NUMBER = "CHANGE_CLIENT_ID_NUMBER";
export const CHANGE_TEMP_CLIENT_ID_NUMBER = "CHANGE_TEMP_CLIENT_ID_NUMBER";
export const CHANGE_CLIENT_VAT_NUMBER = "CHANGE_CLIENT_VAT_NUMBER";
export const CHANGE_TEMP_CLIENT_VAT_NUMBER = "CHANGE_TEMP_CLIENT_VAT_NUMBER";
export const CHANGE_CLIENT_CITY = "CHANGE_CLIENT_CITY";
export const CHANGE_CLIENT_ADDRESS = "CHANGE_CLIENT_ADDRESS";
export const CHANGE_CLIENT_ZIP_CODE = "CHANGE_CLIENT_ZIP_CODE";
export const CHANGE_TEMP_CLIENT_ZIP_CODE = "CHANGE_TEMP_CLIENT_ZIP_CODE";
export const CHANGE_PRODUCT_ID = "CHANGE_PRODUCT_ID";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const CHANGE_STEP = "CHANGE_STEP";
export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";
export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";
export const POST_ORDER_SUCCESS = "POST_ORDER_SUCCESS";
export const CLEAN_INVOICE_DETAILS = "CLEAN_INVOICE_DETAILS";
export const GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL";
export const IS_LOADING_GET_PAYMENT_DETAIL = "IS_LOADING_GET_PAYMENT_DETAIL";
export const IS_LOADING_ORDER = "IS_LOADING_GET_ORDER";
export const CHANGE_IS_LOADING_ORDER = "CHANGE_IS_LOADING_ORDER";

export function getProducts() {
  return function action(dispatch) {
    const request = getInstanceBackend()({
      method: "get",
      url: "GetSubscriptionTypes",
    });
    return request
      .then((response) => {
        dispatch(getProductsSuccess(response.data));
      })
      .catch((error) => {
        // do nothing
        console.log("error in getProducts", error);
      });
  };
}

export function checkVendorClientId(receivedVendorClientId) {
  return function action(dispatch) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/GetUserLicense/" + receivedVendorClientId,
    });
    return request
      .then((response) => {
        const isValid = typeof response.data.UserId === "undefined";
        if (!isValid) {
          dispatch(checkVendorClientIdSuccess(receivedVendorClientId));
        } else {
          dispatch(checkVendorClientIdError(response.data));
        }
      })
      .catch((error) => {
        // do nothing
        console.log("error in checkVendorClientId", error);
      });
  };
}

function checkVendorClientIdSuccess(responseData) {
  return {
    type: CHECK_VENDOR_CLIENT_ID_SUCCESS,
    payload: responseData,
  };
}

function checkVendorClientIdError(responseData) {
  return {
    type: CHECK_VENDOR_CLIENT_ID_ERROR,
    payload: responseData,
  };
}

export function changeTemporaryVendorClientId(newVendorCliendId) {
  return {
    type: CHANGE_TEMP_VENDOR_CLIENT_ID,
    payload: newVendorCliendId,
  };
}

export function changeCountryIso2(country) {
  return {
    type: CHANGE_COUNTRY_ISO2,
    payload: country,
  };
}

export function changeVatRate(rate) {
  return {
    type: CHANGE_VAT_RATE,
    payload: rate,
  };
}

export function changeClientName(newCliendName) {
  return {
    type: CHANGE_CLIENT_NAME,
    payload: newCliendName,
  };
}

export function changeClientVatNumber(newCliendVatNumber) {
  return {
    type: CHANGE_CLIENT_VAT_NUMBER,
    payload: newCliendVatNumber,
  };
}

export function changeTempClientVatNumber(newTempCliendVatNumber) {
  return {
    type: CHANGE_TEMP_CLIENT_VAT_NUMBER,
    payload: newTempCliendVatNumber,
  };
}

export function changeClientCity(newClientCity) {
  return {
    type: CHANGE_CLIENT_CITY,
    payload: newClientCity,
  };
}

export function changeClientAddress(newCliendAddress) {
  return {
    type: CHANGE_CLIENT_ADDRESS,
    payload: newCliendAddress,
  };
}

export function changeClientZipCode(newCliendZipCode) {
  return {
    type: CHANGE_CLIENT_ZIP_CODE,
    payload: newCliendZipCode,
  };
}

export function changeTempClientZipCode(newTempCliendZipCode) {
  return {
    type: CHANGE_TEMP_CLIENT_ZIP_CODE,
    payload: newTempCliendZipCode,
  };
}

export function getPaymentMethods() {
  return function action(dispatch) {
    const request = getInstanceBackend()({
      methods: "get",
      url: "GetPaymentTypes",
    });
    return request
      .then((response) => {
        return dispatch(getPaymentMethodsSuccess(response.data)); // need the return data in getPaymentDetail function
      })
      .catch((error) => {
        console.log("error in getPaymentMethods ", error);
      });
  };
}

export function postOrder(
  subscriptionTypeId,
  currency,
  vendorClientId,
  invoiceDetails,
  paymentTypeId
) {
  return function action(dispatch) {
    dispatch(isLoadingOrder());
    const request = getInstanceBackend()({
      method: "post",
      url: "CreatePayment",
      data: {
        subscriptionTypeId: subscriptionTypeId,
        currency: currency, // BRL, USD, EUR
        vendorClientId: vendorClientId,
        invoiceDetails: {
          // isCustomDetails: false,
          // countryIso2: "",
          // name: "",
          // address: "",
          // zipCode: "",
          // city: "",
          // vatNumber: "",
          ...invoiceDetails
        },
        paymentTypeId: paymentTypeId,
      },
    });
    return request
      .then((response) => {
        dispatch(postOrderSuccess(response.data));
      })
      .catch((error) => {
        console.log("error in getPaymentMethods ", error);
      });
  };
}

function postOrderSuccess(responsoData) {
  return {
    type: POST_ORDER_SUCCESS,
    payload: responsoData,
  };
}

function getProductsSuccess(responseData) {
  return {
    type: GET_PRODUCTS,
    payload: responseData,
  };
}

export function changeProductId(productId) {
  return {
    type: CHANGE_PRODUCT_ID,
    payload: productId,
  };
}

export function changeStep(step) {
  return {
    type: CHANGE_STEP,
    payload: step,
  };
}

export function saveOrderDetails(vendorClientId, invoiceDetails) {
  return {
    type: SAVE_ORDER_DETAILS,
    payload: {
      vendorClientId: vendorClientId,
      invoiceDetails: invoiceDetails,
    },
  };
}

function getPaymentMethodsSuccess(responseData) {
  return {
    type: GET_PAYMENT_METHODS,
    payload: responseData,
  };
}

export function savePaymentMethod(paymentMethodId) {
  return {
    type: SAVE_PAYMENT_METHOD,
    payload: {
      paymentMethodId: paymentMethodId,
    },
  };
}

export function cleanInvoiceDetails() {
  return {
    type: CLEAN_INVOICE_DETAILS,
  };
}

export function getPaymentDetail(paymentTypeName, paymentToken) {
  return async function action(dispatch, getState) {
    dispatch(isLoadingGetPaymentDetail());
    // if don't have product info in store, should call getProducts before continue
    const productsArray = getState().store.products;
    if (productsArray.length === 0) {
      await dispatch(getProducts());
    }
    // if don't have paymentMethods info in store, should call getPaymentMethods before continue
    let paymentMethodsArray = getState().store.paymentMethods;
    let paymentTypeId = -1;
    if (paymentMethodsArray.length === 0) {
      const actionGetPaymentMethods = await dispatch(getPaymentMethods());
      paymentMethodsArray = objectKeysToLowerCase(actionGetPaymentMethods.payload);
    }
    // convert paymentTypeName in paymentTypeId
    paymentMethodsArray.some(method => {
      if (method.name.toLowerCase() === paymentTypeName) {
        paymentTypeId = method.paymentMethodId;
        return true;
      } else {
        return false;
      }
    })
    // finally cal get payment details
    dispatch(getPayment(paymentTypeId, paymentToken));
  };
}

function getPayment(paymentTypeId, paymentToken) {
  return function action(dispatch) {
    const request = getLongInstanceBackend()({
      methods: "get",
      url: "GetPayment",
      params: {
        typeId: paymentTypeId,
        token: paymentToken
      }
    });
    return request
      .then((response) => {
        dispatch({
          type: GET_PAYMENT_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log("error in getPayment ", error);
      });
  };
}

export function isLoadingGetPaymentDetail() {
  return {
    type: IS_LOADING_GET_PAYMENT_DETAIL,
  };
}
export function isLoadingOrder() {
  return {
    type: IS_LOADING_ORDER,
  };
}
export function changeIsLoading() {
  return {
    type: CHANGE_IS_LOADING_ORDER,
  };
}