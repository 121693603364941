import React, { Component } from "react";
import styles from "./contact-page.module.css";
import { _t } from "src/redux/store";
import { Helmet } from "react-helmet";
import { renderEmailText } from "src/components/SmartProTrading/renderEmail";
import renderHTML from "html-react-parser";

export default class ContactPage extends Component {
  render() {
    return (
      <div className={styles.main}>
        <Helmet>
          <title>{_t.translate("SmartProTrading Contact page")}</title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <h1 className={styles.font300}>{_t.translate("Contact")}</h1>
        <p className={styles.spaceBottom}>
          {renderHTML(_t.translate("Contact us at {{email}}", { email: renderEmailText() }))}
        </p>
        <p>
          {_t.translate(
            "The support team will do its best to respond" +
            " as quickly as possible, within a maximum of 24 hours (working days)."
          )}
        </p>
      </div>
    );
  }
}
