import React, { Component } from "react";
import styles from "./details-page.module.css";
import { _t } from "src/redux/store";
import "react-photoswipe/lib/photoswipe.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  LANG_AND_CURRENCY_BY_COUNTRY,
  getVatRateByCountry,
} from "src/app-constant";
import { getGeoIpCountry } from "src/redux/actions/action-options.js";
import {
  changeCountryIso2,
  cleanInvoiceDetails,
} from "src/redux/actions/action-store";
import FormControl from "@material-ui/core/FormControl";
import InputClientName from "./input-client-name";
import InputClientVatNumber from "./input-client-vat-number";
import InputClientCity from "./input-client-city";
import InputClientAddress from "./input-client-address";
import InputClientZipCode from "./input-client-zip-code";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "../../model/myEnv";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validInvoice: false,
    };
    this.props.getGeoIpCountry();
  }

  componentDidMount() {
    this.handleValidateInvoiceInputs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.validInvoice !== this.state.validInvoice) {
      this.props.changeValidInvoice(this.state.validInvoice);
    }
    if (prevProps.invoiceDetails !== this.props.invoiceDetails) {
      this.handleValidateInvoiceInputs();
    }
  }

  handleChangeCountryCode = (event) => {
    this.props.cleanInvoiceDetails();
    this.props.changeCountryIso2(event.target.value);
  };

  handleValidateInvoiceInputs = () => {
    let isValid = true;
    if (
      this.props.invoiceDetails.address.length < REQ_INVOICE_INPUT_MIN_LENGTH ||
      this.props.invoiceDetails.city.length < REQ_INVOICE_INPUT_MIN_LENGTH ||
      this.props.invoiceDetails.name.length < REQ_INVOICE_INPUT_MIN_LENGTH ||
      this.props.invoiceDetails.vatNumber.length <
        REQ_INVOICE_INPUT_MIN_LENGTH ||
      this.props.invoiceDetails.zipCode.length < REQ_INVOICE_INPUT_MIN_LENGTH
    ) {
      isValid = false;
    }
    if (this.state.validInvoice !== isValid) {
      this.setState({
        validInvoice: isValid,
      });
    }
  };

  render() {
    return (
      <div className={styles.invoiceDetails}>
        {this.props.vendorClientId !== "" ? (
          <div>
            <div className={styles.countrySelectForm}>
              <FormControl
                classes={{
                  root: styles.countrySelectInput,
                }}
                variant="outlined"
              >
                <InputLabel classes={{ root: styles.countrySelectLabel }} id="">
                  {_t.translate("Country")}
                </InputLabel>
                <Select
                  classes={{ root: styles.countrySelect }}
                  native
                  required
                  id="CountrySelection"
                  label="Country"
                  onChange={this.handleChangeCountryCode}
                  value={
                    this.props.invoiceDetails.countryIso2 !== ""
                      ? this.props.invoiceDetails.countryIso2
                      : this.props.countryGeoIp.toUpperCase()
                  }
                >
                  {Object.keys(LANG_AND_CURRENCY_BY_COUNTRY).map((code) =>
                    getVatRateByCountry(code) &&
                    getVatRateByCountry(code) > 0 ? (
                      <option key={code} value={code}>
                        {LANG_AND_CURRENCY_BY_COUNTRY[code].name}
                      </option>
                    ) : (
                      <option key={code} value={code}>
                        {LANG_AND_CURRENCY_BY_COUNTRY[code].name}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </div>
            <div className={styles.textFieldBox}>
              <InputClientVatNumber />
            </div>
            <div className={styles.textFieldBox}>
              <InputClientName />
            </div>
            <div className={styles.textFieldBox}>
              <InputClientAddress />
            </div>
            <div className={styles.textFieldBox}>
              <InputClientCity />
            </div>
            <div className={styles.textFieldBox}>
              <InputClientZipCode />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    countryGeoIp: state.options.countryGeoIp,
    invoiceDetails: state.store.order.invoiceDetails,
    vendorClientId: state.store.order.vendorClientId,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getGeoIpCountry: () => {
      dispatch(getGeoIpCountry());
    },
    changeCountryIso2: (countryCode) => {
      dispatch(changeCountryIso2(countryCode));
    },
    cleanInvoiceDetails: () => {
      dispatch(cleanInvoiceDetails());
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails)
);
