import axios from "axios";

export const getInstanceGeoIp = () =>
  axios.create({
    baseURL: "https://www.smartprotrading.com/api/geoip",
    timeout: 10000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const getInstanceBackend = () =>
  axios.create({
    baseURL: "https://api.smartprotrading.com/SPTService/ServiceSPT.svc/rest",
    timeout: 10000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

export const getLongInstanceBackend = () =>
  axios.create({
    baseURL: "https://api.smartprotrading.com/SPTService/ServiceSPT.svc/rest",
    timeout: (1000 * 120), // 2 minutes, used for GetPayment when getting response from paypal, etc
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

