import React, { Component } from "react";
// import classnames from 'classnames';
import styles from "src/views/home-page.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import { _t } from "src/redux/store";

SwiperCore.use([Autoplay]);

class SwiperDownload extends Component {
  render() {
    return (
      <div className={styles.swiperContainer}>
        <Swiper
          loop={true}
          autoplay={{
            delay: 4000,
          }}
          centeredSlides
          slidesPerView={1}
          spaceBetween={100}
        >
          <SwiperSlide key={1}>
            <div className={styles.slide}>
              <img
                className={styles.w100}
                src="img/1_download.png"
                width="800"
                alt={_t.translate("Snapshot 1")}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <div className={styles.slide}>
              <img
                className={styles.w100}
                src="img/2_download.png"
                width="800"
                alt={_t.translate("Snapshot 2")}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <div className={styles.slide}>
              <img
                className={styles.w100}
                src="img/3_download.png"
                width="800"
                alt={_t.translate("Snapshot 3")}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}
export default SwiperDownload;
