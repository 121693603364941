import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./priceCards.module.css";
import { ButtonBase } from "@material-ui/core";
import { getProducts } from "src/redux/actions/action-store";
import { _t } from "src/redux/store";
import classnames from "classnames";
import { withRouter } from "react-router";
import { changeProductId } from "src/redux/actions/action-store";
import {
  convertCurrency,
  convertCurrencyNoDecimals,
} from "src/utils/currency-conversion";

class PriceCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.getProducts();
  }

  componentDidUpdate(prevProps){
    if(prevProps.currency !== this.props.currency)
    {
      this.renderPrices()
    }
  }

  handleChangeProductId = (event) => {
    if (this.props.location.pathname !== "/store") {
      // verify if you are in /store . if not : do the redirect

      this.props.history.push("/store");
    }
    this.props.changeProductId(event);
  };

  renderPrices() {
    return this.props.productsArray
      .sort((a, b) => a.lengthDays - b.lengthDays)
      .map((product) => {
        //    let showCurrency = "";
        let showPrice = "";
        let pricePerDay = "";
        let showTitle = "";
        let showText = "";
        let showDiscount = "";
        let hasDiscount = false;
        let productId = product.productId;

        product.prices.forEach((el) => {
          if (el.currency === this.props.currency) {
            //       showCurrency = el.currency;
            pricePerDay = el.valueFull / product.lengthDays;
            // .toFixed(2)
            // .concat(" ")
            // .concat(showCurrency);
            showPrice = el.valueFull;
            showTitle = product.name;
            showText = _t.translate("Only {{pricePerDay}} per day", {
              pricePerDay: convertCurrency(
                pricePerDay,
                this.props.currency,
                this.props.countryGeoIp
              ),
            });
            if (el.valueWithDiscount !== undefined) {
              showDiscount = el.valueWithDiscount;
              showPrice = el.valueFull;
              hasDiscount = true;
            }
          }
        });
        if (product.lengthDays >1000) {
          showText = _t.translate("It's forever yours");
        }

        return (
          <div className={styles.cell} onClick={() => this.handleChangeProductId(productId)} key={productId}>
            <p className={styles.title}>{_t.translate(showTitle)}</p>
            <div className={styles.discount}>
              <p
                className={classnames(
                  styles.price,
                  hasDiscount === true && styles.oldPrice
                )}
              >
                {convertCurrencyNoDecimals(
                  showPrice,
                  this.props.currency,
                  this.props.countryGeoIp
                )}
              </p>
              <p
                className={classnames(
                  hasDiscount === true && styles.newPrice,
                  styles.price
                )}
              >
                {hasDiscount &&
                  convertCurrencyNoDecimals(
                    showDiscount,
                    this.props.currency,
                    this.props.countryGeoIp
                  )}
              </p>
            </div>
            <p className={styles.text}>{showText}</p>
            <ButtonBase
              onClick={() => this.handleChangeProductId(productId)}
              classes={{
                root: classnames(
                  styles.btn,
                  this.props.location === undefined && styles.home
                ),
              }}
            >
              {_t.translate('Buy')}
            </ButtonBase>
          </div>
        );
      });
  }

  render() {
    return <div className={styles.priceCards}>{this.renderPrices()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    productsArray: state.store.products,
    currency: state.options.currency,
    productIdStore: state.store.order.productId,
    countryGeoIp: state.options.countryGeoIp,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getProducts: () => {
      dispatch(getProducts());
    },
    changeProductId: (productId) => {
      dispatch(changeProductId(productId));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PriceCards)
);
