import {
  CHANGE_LANGUAGE,
  GET_GEO_IP_COUNTRY,
  CHANGE_CURRENCY,
} from "../actions/action-options";
import { getLanguageAndCurrencyByCountry } from "src/app-constant.js";

import { INITIAL_STATE } from "./initial-state";
const initialState = { ...INITIAL_STATE.options };

export default function stateOptions(state, action) {
  // console.log('action.type', action.type)
  if (state === undefined) {
    return { ...initialState };
  }
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
        langUserChanged: true,
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        currency: action.payload,
        currencyUserChanged: true,
      };

    case GET_GEO_IP_COUNTRY:
      const newCountryIso2GeoIp = action.payload;
      const countryInfo = getLanguageAndCurrencyByCountry(newCountryIso2GeoIp);
      const newLang =
        state.langUserChanged === true ? state.lang : countryInfo.lang;
      const newCurrency =
        state.currencyUserChanged === true
          ? state.currency
          : countryInfo.currency;
      return {
        ...state,
        lang: newLang,
        currency: newCurrency,
        countryGeoIp: newCountryIso2GeoIp,
      };

    default:
      return {
        ...state,
      };
  }
}
