import React, { Component } from "react";
import { Route, Switch } from "react-router";
import "./App.css";
import ContactPage from "./views/contact-page";
import HomePage from "./views/home-page";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import AfiliadoPage from "./views/afiliado-page";
import FAQPage from "./views/faq-page";
import GuiaPage from "./views/guia-page";
import TermsConditionsPage from "./views/terms-conditions-page";
import CancelarPage from "./views/cancelar-page";
import DownloadPage from "./views/download-page";
import LojaPage from "./views/loja-page";
import LojaReturnPage from "./views/loja-return-page";
import UserActivityWatcher from "src/utils/UserActivityWatcher";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base: 0,
    };
  }

  render() {
    return (
      <div className="main">
        <Header />
        <Switch>
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/affiliate" component={AfiliadoPage} />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/terms-and-conditions" component={TermsConditionsPage} />
          <Route exact path="/cancel" component={CancelarPage} />
          <Route path="/download" component={DownloadPage} />
          <Route path="/user-guide" component={GuiaPage} />
          <Route path="/store/result" component={LojaReturnPage} />
          <Route path="/store" component={LojaPage} />
          {/* Because it's inside a Switch: placing homepage at the bottom, ensure that all urls that did not fit above, will end up here (no need for 404 Page) */}
          <Route path="/" component={HomePage} />
        </Switch>
        <Footer />
        <UserActivityWatcher />
      </div>
    );
  }
}

export default App;
