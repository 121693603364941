export const DOWNLOAD_REQUIREMENTS = {
  windows: {
    os: "windows",
    tabUrl: "/download/windows",
    downloadLink: "/download/SmartProTrading-windows.msi",
    requirements:
      "<ul>" +
      "<li> Intel® Core 2 or AMD Athlon® 64 processor; 2 GHz or faster processor</li>" +
      "<li> 2 GB of RAM (4 GB recommended)</li>" +
      "<li> 1 GB of available hard-disk space; additional free space required during installation (cannot install on removable flash storage devices)</li>" +
      "<li> 1280x800 display ( recommended) with 16-bit color and 512 MB of VRAM (1 GB recommended)</li>" +
      "<li> Internet connection: Broadband minimum 4Mbps or high speed mobile (3G).</li>" +
      "</ul>",
    software:
      "<ul>" +
      "<li> Windows 8.1, Windows 10, Windows Server 2012 R2, Windows Server 2016, Windows Server 2019 or above.</li>" +
      "<li> Microsoft .NET Core 3.1 is required, this will install automatically if not already present on your system.</li>" +
      "</ul>",
  },

  mac: {
    os: "mac",
    tabUrl: "/download/mac",
    downloadLink: "/download/SmartProTrading-mac.zip",
    requirements:
      "<ul>" +
      "<li> Mac released after 2013, including a Intel Core i5 dual-core at 1,3 GHz or faster processor</li>" +
      "<li> 4 GB of RAM minimum</li>" +
      "<li> 1 GB of available hard-disk space</li>" +
      "<li> Internet connection: Broadband minimum 4Mbps or high speed mobile (3G).</li>" +
      "</ul>",
    software:
      "<ul>" +
      "<li> MacOS® X 10.13 (High Sierra) or above</li>" +
      "</ul>",
  },

  linux: {
    os: "linux",
    tabUrl: "/download/linux",
    downloadLink: "/download/SmartProTrading-linux.deb",
    requirements:
      "<ul>" +
      "<li> Intel® Core 2 or AMD Athlon® 64 processor; 2 GHz or faster processor</li>" +
      "<li> 2 GB of RAM (4 GB recommended)</li>" +
      "<li> 1 GB of available hard-disk space; additional free space required during installation (cannot install on removable flash storage devices)</li>" +
      "<li> 1280x800 display ( recommended) with 16-bit color and 512 MB of VRAM (1 GB recommended)</li>" +
      "<li> Internet connection: Broadband minimum 4Mbps or high speed mobile (3G).</li>" +
      "</ul>",
    software:
      "<ul>" +
      "<li> Ubunto18.04LTS or above, or Debian 10 or above</li>" +
      "</ul>",
  },
};
