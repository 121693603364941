import React, { Component } from "react";
import { _t } from "src/redux/store";
import { connect } from "react-redux";
import { changeClientAddress } from "src/redux/actions/action-store.js";
import FormControl from "@material-ui/core/FormControl";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./details-page.module.css";
import { TextField } from "@material-ui/core";
import { REQ_INVOICE_INPUT_MIN_LENGTH } from "./../../model/myEnv";

class InputClientAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddressEdited: false,
      tempValue: "",
    };
  }

  handleClientAddressChange = (event) => {
    this.setState({ isAddressEdited: true, tempValue: event.target.value });
    if (event.target.value.length >= REQ_INVOICE_INPUT_MIN_LENGTH) {
      this.props.changeClientAddress(event.target.value);
    } else {
      this.props.changeClientAddress("");
    }
  };

  render() {
    const isAddressEmpty = this.state.tempValue === "";
    const addressHasError = isAddressEmpty && this.state.isAddressEdited;
    const hasRequiredLength =
      this.state.tempValue.length >= REQ_INVOICE_INPUT_MIN_LENGTH;
    return (
      <div className={styles.invoice}>
        <div className={styles.inputDetails}>
          <FormControl className={styles.formControl}>
            <TextField
              classes={{
                root: addressHasError
                  ? styles.textFieldError
                  : styles.textField,
              }}
              variant="outlined"
              required
              error={addressHasError}
              id="client-address"
              label={_t.translate("Billing Address")}
              helperText={
                addressHasError === true
                  ? _t.translate("Billing address is required")
                  : !hasRequiredLength && this.state.isAddressEdited
                  ? _t.translate(
                      "At least {{minimumLength}} characters required",
                      {
                        minimumLength: REQ_INVOICE_INPUT_MIN_LENGTH,
                      }
                    )
                  : ""
              }
              onChange={this.handleClientAddressChange}
              defaultValue={this.props.invoiceDetailsAddress}
            />
          </FormControl>
        </div>
        <div className={styles.checkIcon}>
          {this.props.invoiceDetailsAddress !== "" ? (
            <CheckIcon classes={{ root: styles.icon }}></CheckIcon>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoiceDetailsAddress: state.store.order.invoiceDetails.address,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeClientAddress: (newClientAddress) => {
      dispatch(changeClientAddress(newClientAddress));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputClientAddress);
