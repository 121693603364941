import React, { Component } from "react";
import renderHTML from 'html-react-parser';
import { DOWNLOAD_REQUIREMENTS } from "src/views/download-page/downloadRequirementsData";
import styles from "src/views/download-page.module.css";
import { Helmet } from "react-helmet";
import { _t } from "src/redux/store";
import { renderBetfairText } from "src/components/SmartProTrading/renderLinks";

export default class DownloadData extends Component {
  render() {
    const osKey = DOWNLOAD_REQUIREMENTS.hasOwnProperty(this.props.match.params.os) ? this.props.match.params.os : 'windows';
    const requirements = _t.translate(
      DOWNLOAD_REQUIREMENTS[osKey].requirements
    );
    const software = _t.translate(
      DOWNLOAD_REQUIREMENTS[osKey].software
    );
    const title = DOWNLOAD_REQUIREMENTS[osKey].os;

    return (
      <div>
        <Helmet>
          <title>
            {title + " - " + _t.translate("SmartProTrading download page")}
          </title>
          {/* <meta name="description" content={itemDescription} /> */}
        </Helmet>
        <p className={styles.textTitle}>
          {_t.translate("Technical requirements for {{operatingSystemName}}:", { operatingSystemName: title.toUpperCase() })}
        </p>
        <div>{renderHTML(requirements)}</div>
        <p className={styles.textTitle}>{_t.translate("Software:")}</p>
        <div>{renderHTML(software)}</div>
        <p className={styles.conta}>
          {renderHTML(_t.translate("You need to have a {{betfairLink}} account", { betfairLink: renderBetfairText() }))}
        </p>
      </div>
    );
  }
}
