import React, { Component } from "react";
import styles from "./SmartProTrading.module.css";
import classnames from "classnames";

/**
 * @props mainColorClass : css class to control the color on BOLD outter text
 * @props secondaryColorClass : css class to control the color on thin inner text
 * @props {boolean} spaceLeft : add 0.3em padding left
 * @props {boolean} spaceRight : add 0.3em padding right
 */
export default class SmartProTrading extends Component {
  render() {
    return (
      <span
        className={classnames(
          styles.logoName,
          this.props.spaceLeft === true && styles.paddingLeft,
          this.props.spaceRight === true && styles.paddingRight
        )}
      >
        <span className={classnames("font900", this.props.mainColorClass)}>
          SMART
        </span>
        <span className={classnames("font300", this.props.secondaryColorClass)}>
          PRO
        </span>
        <span className={classnames("font900", this.props.mainColorClass)}>
          TRADING
        </span>
      </span>
    );
  }
}
